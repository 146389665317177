import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { X, Plus } from "lucide-react";
import { useAuthStore } from "../../store/authStore";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../config/firebase";

// Update schema to properly handle custom categories
const budgetItemSchema = z
  .object({
    category: z.string().optional(),
    isCustomCategory: z.boolean().default(false),
    customCategory: z.string().optional().nullable(),
    name: z.string().min(2, "Name must be at least 2 characters"),
    limit: z.number().min(0, "Limit must be greater than 0"),
    spent: z.number().min(0, "Spent must be greater than 0"),
  })
  .refine(
    (data) => {
      // Require either category or customCategory based on isCustomCategory
      if (data.isCustomCategory) {
        return !!data.customCategory;
      }
      return !!data.category;
    },
    {
      message: "Please select a category or enter a custom category",
      path: ["category"],
    }
  );

type BudgetItemFormData = z.infer<typeof budgetItemSchema>;

interface AddBudgetItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  transactions?: any[];
  categories?: any[];
}

function AddBudgetItemModal({
  isOpen,
  onClose,
  transactions = [],
  categories = [],
}: AddBudgetItemModalProps) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<BudgetItemFormData>({
    resolver: zodResolver(budgetItemSchema),
    defaultValues: {
      isCustomCategory: false,
      customCategory: null,
      spent: 0,
    },
  });

  const isCustomCategory = watch("isCustomCategory");
  const { user } = useAuthStore();

  const onSubmit = async (data: BudgetItemFormData) => {
    try {
      const category = data.isCustomCategory
        ? data.customCategory!
        : data.category!;

      let transactionsUnderCurrentCategory;

      if (!data.isCustomCategory) {
        transactionsUnderCurrentCategory = transactions?.filter(
          (transaction) =>
            transaction?.category[1]?.toLowerCase() ===
              category.toLowerCase() ||
            transaction?.category[0]?.toLowerCase() === category.toLowerCase()
        );
      }

      const totalSpent =
        transactionsUnderCurrentCategory
          ?.filter((transaction) => transaction.amount < 0)
          ?.reduce(
            (sum, transaction) => sum + Math.abs(transaction.amount),
            0
          ) || data.spent;

      await addDoc(collection(db, "budgetCategories", user.id, "categories"), {
        name: data.name,
        category,
        limit: data.limit,
        spent: totalSpent,
        userId: user.id,
        isCustom: data.isCustomCategory,
        createdAt: new Date().toISOString(),
      });

      reset();
      onClose();
    } catch (error) {
      console.error("Failed to add budget item:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <style>
          {`
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
          `}
        </style>
        <div
          className="fixed inset-0 bg-black bg-opacity-25"
          onClick={onClose}
        />

        <div className="relative w-full max-w-md rounded-lg bg-white dark:bg-gray-800 p-6 shadow-xl">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              Add Budget Category
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    {...register("isCustomCategory")}
                    className="rounded border-gray-300 text-primary-400 focus:ring-primary-400"
                  />
                  <span>Create Custom Category</span>
                </div>
              </label>
            </div>

            {isCustomCategory ? (
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Custom Category Name
                </label>
                <input
                  type="text"
                  {...register("customCategory")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="Enter custom category name"
                />
                {errors.customCategory && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.customCategory.message}
                  </p>
                )}
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Category
                </label>
                <select
                  {...register("category")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="">Select a category</option>
                  {categories?.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                  <option value="housing">Housing</option>
                  <option value="transportation">Transportation</option>
                  <option value="food">Food</option>
                  <option value="utilities">Utilities</option>
                  <option value="healthcare">Healthcare</option>
                  <option value="insurance">Insurance</option>
                  <option value="entertainment">Entertainment</option>
                  <option value="shopping">Shopping</option>
                  <option value="savings">Savings</option>
                  <option value="debt">Debt</option>
                  <option value="other">Other</option>
                </select>
                {errors.category && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.category.message}
                  </p>
                )}
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Name
              </label>
              <input
                type="text"
                {...register("name")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="e.g., Monthly Rent or Groceries"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Monthly Limit
              </label>
              <div className="relative mt-1">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  {...register("limit", { valueAsNumber: true })}
                  className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="0.00"
                  step="0.01"
                />
              </div>
              {errors.limit && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.limit.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Amount Spent
              </label>
              <div className="relative mt-1">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  {...register("spent", { valueAsNumber: true })}
                  className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="0.00"
                  step="0.01"
                />
              </div>
              {errors.spent && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.spent.message}
                </p>
              )}
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md disabled:opacity-50"
              >
                {isSubmitting ? "Adding..." : "Add Category"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBudgetItemModal;
