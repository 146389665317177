import React, { useState, useEffect } from "react";
import {
  TrendingUp,
  Search,
  Filter,
  Plus,
  Edit2,
  Trash2,
  DollarSign,
  Scale,
  Warehouse,
  TrendingDown,
} from "lucide-react";
import AddCommodityModal from "../../components/../pages/investments/components/AddCommodityModal";
import {
  createDocumentWithRandomId,
  deleteDocument,
  getDocument,
  updateDocument,
} from "../../utils/firebase-db";
import { useAuthStore } from "../../store/authStore";
import { useNotificationStore } from "../../store/notificationStore";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../config/firebase";

interface CommodityFilters {
  search: string;
  sortBy:
    | "value_desc"
    | "value_asc"
    | "quantity_desc"
    | "quantity_asc"
    | "gain_desc"
    | "gain_asc"
    | "gainPercent_desc"
    | "gainPercent_asc"
    | "name_asc"
    | "name_desc";
}

function CommoditiesPage({ commodities }) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingCommodity, setEditingCommodity] = useState(null);
  const [filters, setFilters] = useState<CommodityFilters>({
    search: "",
    sortBy: "value_desc",
  });
  const [updatedCommodities, setUpdatedCommodities] = useState(commodities);
  const { user } = useAuthStore();
  const [userNotifications, setUserNotifications] = useState(null);
  const { addNotification } = useNotificationStore();

  // Fetch current commodity price using API Ninjas
  async function fetchCommodityData(commodityName) {
    try {
      const response = await fetch(
        `https://api.api-ninjas.com/v1/commodityprice?name=${commodityName}`,
        {
          method: "GET",
          headers: { "X-Api-Key": "c6+m+aHCQrXs6FZhFC6ZeA==mgtKNI4LOtTGn9uO" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      // Assuming the API returns an object with a price property
      return data;
    } catch (error) {
      console.error("Error fetching commodity price:", error);
      return null;
    }
  }

  // On mount (or when commodities prop changes), update each commodity with its current price.
  useEffect(() => {
    async function updatePrices() {
      const newCommodities = await Promise.all(
        commodities.map(async (commodity) => {
          const fetchedData = await fetchCommodityData(commodity.name);
          // Use the fetched price if available; otherwise, fallback to the existing currentPrice.
          const newPrice =
            fetchedData && fetchedData.price
              ? parseFloat(fetchedData.price)
              : parseFloat(commodity.currentPrice) || 0;
          const totalValue = commodity.quantity * newPrice;
          const gain =
            totalValue - commodity.quantity * commodity.purchasePrice;
          const gainPercent =
            (gain / (commodity.quantity * commodity.purchasePrice)) * 100;
          return {
            ...commodity,
            currentPrice: newPrice,
            totalValue,
            gain,
            gainPercent,
          };
        })
      );
      setUpdatedCommodities(newCommodities);
    }
    updatePrices();
  }, [commodities]);

  // Filter and sort commodities using the updated data.
  const filteredCommodities = updatedCommodities
    .filter((commodity) => {
      const searchFields = [
        commodity.name,
        commodity.type,
        commodity.location,
        commodity.custodian,
      ];
      return searchFields.some((field) =>
        field?.toLowerCase().includes(filters.search.toLowerCase())
      );
    })
    .sort((a, b) => {
      const [field, direction] = filters.sortBy.split("_");
      const getValue = (commodity: any) => {
        switch (field) {
          case "name":
            return commodity.name;
          case "value":
            return commodity.totalValue;
          case "quantity":
            return commodity.quantity;
          case "gain":
            return commodity.gain;
          case "gainPercent":
            return commodity.gainPercent;
          default:
            return commodity.totalValue;
        }
      };
      const aValue = getValue(a);
      const bValue = getValue(b);
      if (typeof aValue === "string") {
        return direction === "asc"
          ? aValue.localeCompare(bValue as string)
          : (bValue as string).localeCompare(aValue);
      }
      return direction === "asc" ? aValue - bValue : bValue - aValue;
    });

  const totalValue = filteredCommodities.reduce(
    (sum, holding) => sum + holding.totalValue,
    0
  );
  const totalGain = filteredCommodities.reduce(
    (sum, holding) => sum + holding.gain,
    0
  );
  const averageGainPercent =
    filteredCommodities.reduce((sum, holding) => sum + holding.gainPercent, 0) /
    filteredCommodities.length;

  // Calculate storage status
  const allInsured = filteredCommodities.every(
    (commodity) => commodity.insuranceStatus === "Insured"
  );
  const partiallyInsured = filteredCommodities.some(
    (commodity) => commodity.insuranceStatus === "Insured"
  );
  const noHoldings = filteredCommodities.length === 0;

  const getStorageStatus = () => {
    if (noHoldings) return { text: "No Holdings", color: "text-gray-500" };
    if (allInsured) return { text: "Fully Insured", color: "text-green-500" };
    if (partiallyInsured)
      return { text: "Partially Insured", color: "text-yellow-500" };
    return { text: "Uninsured", color: "text-red-500" };
  };

  const storageStatus = getStorageStatus();

  const handleAddCommodity = async (data: any) => {
    try {
      // Optionally, you could also call fetchCommodityData here if you want the latest price at add time.
      const currentPrice = data.purchasePrice * 1.05;
      const totalValue = data.quantity * currentPrice;
      const gain = totalValue - data.quantity * data.purchasePrice;
      const gainPercent = (gain / (data.quantity * data.purchasePrice)) * 100;

      const newCommodity = {
        ...data,
        currentPrice,
        totalValue,
        gain,
        gainPercent,
        lastValuationDate: new Date().toISOString(),
        // userId: user.id,
      };

      await addDoc(
        collection(db, "commodities", user.id, "userCommodity"),
        newCommodity
      );

      if (userNotifications?.notifications.push.investmentAlerts) {
        await addNotification({
          type: "success",
          message: `Commodity ${data.name} added successfully!`,
          title: `New commodity`,
        });
      }
      setIsAddModalOpen(false);
    } catch (error) {
      console.error("Failed to add commodity:", error);
    }
  };

  const handleEditCommodity = async (commodity: string, data: any) => {
    try {
      const currentPrice = data.purchasePrice * 1.05;
      const totalValue = data.quantity * currentPrice;
      const gain = totalValue - data.quantity * data.purchasePrice;
      const gainPercent = (gain / (data.quantity * data.purchasePrice)) * 100;

      const updatedCommodity = {
        ...data,
        currentPrice,
        totalValue,
        gain,
        gainPercent,
        lastValuationDate: new Date().toISOString(),
        // userId: user.id,
      };

      const commodityDoc = doc(
        db,
        "commodities",
        user.id,
        "userCommodity",
        commodity.id
      );
      await updateDoc(commodityDoc, updatedCommodity);

      setEditingCommodity(null);
      setIsAddModalOpen(false);
    } catch (error) {
      console.error("Failed to update commodity:", error);
    }
  };

  const handleDeleteCommodity = async (commodityId: string) => {
    try {
      const commodityDoc = doc(
        db,
        "commodities",
        user.id,
        "userCommodity",
        commodityId
      );
      await deleteDoc(commodityDoc);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchnotifications = async () => {
      const userNotifications = await getDocument(
        "notificationSettings",
        user.id
      );

      setUserNotifications(userNotifications);
    };

    fetchnotifications();
  }, []);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search commodities..."
            value={filters.search}
            onChange={(e) =>
              setFilters((f) => ({ ...f, search: e.target.value }))
            }
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <div className="flex items-center">
            <Filter className="h-5 w-5 text-gray-400 mr-2" />
            <select
              value={filters.sortBy}
              onChange={(e) =>
                setFilters((f) => ({ ...f, sortBy: e.target.value as any }))
              }
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-400"
            >
              <option value="value_desc">Market Value (High to Low)</option>
              <option value="value_asc">Market Value (Low to High)</option>
              <option value="quantity_desc">Quantity (High to Low)</option>
              <option value="quantity_asc">Quantity (Low to High)</option>
              <option value="gain_desc">Total Gain/Loss (High to Low)</option>
              <option value="gain_asc">Total Gain/Loss (Low to High)</option>
              <option value="gainPercent_desc">
                % Gain/Loss (High to Low)
              </option>
              <option value="gainPercent_asc">% Gain/Loss (Low to High)</option>
              <option value="name_asc">Name (A to Z)</option>
              <option value="name_desc">Name (Z to A)</option>
            </select>
          </div>
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="flex items-center px-4 py-2 text-white bg-primary-400 hover:bg-primary-500 rounded-lg"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Commodity
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Value</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalValue.toLocaleString()}</p>
          <div className="flex items-center mt-2 text-green-500">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+${totalGain.toLocaleString()} Total Return</span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Average Return</h3>
            <Scale className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold text-green-500">
            +{averageGainPercent.toFixed(2)}%
          </p>
          <p className="text-sm text-gray-500 mt-2">Across all holdings</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Storage Status</h3>
            <Warehouse className="h-5 w-5 text-primary-400" />
          </div>
          <p className={`text-2xl font-bold ${storageStatus.color}`}>
            {storageStatus.text}
          </p>
          <p className="text-sm text-gray-500 mt-2">
            {noHoldings
              ? "Add commodities to track insurance status"
              : allInsured
              ? "All holdings are insured"
              : partiallyInsured
              ? "Some holdings need insurance"
              : "Holdings require insurance"}
          </p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium">Commodity Holdings</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Commodity/Type
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Purchase Price
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Price
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Storage Location
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Total Value
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Gain/Loss
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {filteredCommodities.map((commodity) => (
                <tr
                  key={commodity.id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="font-medium text-gray-900 dark:text-white">
                        {commodity.name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {commodity.type}
                      </div>
                      <div className="text-xs text-gray-500">
                        Form: {commodity.form}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {commodity.quantity} {commodity.unit}
                    </span>
                    <div className="text-xs text-gray-500">
                      Purity: {commodity.purity}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${commodity.purchasePrice.toLocaleString()}
                    </span>
                    <div className="text-xs text-gray-500">
                      per {commodity.unit}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${commodity.currentPrice.toLocaleString()}
                    </span>
                    <div className="text-xs text-gray-500">
                      per {commodity.unit}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm text-gray-900 dark:text-white">
                        {commodity.location}
                      </div>
                      <div className="text-xs text-gray-500">
                        {commodity.custodian}
                      </div>
                      <div className="text-xs text-green-500">
                        {commodity.insuranceStatus}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${commodity.totalValue.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex flex-col items-end">
                      <div
                        className={`flex items-center ${
                          commodity.gain >= 0
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {commodity.gain >= 0 ? (
                          <TrendingUp className="h-4 w-4 mr-1" />
                        ) : (
                          <TrendingDown className="h-4 w-4 mr-1" />
                        )}
                        <span>
                          {commodity.gain >= 0 ? "+$" : "-$"}
                          {Math.abs(commodity.gain).toLocaleString()}
                        </span>
                      </div>
                      <span
                        className={`text-sm ${
                          commodity.gainPercent >= 0
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {commodity.gainPercent >= 0 ? "+" : "-"}
                        {Math.abs(commodity.gainPercent).toFixed(2)}%
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex items-center justify-end space-x-2">
                      <button
                        onClick={() => {
                          setEditingCommodity(commodity);
                          setIsAddModalOpen(true);
                        }}
                        className="p-2 text-gray-400 hover:text-primary-400 transition-colors"
                      >
                        <Edit2 className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDeleteCommodity(commodity.id)}
                        className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AddCommodityModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setEditingCommodity(null);
        }}
        onAdd={handleAddCommodity}
        editingCommodity={editingCommodity}
        onEdit={handleEditCommodity}
      />
    </div>
  );
}

export default CommoditiesPage;
