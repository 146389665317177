import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { X } from "lucide-react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import {
  createDocumentWithRandomId,
  getDocument,
} from "../../utils/firebase-db";
import { useAuthStore } from "../../store/authStore";
import { useNotificationStore } from "../../store/notificationStore";
import { db } from "../../config/firebase";

const investmentSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters"),
  category: z.string().min(2, "Category is required"),
  description: z.string().min(2, "Description is required"),
  purchasePrice: z.number().min(0, "Purchase price must be greater than 0"),
  currentValue: z.number().min(0, "Current value must be greater than 0"),
  location: z.string().min(2, "Location is required"),
  condition: z.string().min(2, "Condition is required"),
  insurance: z.object({
    provider: z.string().min(2, "Insurance provider is required"),
    policyNumber: z.string().min(2, "Policy number is required"),
    coverage: z.number().min(0, "Coverage must be greater than 0"),
    premium: z.number().min(0, "Premium must be greater than 0"),
  }),
});

type InvestmentFormData = z.infer<typeof investmentSchema>;

interface AddOtherInvestmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  editingInvestment?: any;
  onUpdate?: (id: string, data: InvestmentFormData) => void;
}

function AddOtherInvestmentModal({
  isOpen,
  onClose,
  editingInvestment,
  onUpdate,
}: AddOtherInvestmentModalProps) {
  const { user } = useAuthStore();
  const [userNotifications, setUserNotifications] = useState(null);
  const { addNotification } = useNotificationStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<InvestmentFormData>({
    resolver: zodResolver(investmentSchema),
    defaultValues: editingInvestment || {
      name: "",
      category: "",
      description: "",
      purchasePrice: 0,
      currentValue: 0,
      location: "",
      condition: "",
      insurance: {
        provider: "",
        policyNumber: "",
        coverage: 0,
        premium: 0,
      },
    },
  });

  useEffect(() => {
    if (editingInvestment) {
      reset(editingInvestment);
    }
  }, [editingInvestment, reset]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const notifications = await getDocument("notificationSettings", user.id);
      setUserNotifications(notifications);
    };
    fetchNotifications();
  }, [user.id]);

  const addInvestment = async (data: InvestmentFormData) => {
    const request = {
      ...data,
      purchaseDate: serverTimestamp(),
      gain: data.currentValue - data.purchasePrice,
      gainPercent:
        ((data.currentValue - data.purchasePrice) / data.purchasePrice) * 100,
      userId: user.id,
    };

    await addDoc(
      collection(db, "userAddedInvestments", user.id, "otherInvestments"),
      request
    );
    if (userNotifications?.notifications.push.investmentAlerts) {
      await addNotification({
        type: "success",
        message: `Investment ${data.name} added successfully!`,
        title: `New investment`,
      });
    }
  };

  const onSubmit = async (data: InvestmentFormData) => {
    try {
      if (editingInvestment) {
        await onUpdate?.(editingInvestment.id, data);
      } else {
        await addInvestment(data);
      }
      reset();
      onClose();
    } catch (error) {
      console.error("Failed to handle investment:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />
        <style>
          {`
            /* Hide number input spinners */
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
          `}
        </style>

        <div
          className="relative w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              {editingInvestment
                ? "Edit Investment"
                : "Add Alternative Investment"}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Name
                </label>
                <input
                  type="text"
                  {...register("name")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.name.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Category
                </label>
                <select
                  {...register("category")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="">Select a category</option>
                  <option value="Watches">Watches</option>
                  <option value="Art">Art</option>
                  <option value="Collectibles">Collectibles</option>
                  <option value="Wine">Wine</option>
                  <option value="Jewelry">Jewelry</option>
                  <option value="Cars">Cars</option>
                  <option value="Others">Others</option>
                </select>
                {errors.category && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.category.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Description
              </label>
              <textarea
                {...register("description")}
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              />
              {errors.description && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.description.message}
                </p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Purchase Price
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    {...register("purchasePrice", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
                {errors.purchasePrice && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.purchasePrice.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Current Value
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    {...register("currentValue", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
                {errors.currentValue && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.currentValue.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Location
                </label>
                <input
                  type="text"
                  {...register("location")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
                {errors.location && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.location.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Condition
                </label>
                <select
                  {...register("condition")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="">Select condition</option>
                  <option value="Mint">Mint</option>
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                </select>
                {errors.condition && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.condition.message}
                  </p>
                )}
              </div>
            </div>

            <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
              <h3 className="text-lg font-medium mb-4">
                Insurance Information
              </h3>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Insurance Provider
                  </label>
                  <input
                    type="text"
                    {...register("insurance.provider")}
                    className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                  {errors.insurance?.provider && (
                    <p className="mt-1 text-sm text-red-600">
                      {errors.insurance.provider.message}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Policy Number
                  </label>
                  <input
                    type="text"
                    {...register("insurance.policyNumber")}
                    className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                  {errors.insurance?.policyNumber && (
                    <p className="mt-1 text-sm text-red-600">
                      {errors.insurance.policyNumber.message}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Coverage Amount
                  </label>
                  <div className="relative mt-1">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      {...register("insurance.coverage", {
                        valueAsNumber: true,
                      })}
                      className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                    />
                  </div>
                  {errors.insurance?.coverage && (
                    <p className="mt-1 text-sm text-red-600">
                      {errors.insurance.coverage.message}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Annual Premium
                  </label>
                  <div className="relative mt-1">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      {...register("insurance.premium", {
                        valueAsNumber: true,
                      })}
                      className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                    />
                  </div>
                  {errors.insurance?.premium && (
                    <p className="mt-1 text-sm text-red-600">
                      {errors.insurance.premium.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md"
              >
                {editingInvestment ? "Update Investment" : "Add Investment"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddOtherInvestmentModal;
