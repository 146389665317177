import { useEffect, useState } from "react";
import { CreditCard, DollarSign } from "lucide-react";
import { BankAccount } from "../../types/bank";
import { getDocument } from "../../utils/firebase-db.js";
import { useAuthStore } from "../../store/authStore.js";

function AccountCard() {
  const [userAccounts, setUserAccounts] = useState<BankAccount | null>(null);
  const { user } = useAuthStore();
  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const fetchedData = await getDocument("bankAccounts", user.id);

        // Assuming the fetched data has the same structure as shown in your example
        setUserAccounts(fetchedData);
      } catch (err) {
        console.error("Error fetching account data:", err);
      }
    };

    fetchAccountData();
  }, []);
  return (
    <>
      {userAccounts?.accounts.map((account) => (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transition-all hover:shadow-xl">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                <CreditCard className="h-6 w-6 text-primary-400" />
              </div>
              <div>
                <h3 className="font-semibold text-gray-900 dark:text-white">
                  {userAccounts.item.institution_name}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {account.official_name?.replace("Plaid ", "")}
                </p>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Current Balance
              </p>
              <div className="flex items-center mt-1">
                <DollarSign className="h-5 w-5 text-gray-400" />
                <p className="text-xl font-bold text-gray-900 dark:text-white">
                  {account.balances.available?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <p className="text-gray-500 dark:text-gray-400">
                  Account Number
                </p>
                <p className="font-medium text-gray-900 dark:text-white">
                  ****{account.mask}
                </p>
              </div>
              <div>
                <p className="text-gray-500 dark:text-gray-400">Type</p>
                <p className="font-medium text-gray-900 dark:text-white capitalize">
                  {account.subtype?.replace("_", " ")}
                </p>
              </div>

              <div>
                <p className="text-gray-500 dark:text-gray-400">
                  Interest Rate
                </p>
                {account.official_name?.match(/(\d+\.?\d*)%/) ? (
                  <p className="font-medium text-gray-900 dark:text-white">
                    {account.official_name?.match(/(\d+\.?\d*)%/)[0]}
                  </p>
                ) : (
                  <p className="font-medium text-gray-900 dark:text-white">
                    No Interest Rate
                  </p>
                )}
              </div>
              <div>
                <p className="text-gray-500 dark:text-gray-400">Last Updated</p>
                <p className="font-medium text-gray-900 dark:text-white">
                  {/* {format(new Date(account.lastUpdated), "MMM d, yyyy")} */}
                  Jan 21, 2025
                </p>
              </div>
            </div>

            <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-500 dark:text-gray-400">Status</span>
                <span
                  className={`px-2 py-1 rounded-full text-xs font-medium ${
                    account.status === "active"
                      ? "bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400"
                      : "bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-400"
                  }`}
                >
                  {/* {account.status.charAt(0).toUpperCase() +
                    account.status.slice(1)} */}
                  active
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default AccountCard;
