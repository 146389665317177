import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Plus, ChevronDown, Link, PencilLine } from "lucide-react";
import { useAuthStore } from "../../store/authStore";
import Context from "../../Context";
import { usePlaidLink } from "../Link";
import { api } from "../../utils/api";
import { createDocument } from "../../utils/firebase-db";
import { auth, db } from "../../config/firebase";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";

function LinkBankButton() {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { plaidTransactions, plaidAccounts, addAccessTokenAndItemId } =
    useAuthStore();
  const { linkToken, isPaymentInitiation, isCraProductsExclusively, dispatch } =
    useContext(Context);

  const { user } = useAuthStore();

  enum CraCheckReportProduct {
    BaseReport = "cra_base_report",
    IncomeInsights = "cra_income_insights",
    PartnerInsights = "cra_partner_insights",
    NetworkInsights = "cra_network_insights",
  }

  const getInfo = useCallback(async () => {
    if (!dispatch) {
      console.error("Dispatch is not defined");
      return;
    }

    const response = await api.post("/info", {
      access_token: user.access_token,
      item_id: user.item_id,
    });

    // if (!response.accesss_token || !response.item_id) {
    //   return { paymentInitiation: false };
    // }

    // const data = await response.json();

    const paymentInitiation: boolean =
      response.products.includes("payment_initiation");
    const craEnumValues = Object.values(CraCheckReportProduct);
    const isUserTokenFlow: boolean = response.products.some(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );
    const isCraProductsExclusively: boolean = response.products.every(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );

    dispatch({
      type: "SET_STATE",
      state: {
        products: response.products,
        isPaymentInitiation: paymentInitiation,
        isCraProductsExclusively: isCraProductsExclusively,
        isUserTokenFlow: isUserTokenFlow,
      },
    });
    return { paymentInitiation, isUserTokenFlow };
  }, [dispatch]);

  const generateUserToken = useCallback(async () => {
    const response = await api.post("/create_user_token", {});
    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { userToken: null } });

      return;
    }
    const data = await response.json();
    if (data) {
      if (data.error != null) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
            linkTokenError: data.error,
          },
        });
        return;
      }
      dispatch({ type: "SET_STATE", state: { userToken: data.user_token } });

      return data.user_token;
    }
  }, [dispatch]);

  const generateToken = useCallback(
    async (isPaymentInitiation) => {
      // Link tokens for 'payment_initiation' use a different creation flow in your backend.

      const path =
        // isPaymentInitiation
        // ? "/api/create_link_token_for_payment"
        "/create_link_token";
      const response = await api.post(path, {
        user_id: user.id,
        token_type: "link",
      });

      // if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { linkToken: null } });

      // return;
      // }
      // const data = await response.text();

      // if (data) {
      if (!response.link_token) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
          },
        });
        return;
      }

      dispatch({
        type: "SET_STATE",
        state: { linkToken: response.link_token },
      });
    },

    [dispatch]
  );

  useEffect(() => {
    const init = async () => {
      const { paymentInitiation, isUserTokenFlow } = await getInfo(); // used to determine which path to take when generating token
      // do not generate a new token for OAuth redirect; instead

      if (isUserTokenFlow) {
        await generateUserToken();
      }
      generateToken(paymentInitiation);
    };
    init();
  }, [dispatch, generateToken, generateUserToken, getInfo]);

  const onSuccess = React.useCallback(
    async (public_token: string, metadata: any) => {
      try {
        if (isPaymentInitiation || isCraProductsExclusively) {
          dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
          return;
        }

        const tokenType = "link";

        const data = await api.post("/set_access_token", { public_token });

        if (!data.access_token) throw new Error("Failed to exchange token");

        dispatch({
          type: "SET_STATE",
          state: {
            itemId: data.item_id,
            accessToken: data.access_token,
            isItemAccess: true,
          },
        });

        const [transactionResult, bankAccountResult] = await Promise.all([
          plaidTransactions(data.access_token),
          plaidAccounts(data.access_token),
        ]);

        await Promise.all([
          addAccessTokenAndItemId(data, user, tokenType),
          createDocument(
            "transactions",
            user.id,
            transactionResult.latest_transactions
          ),
          createDocument("bankAccounts", user.id, bankAccountResult),
          addUniqueCategories(transactionResult.latest_transactions), // Add categories to Firestore
        ]);

        dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
        window.history.pushState("", "", "/");
      } catch (error) {
        console.error("Token exchange failed:", error);
        dispatch({
          type: "SET_STATE",
          state: {
            itemId: "no item_id retrieved",
            accessToken: "no access_token retrieved",
            isItemAccess: false,
          },
        });
      }
    },
    [dispatch, isPaymentInitiation, isCraProductsExclusively]
  );

  // Function to add unique categories to Firestore
  async function addUniqueCategories(transactions) {
    const categoriesSet = new Set();

    // Extract unique categories from transactions
    transactions?.forEach((transaction) => {
      if (Array.isArray(transaction?.category)) {
        transaction.category.forEach((cat) => categoriesSet.add(cat));
      }
    });

    const uniqueCategories = Array.from(categoriesSet); // Convert Set to Array

    // Fetch existing categories from Firestore
    const categoriesRef = collection(db, "categories");
    const existingCategoriesSnapshot = await getDocs(categoriesRef);
    const existingCategories = new Set(
      existingCategoriesSnapshot.docs.map((doc) => doc.id)
    );

    // Filter out categories that are already in Firestore
    const newCategories = uniqueCategories.filter(
      (category) => !existingCategories.has(category)
    );

    // Add only new categories to Firestore
    const categoryPromises = newCategories.map((category) =>
      setDoc(doc(categoriesRef, category), { name: category })
    );

    await Promise.all(categoryPromises); // Run all writes in parallel
  }

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  const linkBankAccount = async () => {
    if (!ready) {
      console.warn("Plaid Link not ready");
      return;
    }

    if (!linkToken) {
      console.error("No link token available");
      return;
    }

    open();
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => linkBankAccount()}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500"
      >
        <Plus className="h-5 w-5 mr-2" />
        Add Account
      </button>
    </div>
  );
}

export default LinkBankButton;
