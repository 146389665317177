import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import DownloadButton from "./DownloadButton";
import QualitativeInsights from "./QualitativeInsights";
import { format, isWithinInterval, startOfYear, sub } from "date-fns";

interface SpendingReportProps {
  timeFrame: string;
}

function SpendingReport({ timeFrame, transactions }: SpendingReportProps) {
  const getDateRange = (timeFrame: string) => {
    const endDate = new Date();
    let startDate: Date;

    switch (timeFrame) {
      case "1M":
        startDate = sub(endDate, { months: 1 });
        break;
      case "3M":
        startDate = sub(endDate, { months: 3 });
        break;
      case "6M":
        startDate = sub(endDate, { months: 6 });
        break;
      case "YTD":
        startDate = startOfYear(endDate);
        break;
      case "1Y":
        startDate = sub(endDate, { years: 1 });
        break;
      case "5Y":
        startDate = sub(endDate, { years: 5 });
        break;
      case "ALL":
      default:
        startDate = new Date(0); // Include all transactions
        break;
    }

    return { startDate, endDate };
  };

  const { startDate, endDate } = getDateRange(timeFrame);

  const filteredTransactions = transactions.filter((transaction) =>
    isWithinInterval(new Date(transaction.date), {
      start: startDate,
      end: endDate,
    })
  );

  const totalSpending = filteredTransactions
    .reduce(
      (acc, transaction) =>
        transaction.amount < 0 ? acc + Math.abs(transaction.amount) : acc,
      0
    )
    .toFixed(2);

  function getLargestExpenseCategory() {
    // Group expenses by category
    const expensesByCategory = filteredTransactions.reduce(
      (acc: { [key: string]: number }, transaction) => {
        const category = transaction?.category; // Use category as string
        if (transaction.amount < 0) {
          acc[category] = (acc[category] || 0) + transaction.amount; // Summing negative amounts
        }
        return acc;
      },
      {}
    );

    // Find the category with the largest absolute expense
    let largestExpenseCategory = { category: "", amount: 0 };
    for (const [category, amount] of Object.entries(expensesByCategory)) {
      if (Math.abs(amount) > Math.abs(largestExpenseCategory.amount)) {
        largestExpenseCategory = { category, amount };
      }
    }

    return largestExpenseCategory;
  }

  // Example usage
  const largestExpenseCategory = getLargestExpenseCategory();
  const largestSpendingPercentage = (
    (largestExpenseCategory.amount / parseFloat(totalSpending)) *
    100
  ).toFixed(0);

  function calculateTotalExpenses() {
    // Group expenses by category and calculate the total for each
    const categoryTotals = filteredTransactions.reduce((acc, transaction) => {
      if (transaction.amount < 0) {
        const category = transaction?.category?.join(", "); // Combine category hierarchy as string
        const expense = Math.abs(transaction.amount); // Use absolute value for expense
        acc[category] = (acc[category] || 0) + expense;
      }
      return acc;
    }, {});

    // Calculate total expenses across all categories
    const totalExpenses = Object.values(categoryTotals).reduce(
      (sum, amount) => sum + amount,
      0
    );

    // Format the data
    const spendingData = Object.entries(categoryTotals).map(
      ([category, amount]) => ({
        category,
        amount,
        percentage: ((amount / totalExpenses) * 100).toFixed(2), // Calculate percentage
      })
    );

    return spendingData;
  }

  // Example usage
  const spendingData = calculateTotalExpenses();

  const netWorthData = filteredTransactions
    .filter((tx) => tx.amount < 0) // Only include transactions with amount < 0
    .map((tx) => ({
      date: tx.date,
      expenses: Math.abs(tx.amount), // No need to check tx.amount here since filter already ensures it's < 0
    }));

  const COLORS = [
    "#B100FF",
    "#10B981",
    "#F59E0B",
    "#6366F1",
    "#EC4899",
    "#8B5CF6",
  ];

  const insights = [
    {
      type: "warning" as const,
      title: "Housing Expenses Above Target",
      description:
        "Housing costs are 35% of income, above the recommended 30%. Consider ways to reduce housing expenses.",
    },
    {
      type: "positive" as const,
      title: "Food Spending Optimized",
      description:
        "Food expenses are within target range at 20% of total spending.",
    },
    {
      type: "action" as const,
      title: "Potential Savings Opportunity",
      description:
        "Reducing entertainment spending by 10% could save $40 monthly.",
    },
  ];

  // Calculate monthly average spending and trend
  const monthsInPeriod = Math.ceil(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 30)
  );
  const monthlyAverage = parseFloat(
    (parseFloat(totalSpending) / monthsInPeriod).toFixed(2)
  );

  // Calculate previous period's average spending
  const previousStartDate = sub(startDate, { months: monthsInPeriod });
  const previousEndDate = sub(endDate, { months: monthsInPeriod });

  const previousTransactions = transactions.filter((transaction) =>
    isWithinInterval(new Date(transaction.date), {
      start: previousStartDate,
      end: previousEndDate,
    })
  );

  const previousTotalSpending = previousTransactions.reduce(
    (acc, transaction) =>
      transaction.amount < 0 ? acc + Math.abs(transaction.amount) : acc,
    0
  );

  const previousMonthlyAverage = parseFloat(
    (previousTotalSpending / monthsInPeriod).toFixed(2)
  );

  // Calculate trend percentage
  const trendPercentage =
    ((monthlyAverage - previousMonthlyAverage) / previousMonthlyAverage) * 100;

  function calculateMonthlySpending() {
    const monthlyData = filteredTransactions.reduce(
      (
        acc: { [key: string]: { income: number; expenses: number } },
        transaction
      ) => {
        const month = format(new Date(transaction.date), "MMM"); // Extract month
        if (!acc[month]) {
          acc[month] = { income: 0, expenses: 0 };
        }

        if (transaction.amount > 0) {
          acc[month].income += transaction.amount;
        } else {
          acc[month].expenses += Math.abs(transaction.amount);
        }

        return acc;
      },
      {}
    );

    // Format the data for the chart
    return Object.entries(monthlyData).map(([month, { income, expenses }]) => ({
      month,
      income: parseFloat(income.toFixed(2)),
      expenses: parseFloat(expenses.toFixed(2)),
    }));
  }

  // Get monthly spending data
  const monthlySpendingData = calculateMonthlySpending();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Spending Analysis</h3>
        <DownloadButton
          reportId="spending-report"
          fileName="spending-analysis"
        />
      </div>

      <div id="spending-report" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Total Spending
            </h4>
            <p className="text-2xl font-bold text-red-500">
              ${parseFloat(totalSpending).toLocaleString()}
            </p>
            {/* <div className="flex items-center mt-2 text-red-500">
              <ArrowUpRight className="h-4 w-4 mr-1" />
              <span>+5.2% from last month</span>
            </div> */}
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Largest Category
            </h4>
            <p className="text-2xl font-bold">
              {largestExpenseCategory.category.split(",")[0] ||
                "No Category yet"}
            </p>
            <p className="text-sm text-gray-500 mt-2">
              {Math.abs(Number(largestSpendingPercentage)) || 0}% of total
              spending
            </p>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Monthly Average
            </h4>
            <p className="text-2xl font-bold">${monthlyAverage}</p>
            <div className="flex items-center mt-2 text-green-500">
              {/* <ArrowDownRight className="h-4 w-4 mr-1" /> */}
              <span>{Math.round(trendPercentage)}% trend</span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Spending by Category
            </h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={spendingData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="amount"
                  >
                    {spendingData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="mt-4 space-y-2">
              {spendingData.map((category, index) => (
                <div
                  key={category.category}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <div
                      className="w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: COLORS[index % COLORS.length] }}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-300">
                      {category.category}
                    </span>
                  </div>
                  <span className="text-sm font-medium">
                    ${parseFloat(category.amount.toFixed(2)).toLocaleString()}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Monthly Trend
            </h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={monthlySpendingData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="income" fill="#B100FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
            Insights & Recommendations
          </h4>
          <QualitativeInsights insights={insights} />
        </div> */}
      </div>
    </div>
  );
}

export default SpendingReport;
