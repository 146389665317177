import { Plus, Edit2, Trash2 } from "lucide-react";
import { BudgetItem } from "../../types/budget";
import { getDocument } from "../../utils/firebase-db";
import EditBudgetItemModal from "./EditBudgetItemModal";
import { useEffect, useState } from "react";
import { useAuthStore } from "../../store/authStore";
import { startOfMonth, endOfMonth, isWithinInterval } from "date-fns";
import { useNotificationStore } from "../../store/notificationStore";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";

interface BudgetOverviewProps {
  budgets?: BudgetItem[];
  setIsAddBudgetModalOpen: (isOpen: boolean) => void;
  categories?: string[];
  transactions?: any[];
}

function BudgetOverview({
  budgets,
  setIsAddBudgetModalOpen,
  categories,
  transactions,
}: BudgetOverviewProps) {
  const { user } = useAuthStore();
  const [editingBudget, setEditingBudget] = useState<BudgetItem | null>(null);
  const { addNotification } = useNotificationStore();

  // Get current month's date range
  const currentMonthStart = startOfMonth(new Date());
  const currentMonthEnd = endOfMonth(new Date());

  // Filter budgets by user and calculate current month's transactions
  const currentMonthBudgets = budgets
    ?.filter((budget) => budget.userId === user.id)
    .map((budget) => {
      // Check if it's a custom category
      const isCustomCategory = !categories
        ?.map((cat) => cat.name)
        .includes(budget.category);

      if (isCustomCategory) {
        // For custom categories, use the stored spent amount
        return {
          ...budget,
          spent: budget.spent || 0,
        };
      } else {
        // For Plaid categories, calculate from transactions
        const monthlyTransactions =
          transactions?.filter((transaction) => {
            const transactionDate = new Date(
              transaction.authorized_date || transaction.date
            );
            const transactionCategory =
              transaction.category?.[1]?.toLowerCase() ||
              transaction.category?.[0]?.toLowerCase();

            return (
              isWithinInterval(transactionDate, {
                start: currentMonthStart,
                end: currentMonthEnd,
              }) && transactionCategory === budget.category.toLowerCase()
            );
          }) || [];

        const monthlySpent = monthlyTransactions.reduce(
          (sum, transaction) => sum + Math.abs(transaction.amount || 0),
          0
        );

        return {
          ...budget,
          spent: monthlySpent,
          transactions: monthlyTransactions,
        };
      }
    });

  const totalBudget =
    currentMonthBudgets?.reduce((sum, item) => sum + item.limit, 0) || 0;
  const totalSpent =
    currentMonthBudgets?.reduce((sum, item) => sum + (item.spent || 0), 0) || 0;

  const handleDelete = async (id: string) => {
    try {
      const ref = doc(db, "budgetCategories", user.id, "categories", id);
      await deleteDoc(ref);
    } catch (error) {
      console.error("Error deleting budget category:", error);
    }
  };

  const handleEdit = (budget: BudgetItem) => {
    setEditingBudget(budget);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const userNotifications = await getDocument(
          "notificationSettings",
          user.id
        );

        if (userNotifications?.notifications?.push?.budgetAlerts) {
          currentMonthBudgets?.forEach((budget) => {
            const spentPercentage = ((budget.spent || 0) / budget.limit) * 100;
            if (spentPercentage >= 80) {
              addNotification({
                title: "Budget Category Near Limit",
                message: `Your budget category '${
                  budget.name
                }' is at ${spentPercentage.toFixed(1)}% of its limit!`,
                type: "warning",
              });
            }
          });
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [currentMonthBudgets, addNotification, user]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Budget Overview
          </h2>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {currentMonthStart.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </p>
        </div>
        <button
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md"
          onClick={() => setIsAddBudgetModalOpen(true)}
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Category
        </button>
      </div>

      <div className="mb-6">
        <div className="flex justify-between mb-2">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Monthly Budget
          </span>
          <span className="text-sm font-medium text-gray-900 dark:text-white">
            ${totalBudget.toLocaleString()}
          </span>
        </div>
        <div className="flex justify-between mb-2">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Spent This Month
          </span>
          <span className="text-sm font-medium text-gray-900 dark:text-white">
            ${totalSpent.toLocaleString()}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Remaining This Month
          </span>
          <span className="text-sm font-medium text-gray-900 dark:text-white">
            ${(totalBudget - totalSpent).toLocaleString()}
          </span>
        </div>
      </div>

      <div className="space-y-4">
        {currentMonthBudgets?.map((item) => {
          const percentage = ((item.spent || 0) / item.limit) * 100;
          const isOverBudget = (item.spent || 0) > item.limit;
          const isCustomCategory = !categories
            ?.map((cat) => cat.name)
            .includes(item.category);

          return (
            <div key={item.id} className="space-y-2">
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <span className="font-medium text-gray-900 dark:text-white capitalize">
                    {item.name}
                    {isCustomCategory && (
                      <span className="ml-2 text-xs text-gray-500">
                        (Custom)
                      </span>
                    )}
                  </span>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleEdit(item)}
                      className="p-1 text-gray-400 hover:text-primary-400 transition-colors"
                      title="Edit category"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDelete(item.id)}
                      className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                      title="Delete category"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <span
                  className={`text-sm font-medium ${
                    isOverBudget ? "text-red-500" : "text-green-500"
                  }`}
                >
                  ${(item.spent || 0).toLocaleString()} of $
                  {item.limit.toLocaleString()} this month
                </span>
              </div>
              <div className="relative h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                <div
                  className={`absolute top-0 left-0 h-full transition-all duration-500 rounded-full ${
                    isOverBudget
                      ? "bg-red-500"
                      : percentage >= 80
                      ? "bg-yellow-500"
                      : "bg-green-500"
                  }`}
                  style={{ width: `${Math.min(percentage, 100)}%` }}
                />
              </div>
              <div className="flex justify-between text-xs text-gray-500">
                <span>{percentage.toFixed(1)}% of monthly budget</span>
                <span>
                  ${(item.limit - (item.spent || 0)).toLocaleString()} left this
                  month
                </span>
              </div>
            </div>
          );
        })}

        {(!currentMonthBudgets || currentMonthBudgets.length === 0) && (
          <div className="text-center py-8">
            <p className="text-gray-500 dark:text-gray-400 mb-4">
              No budget categories yet. Add some to get started!
            </p>
          </div>
        )}
      </div>

      {editingBudget && (
        <EditBudgetItemModal
          isOpen={true}
          onClose={() => setEditingBudget(null)}
          budgetItem={editingBudget}
          categories={categories}
        />
      )}
    </div>
  );
}

export default BudgetOverview;
