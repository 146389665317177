import React from "react";
import { TrendingUp, TrendingDown, AlertCircle } from "lucide-react";
import { BudgetItem } from "../../types/budget";
import { useAuthStore } from "../../store/authStore";

interface SpendingInsightsProps {
  budgetItems: BudgetItem[];
}

function SpendingInsights({ budgets, transactions }: SpendingInsightsProps) {
  const { user } = useAuthStore();

  const uniqueBudgets = budgets?.filter((budget) => budget.userId === user.id);
  const totalBudget = uniqueBudgets?.reduce((sum, item) => sum + item.limit, 0);
  const totalSpent = uniqueBudgets?.reduce((sum, item) => sum + item.spent, 0);

  // Find categories that are over budget
  const overBudgetCategories = uniqueBudgets?.filter(
    (item) => item.spent > item.limit
  );

  // Find categories close to their limit (>80%)
  const nearLimitCategories = uniqueBudgets?.filter(
    (item) => item.spent / item.limit >= 0.8 && item.spent <= item.limit
  );

  // Calculate biggest expenses
  const sortedBySpent = uniqueBudgets?.length
    ? [...uniqueBudgets].sort((a, b) => b.spent - a.spent)
    : [];
  const topExpenses = sortedBySpent.slice(0, 3);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
        Spending Insights
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Overall Status */}
        <div className="p-4 rounded-lg border border-gray-200 dark:border-gray-700">
          <h3 className="font-medium text-gray-900 dark:text-white mb-4">
            Overall Status
          </h3>
          <div className="flex items-center justify-between">
            <div>
              <div>
                <p className="text-sm text-gray-500">Total Spent</p>
                <p className="text-xl font-bold text-gray-900 dark:text-white">
                  ${totalSpent?.toLocaleString()}
                </p>
              </div>
            </div>
            <div
              className={`flex items-center ${
                totalSpent <= totalBudget ? "text-green-500" : "text-red-500"
              }`}
            >
              {totalSpent <= totalBudget ? (
                <TrendingDown className="h-5 w-5 mr-1" />
              ) : (
                <TrendingUp className="h-5 w-5 mr-1" />
              )}
              <span className="font-medium">
                {((totalSpent / totalBudget) * 100).toFixed(1)}%
              </span>
            </div>
          </div>
        </div>

        {/* Alerts */}
        <div className="p-4 rounded-lg border border-gray-200 dark:border-gray-700">
          <h3 className="font-medium text-gray-900 dark:text-white mb-4">
            Alerts
          </h3>
          <div className="space-y-2">
            {overBudgetCategories?.length || nearLimitCategories?.length ? (
              overBudgetCategories?.map((item) => (
                <div key={item.id} className="flex items-center text-red-500">
                  <AlertCircle className="h-4 w-4 mr-2" />
                  <span className="text-sm">
                    {item.name} is over budget by $
                    {(item.spent - item.limit)?.toLocaleString()}
                  </span>
                </div>
              ))
            ) : (
              <span className="ml-3 text-sm text-gray-900 dark:text-white">
                No Alerts
              </span>
            )}
            {overBudgetCategories?.length || nearLimitCategories?.length
              ? nearLimitCategories?.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center text-yellow-500"
                  >
                    <AlertCircle className="h-4 w-4 mr-2" />
                    <span className="text-sm">
                      {item.name} is at{" "}
                      {((item.spent / item.limit) * 100).toFixed(0)}% of budget
                    </span>
                  </div>
                ))
              : null}
          </div>
        </div>

        {/* Top Expenses */}
        <div className="p-4 rounded-lg border border-gray-200 dark:border-gray-700">
          <h3 className="font-medium text-gray-900 dark:text-white mb-4">
            Top Expenses
          </h3>
          <div className="space-y-3">
            {topExpenses?.map((item, index) => (
              <div key={item.id} className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="w-6 h-6 flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-700 text-xs font-medium">
                    {index + 1}
                  </div>
                  <span className="ml-3 text-sm text-gray-900 dark:text-white">
                    {item.name}
                  </span>
                </div>
                <span className="text-sm font-medium text-gray-900 dark:text-white">
                  ${item.spent?.toLocaleString()}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Recommendations */}
        <div className="p-4 rounded-lg border border-gray-200 dark:border-gray-700">
          <h3 className="font-medium text-gray-900 dark:text-white mb-4">
            Recommendations
          </h3>
          <ul className="space-y-2 text-sm text-gray-600 dark:text-gray-300">
            {overBudgetCategories?.length > 0 && (
              <li>• Review spending in overbudget categories</li>
            )}
            {nearLimitCategories?.length > 0 && (
              <li>• Monitor categories approaching their limits</li>
            )}
            {totalSpent > totalBudget && (
              <li>• Consider adjusting budget allocations</li>
            )}
            <li>• Track recurring expenses for better planning</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SpendingInsights;
