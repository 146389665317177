import { format } from "date-fns";
import { CreditCard as CreditCardIcon } from "lucide-react";
import { CreditCard } from "../../types/credit";

interface CreditCardDetailsProps {
  card: CreditCard;
  onDelete: (id: string) => void;
}

function CreditCardDetails({ liabilities, card }: CreditCardDetailsProps) {
  const availableCredit = card.balances.limit - card.balances.current;

  const utilization = card.balances.current / card.balances.limit;

  const credit = liabilities.liabilities.credit;

  function getCreditCardType(officialName) {
    if (!officialName) return "Unknown Card";

    // Split the name into words
    const words = officialName.split(" ");

    // Look for a keyword that represents the card type
    const keywords = [
      "Platinum",
      "Gold",
      "Silver",
      "Diamond",
      "Standard",
      "Premium",
      "Elite",
      "Business",
    ];
    const cardType = words.find((word) => keywords.includes(word));

    // Return formatted card type
    return cardType ? `${cardType} Credit Card` : "Generic Credit Card";
  }

  // Example usage:

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transition-all hover:shadow-xl">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
            <CreditCardIcon className="h-6 w-6 text-primary-400" />
          </div>
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">
              {card.issuer}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {getCreditCardType(card.official_name)}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Current Balance
          </p>
          <p className="text-xl font-bold text-gray-900 dark:text-white">
            ${card.balances.current.toLocaleString() || 0}
          </p>
        </div>
        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Available Credit
          </p>
          <p className="text-xl font-bold text-gray-900 dark:text-white">
            ${availableCredit.toLocaleString()}
          </p>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <div className="flex justify-between items-center mb-2">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Credit Utilization
            </p>
            <p
              className={`text-sm font-medium ${
                utilization > 30 ? "text-red-500" : "text-green-500"
              }`}
            >
              {utilization.toFixed(1)}%
            </p>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
            <div
              className={`h-2 rounded-full ${
                utilization > 30 ? "bg-red-500" : "bg-green-500"
              }`}
              style={{ width: `${utilization}%` }}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 text-sm">
          <div>
            <p className="text-gray-500 dark:text-gray-400">
              Statement Balance
            </p>
            <p className="font-medium text-gray-900 dark:text-white">
              ${credit[0]?.last_statement_balance?.toLocaleString() || 0}
            </p>
          </div>
          <div>
            <p className="text-gray-500 dark:text-gray-400">Minimum Payment</p>
            <p className="font-medium text-gray-900 dark:text-white">
              ${credit[0]?.minimum_payment_amount?.toLocaleString()}
            </p>
          </div>
          <div>
            <p className="text-gray-500 dark:text-gray-400">Payment Due Date</p>
            <p className="font-medium text-gray-900 dark:text-white">
              {format(new Date(credit[0].next_payment_due_date), "MMM d, yyyy")}
            </p>
          </div>
          <div>
            <p className="text-gray-500 dark:text-gray-400">APR</p>
            <p className="font-medium text-gray-900 dark:text-white">
              {credit[0].aprs[2].apr_percentage}%
            </p>
          </div>
        </div>

        <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
          {/* <h4 className="font-medium text-gray-900 dark:text-white mb-3">
            Recent Charges
          </h4> */}
          <div className="space-y-2">
            {/* {card.recentCharges.map((charge) => (
              <div
                key={charge.id}
                className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">
                    {charge.merchant}
                  </p>
                  <p className="text-xs text-gray-500">
                    {format(new Date(charge.date), "MMM d, yyyy")}
                  </p>
                </div>
                <p className="font-medium text-gray-900 dark:text-white">
                  ${charge.amount.toLocaleString()}
                </p>
              </div>
            ))} */}
          </div>
        </div>

        {/* <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Gift className="h-5 w-5 text-primary-400" />
              <span className="text-sm font-medium text-gray-900 dark:text-white"></span>
            </div>
            <button className="text-sm text-primary-400 hover:text-primary-500 font-medium">
              Redeem
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default CreditCardDetails;
