import { useEffect, useState } from "react";
import {
  TrendingUp,
  TrendingDown,
  Search,
  Filter,
  Plus,
  Edit2,
  Trash2,
} from "lucide-react";
import AddStockModal from "../../../src/pages/investments/components/AddStockModal";
import { useAuthStore } from "../../store/authStore";
import {
  getDocument,
} from "../../utils/firebase-db";
import { useNotificationStore } from "../../store/notificationStore";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../config/firebase";

interface StockFilters {
  search: string;
  sortBy:
    | "value_desc"
    | "value_asc"
    | "gain_desc"
    | "gain_asc"
    | "gainPercent_desc"
    | "gainPercent_asc"
    | "name_asc"
    | "name_desc";
}

const API_KEY = "KAD1T18GFGKV3BL8";

function StocksPage({ investments, addedStocks }) {
  const [filters, setFilters] = useState({
    search: "",
    sortBy: "value_desc",
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingStock, setEditingStock] = useState(null);
  const [stocks, setStocks] = useState([]);
  const { addNotification } = useNotificationStore();
  const [userNotifications, setUserNotifications] = useState();
  const { user } = useAuthStore();

  // Async function to fetch current stock price
  async function fetchStockPrice(symbol) {
    try {
      const response = await fetch(
        `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${symbol}&apikey=${API_KEY}`
      );
      const data = await response.json();

      return parseFloat(data["Global Quote"]["05. price"]);
    } catch (error) {
      return null;
    }
  }

  // Combine holdings and manually added stocks, fetching live prices for each.
  useEffect(() => {
    async function updateStockData() {
      let combinedStocks = [];
      let holdingsLength = 0;

      // Process holdings from investments (if available)
      if (investments?.holdings) {
        const holdingsWithDetails = await Promise.all(
          investments.holdings.map(async (holding, index) => {
            const security = investments.securities?.find(
              (sec) => sec.security_id === holding.security_id
            );
            // Fetch the current price using the security's ticker symbol
            const fetchedPrice = await fetchStockPrice(
              security?.ticker_symbol || ""
            );
            // Use fetched price if available; fallback to institution_price otherwise.
            const currentPrice =
              fetchedPrice !== null ? fetchedPrice : holding.institution_price;
            const marketValue = currentPrice * holding.quantity;
            const totalCost = holding.cost_basis * holding.quantity;
            const totalGainLoss = marketValue - totalCost;
            const percentageGainLoss = totalCost
              ? (totalGainLoss / totalCost) * 100
              : 0;

            return {
              symbol: security?.ticker_symbol || "Unknown",
              name: security?.name || "Unknown",
              shares: holding.quantity,
              avgCost: holding.cost_basis.toFixed(2),
              currentPrice: currentPrice.toFixed(2),
              marketValue: marketValue.toFixed(2),
              totalGainLoss: totalGainLoss.toFixed(2),
              percentageGainLoss: percentageGainLoss.toFixed(2),
              id: index + 1,
              isManuallyAdded: false,
              userId: user.id,
            };
          })
        );
        holdingsLength = holdingsWithDetails.length;
        combinedStocks = combinedStocks.concat(holdingsWithDetails);
      }

      // Process manually added stocks
      if (addedStocks) {
        const addedStocksDetails = await Promise.all(
          addedStocks.map(async (stock, index) => {
            const fetchedPrice = await fetchStockPrice(stock.symbol);
            const currentPrice =
              fetchedPrice !== null ? fetchedPrice : stock.institution_price;
            const marketValue = stock.quantity * currentPrice;
            const totalCost = stock.cost_basis * stock.quantity;
            const totalGainLoss = marketValue - totalCost;
            const percentageGainLoss = totalCost
              ? (totalGainLoss / totalCost) * 100
              : 0;

            return {
              symbol: stock.symbol,
              name: stock.name || "Unknown",
              shares: stock.quantity || 0,
              avgCost: stock.cost_basis.toFixed(2),
              currentPrice: currentPrice.toFixed(2),
              marketValue: marketValue.toFixed(2),
              totalGainLoss: totalGainLoss.toFixed(2),
              percentageGainLoss: percentageGainLoss.toFixed(2),
              id: stock.id || holdingsLength + index + 1,
              isManuallyAdded: true,
              originalData: stock,
              userId: user.id,
            };
          })
        );
        combinedStocks = combinedStocks.concat(addedStocksDetails);
      }

      // Keep only stocks for the current user
      combinedStocks = combinedStocks.filter(
        (stock) => stock.userId === user.id
      );
      setStocks(combinedStocks);
    }

    updateStockData();
  }, [investments, addedStocks, user.id]);

  // Filter and sort stocks based on search text and sortBy filter
  const filteredStocks = stocks
    .filter((stock) => {
      const searchLower = filters.search.toLowerCase();
      return (
        stock.name.toLowerCase().includes(searchLower) ||
        stock.symbol.toLowerCase().includes(searchLower)
      );
    })
    .sort((a, b) => {
      const [field, direction] = filters.sortBy.split("_");
      const getValue = (stock) => {
        switch (field) {
          case "name":
            return stock.name;
          case "value":
            return parseFloat(stock.marketValue);
          case "gain":
            return parseFloat(stock.totalGainLoss);
          case "gainPercent":
            return parseFloat(stock.percentageGainLoss);
          default:
            return parseFloat(stock.marketValue);
        }
      };

      const aValue = getValue(a);
      const bValue = getValue(b);

      // For string values (e.g., name) use localeCompare; otherwise, numeric sort.
      if (typeof aValue === "string") {
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      return direction === "asc" ? aValue - bValue : bValue - aValue;
    });

  // Calculate total values from all stocks
  const totalValue = stocks.reduce(
    (acc, stock) => acc + parseFloat(stock.marketValue),
    0
  );
  const totalCostBasis = stocks.reduce(
    (sum, stock) => sum + parseFloat(stock.avgCost) * stock.shares,
    0
  );
  const totalGainLoss = totalValue - totalCostBasis;
  const totalReturnPercentage = totalCostBasis
    ? ((totalValue - totalCostBasis) / totalCostBasis) * 100
    : 0;

  // Handlers for adding, editing, and deleting stocks
  const handleAddStock = async (data) => {
    const mockCurrentPrice = data.avgCost * 1.1; // or use fetchStockPrice(data.symbol) if needed
    const marketValue = data.shares * mockCurrentPrice;

    const newStock = {
      symbol: data.symbol,
      name: data.name,
      quantity: data.shares,
      cost_basis: data.avgCost,
      institution_price: mockCurrentPrice,
      institution_value: marketValue,
      sector: data.sector,
      purchaseDate: data.purchaseDate,
      notes: data.notes || "",
      userId: user.id,
      createdAt: new Date().toISOString(),
    };

    await addDoc(collection(db, "addedStocks", user.id, "stocks"), newStock);
    if (userNotifications?.notifications.push.investmentAlerts) {
      await addNotification({
        type: "success",
        message: `Stock ${data.symbol} added successfully!`,
        title: `New stock`,
      });
    }

    setIsAddModalOpen(false);
  };

  const handleEditStock = async (id, data) => {
    try {
      const mockCurrentPrice = data.avgCost * 1.1;
      const marketValue = data.shares * mockCurrentPrice;

      const updatedStock = {
        symbol: data.symbol,
        name: data.name,
        quantity: data.shares,
        cost_basis: data.avgCost,
        institution_price: mockCurrentPrice,
        institution_value: marketValue,
        sector: data.sector,
        purchaseDate: data.purchaseDate,
        notes: data.notes || "",
        userId: user.id,
        updatedAt: new Date().toISOString(),
      };

      const stockRef = doc(db, "addedStocks", user.id, "stocks", id);
      await updateDoc(stockRef, updatedStock);

      setEditingStock(null);
      setIsAddModalOpen(false);
    } catch (error) {
      console.error("Failed to update stock:", error);
    }
  };

  const handleDeleteStock = async (stockId) => {
    if (!stockId) {
      console.error("Invalid stock ID");
      return;
    }
    const stockRef = doc(db, "addedStocks", user.id, "stocks", stockId);

    await deleteDoc(stockRef);
  };

  useEffect(() => {
    const fetchnotifications = async () => {
      const userNotifications = await getDocument(
        "notificationSettings",
        user.id
      );

      setUserNotifications(userNotifications);
    };

    fetchnotifications();
  }, []);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <style>
          {`
            /* Hide number input spinners */
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
          `}
        </style>
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search stocks..."
            value={filters.search}
            onChange={(e) =>
              setFilters((f) => ({ ...f, search: e.target.value }))
            }
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <div className="flex items-center">
            <Filter className="h-5 w-5 text-gray-400 mr-2" />
            <select
              value={filters.sortBy}
              onChange={(e) =>
                setFilters((f) => ({ ...f, sortBy: e.target.value as any }))
              }
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-400"
            >
              <option value="value_desc">Market Value (High to Low)</option>
              <option value="value_asc">Market Value (Low to High)</option>
              <option value="gain_desc">Total Gain/Loss (High to Low)</option>
              <option value="gain_asc">Total Gain/Loss (Low to High)</option>
              <option value="gainPercent_desc">
                % Gain/Loss (High to Low)
              </option>
              <option value="gainPercent_asc">% Gain/Loss (Low to High)</option>
              <option value="name_asc">Name (A to Z)</option>
              <option value="name_desc">Name (Z to A)</option>
            </select>
          </div>
          <button
            onClick={() => {
              setEditingStock(null);
              setIsAddModalOpen(true);
            }}
            className="flex items-center px-4 py-2 text-white bg-primary-400 hover:bg-primary-500 rounded-lg"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Stock
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-lg font-medium mb-4">Total Value</h3>
          <p className="text-2xl font-bold">
            ${isNaN(totalValue) ? 0 : totalValue?.toLocaleString()}
          </p>
          <div
            className={
              totalReturnPercentage >= 0
                ? " flex items-center mt-2 text-green-500"
                : "flex items-center mt-2 text-red-500"
            }
          >
            {totalReturnPercentage >= 0 ? (
              <TrendingUp className="h-4 w-4 mr-1" />
            ) : (
              <TrendingDown className="h-4 w-4 mr-1" />
            )}

            <span>
              {(totalReturnPercentage >= 0 ? "+" : "-") +
                Math.abs(totalReturnPercentage || 0).toFixed(1)}
              % Total Return
            </span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-lg font-medium mb-4">Today's Gain/Loss</h3>
          <p
            className={
              totalGainLoss >= 0
                ? "text-2xl font-bold text-green-500"
                : "text-2xl font-bold text-red-500"
            }
          >
            {(totalGainLoss >= 0 ? "+$" : "-$") +
              Math.abs(totalGainLoss || 0).toLocaleString()}
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-lg font-medium mb-4">Total Cost Basis</h3>
          <p className="text-2xl font-bold">
            ${(totalCostBasis || 0)?.toLocaleString()}
          </p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium">Stock Holdings</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Symbol/Name
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Shares
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Avg Cost
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Price
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Market Value
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Total Gain/Loss
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {filteredStocks?.map((stock) => (
                <tr
                  key={stock.id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="font-medium text-gray-900 dark:text-white">
                        {stock.symbol}
                      </div>
                      <div className="text-sm text-gray-500">{stock.name}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {stock.shares.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${stock.avgCost}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${stock.currentPrice}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${stock.marketValue}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex flex-col items-end">
                      <div
                        className={`flex items-center ${
                          parseFloat(stock.totalGainLoss) >= 0
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {parseFloat(stock.totalGainLoss) >= 0 ? (
                          <TrendingUp className="h-4 w-4 mr-1" />
                        ) : (
                          <TrendingDown className="h-4 w-4 mr-1" />
                        )}
                        <span>
                          {parseFloat(stock.totalGainLoss) >= 0 ? "+" : "-"}$
                          {Math.abs(stock.totalGainLoss)}
                        </span>
                      </div>
                      <span
                        className={`text-sm ${
                          parseFloat(stock.percentageGainLoss) >= 0
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {parseFloat(stock.percentageGainLoss) >= 0 ? "+" : ""}
                        {stock.percentageGainLoss}%
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    {stock.isManuallyAdded && (
                      <div className="flex items-center justify-end space-x-2">
                        <button
                          onClick={() => {
                            setEditingStock(stock);
                            setIsAddModalOpen(true);
                          }}
                          className="p-2 text-gray-400 hover:text-primary-400 transition-colors"
                        >
                          <Edit2 className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() =>
                            handleDeleteStock(stock.originalData.id)
                          }
                          className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AddStockModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setEditingStock(null);
        }}
        onAdd={handleAddStock}
        editingStock={editingStock}
        onEdit={handleEditStock}
      />
    </div>
  );
}

export default StocksPage;
