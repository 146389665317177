import React, { useState } from "react";
import { X, MapPin, FileText } from "lucide-react";
import { Property } from "../../../types/property";
import { format } from "date-fns";

interface PropertyDetailsModalProps {
  property: Property;
  onClose: () => void;
}

function PropertyDetailsModal({
  property,
  onClose,
}: PropertyDetailsModalProps) {
  const [activeTab, setActiveTab] = useState<
    "overview" | "financial" | "documents" | "maintenance"
  >("overview");

  function estimateMaintenance(
    propertyValue: number,
    yearBuilt: number
  ): number {
    const currentYear = new Date().getFullYear();
    const propertyAge = currentYear - yearBuilt;
    let maintenanceRate = 0.01; // Default 1%

    if (propertyAge < 10) {
      maintenanceRate = 0.005; // 0.5% for newer properties
    } else if (propertyAge >= 10 && propertyAge <= 30) {
      maintenanceRate = 0.01; // 1% for mid-aged properties
    } else {
      maintenanceRate = 0.015; // 1.5% for older properties
    }

    const annualMaintenance = propertyValue * maintenanceRate;
    return annualMaintenance / 12; // Monthly maintenance cost
  }

  const paymentsMade = Math.max(
    (new Date().getFullYear() - getPurchaseYear()) * 12,
    0
  );

  // const monthlyIncome = property.ownerOccupied
  //   ? 0
  //   : property.rental?.monthlyRent ?? 0;

  // const monthlyExpenses =
  //   (property.mortgage?.monthlyPayment || 0) +
  //   (property.expenses?.propertyTax || 0) / 12 +
  //   (property.expenses?.insurance || 0) / 12 +
  //   (property.expenses?.utilities || 0) +
  //   (property.expenses?.maintenance || 0) / 12 +
  //   (property.expenses?.management || 0) / 12;
  // const monthlyCashFlow = monthlyIncome - monthlyExpenses;

  // const capRate = property.currentValue
  //   ? ((monthlyIncome * 12 -
  //       Object.values(property.expenses || {}).reduce((a, b) => a + b, 0)) /
  //       property.currentValue) *
  //     100
  //   : 0;

  const purchasePrice = property.history
    ? Object.values(property.history)
        .filter((record) => record?.price)
        .map((record) => record.price)
        .pop() || "N/A"
    : "N/A";

  const lastSalePrice = property.history
    ? Object.values(property.history)
        .filter((record) => record?.price) // Get only records with a price
        .map((record) => record.price)
        .pop() || "N/A"
    : "N/A";

  function getLastSaleDate(): string {
    return property.history
      ? Object.values(property.history)
          .filter((record) => record?.date)
          .map((record) => record.date)
          .pop()
        ? format(
            new Date(
              Object.values(property.history)
                .filter((record) => record?.date)
                .map((record) => record.date)
                .pop()
            ),
            "MMM d, yyyy"
          )
        : "N/A"
      : "N/A";
  }

  function getPurchaseYear(): number {
    if (!property.history) return new Date().getFullYear();
    const lastSale = Object.values(property.history)
      .filter((record) => record.date)
      .map((record) => new Date(record.date).getFullYear())
      .pop();
    return lastSale || new Date().getFullYear();
  }

  const originalLoanAmount = purchasePrice * 0.8;

  function estimateMortgageDetails() {
    const annualRate = 0.05; // Assume 5% interest
    const loanTerm = 30; // Assume 30 years

    const monthlyRate = annualRate / 12;
    const totalPayments = loanTerm * 12;

    const currentLoanBalance =
      originalLoanAmount * (1 - paymentsMade / totalPayments);

    const monthlyPayment = originalLoanAmount
      ? (originalLoanAmount *
          monthlyRate *
          Math.pow(1 + monthlyRate, totalPayments)) /
        (Math.pow(1 + monthlyRate, totalPayments) - 1)
      : 0;

    return {
      originalAmount: originalLoanAmount.toLocaleString(),
      monthlyPayment: monthlyPayment.toFixed(2),
    };
  }

  const maturityDate = new Date(getLastSaleDate());
  maturityDate.setFullYear(maturityDate.getFullYear() + 30);

  const result = estimateMortgageDetails();

  const monthlyMaintenance = estimateMaintenance(
    property.taxAssessments?.["2023"]?.value || 0,
    property.yearBuilt || new Date().getFullYear()
  );

  const annualExpenses = (monthlyMaintenance * 12).toLocaleString();

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div
          className="relative w-full max-w-6xl bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Modal header */}
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <div>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {property.address}
              </h2>
              <div className="flex items-center text-gray-500 mt-1">
                <MapPin className="h-4 w-4 mr-1" />
                {property.city}, {property.state} {property.zipCode}
              </div>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          {/* Tabs */}
          <div className="flex border-b border-gray-200 dark:border-gray-700">
            <button
              onClick={() => setActiveTab("overview")}
              className={`px-6 py-3 text-sm font-medium border-b-2 ${
                activeTab === "overview"
                  ? "border-primary-400 text-primary-400"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab("financial")}
              className={`px-6 py-3 text-sm font-medium border-b-2 ${
                activeTab === "financial"
                  ? "border-primary-400 text-primary-400"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              Financial Details
            </button>
            {/* <button
              onClick={() => setActiveTab("maintenance")}
              className={`px-6 py-3 text-sm font-medium border-b-2 ${
                activeTab === "maintenance"
                  ? "border-primary-400 text-primary-400"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              Maintenance & Improvements
            </button>
            <button
              onClick={() => setActiveTab("documents")}
              className={`px-6 py-3 text-sm font-medium border-b-2 ${
                activeTab === "documents"
                  ? "border-primary-400 text-primary-400"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              Documents & Images
            </button> */}
          </div>

          {/* Content */}
          <div className="p-6">
            {activeTab === "overview" && (
              <div className="space-y-6">
                {/* Property Details */}
                <div className="grid grid-cols-2 gap-6">
                  <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-500 mb-2">
                      Property Details
                    </h3>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-sm">Type</span>
                        <span className="text-sm font-medium">
                          {property.propertyType.replace("_", " ")}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Year Built</span>
                        <span className="text-sm font-medium">
                          {property.yearBuilt}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Square Feet</span>
                        <span className="text-sm font-medium">
                          {property?.squareFootage?.toLocaleString()}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Lot Size</span>
                        <span className="text-sm font-medium">
                          {(property.lotSize / 43560).toLocaleString()} acres
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Bedrooms</span>
                        <span className="text-sm font-medium">
                          {property.bedrooms}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Bathrooms</span>
                        <span className="text-sm font-medium">
                          {property.bathrooms}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Parking</span>
                        <span className="text-sm font-medium">
                          {property.garageSpaces} spaces
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Rental Information */}
                  {/* <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-500 mb-2">
                      Rental Information
                    </h3>
                    {property.rental ? (
                      <div className="space-y-2">
                        <div className="flex justify-between">
                          <span className="text-sm">Monthly Rent</span>
                          <span className="text-sm font-medium">
                            ${property.rental.monthlyRent.toLocaleString()}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm">Lease Period</span>
                          <span className="text-sm font-medium">
                            {format(
                              new Date(property.rental.leaseStart),
                              "MMM d, yyyy"
                            )}{" "}
                            -
                            {format(
                              new Date(property.rental.leaseEnd),
                              "MMM d, yyyy"
                            )}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm">Security Deposit</span>
                          <span className="text-sm font-medium">
                            ${property.rental.securityDeposit.toLocaleString()}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm">Tenant</span>
                          <span className="text-sm font-medium">
                            {property.rental.tenantName}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm text-gray-500">
                        Not currently rented
                      </p>
                    )}
                  </div> */}

                  {/* Monthly Expenses */}
                  <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-500 mb-2">
                      Monthly Expenses
                    </h3>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-sm">Mortgage</span>
                        <span className="text-sm font-medium">
                          $
                          {property.mortgage?.monthlyPayment?.toLocaleString() ||
                            "N/A"}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Property Tax</span>
                        <span className="text-sm font-medium">
                          $
                          {(
                            (property.propertyTaxes["2023"].total || 0) / 12
                          ).toLocaleString()}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Insurance</span>
                        <span className="text-sm font-medium">
                          $
                          {(
                            (property.expenses?.insurance || 0) / 12
                          ).toLocaleString()}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Utilities</span>
                        <span className="text-sm font-medium">
                          $
                          {property.expenses?.utilities?.toLocaleString() ||
                            "N/A"}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Maintenance</span>
                        <span className="text-sm font-medium">
                          $
                          {estimateMaintenance(
                            property.taxAssessments?.["2023"]?.value,
                            new Date().getFullYear()
                          ).toLocaleString()}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm">Management</span>
                        <span className="text-sm font-medium">
                          $
                          {(
                            (property.expenses?.management || 0) / 12
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "financial" && (
              <div className="space-y-6">
                {/* Financial Overview */}
                <div className="grid grid-cols-2 gap-6">
                  <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-500 mb-2">
                      Purchase Details
                    </h3>
                    <p className="text-2xl font-bold">
                      ${purchasePrice.toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-500">{getLastSaleDate()}</p>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-500 mb-2">
                      Current Value
                    </h3>
                    <p className="text-2xl font-bold">
                      $
                      {property.taxAssessments?.[
                        "2023"
                      ]?.value?.toLocaleString()}
                    </p>
                    <p className="text-sm text-green-500">
                      +
                      {(
                        ((property.taxAssessments?.["2023"]?.value -
                          lastSalePrice) /
                          lastSalePrice) *
                        100
                      ).toFixed(1)}
                      % appreciation
                    </p>
                  </div>

                  {/* <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-500 mb-2">
                      Monthly Cash Flow
                    </h3>
                    <p
                      className={`text-2xl font-bold ${
                        monthlyCashFlow >= 0 ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      ${Math.abs(monthlyCashFlow).toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-500">Net monthly income</p>
                  </div> */}

                  {/* <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-500 mb-2">
                      Cap Rate
                    </h3>
                    <p className="text-2xl font-bold">{capRate.toFixed(2)}%</p>
                    <p className="text-sm text-gray-500">Annual return</p>
                  </div> */}
                </div>

                {/* Mortgage Details */}
                <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-medium mb-4">
                    Mortgage Details (Estimated)
                  </h3>
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <p className="text-sm text-gray-500">Original Amount</p>
                      <p className="text-lg font-medium">
                        ${result.originalAmount?.toLocaleString() || "N/A"}
                      </p>
                    </div>
                    {/* <div>
                      <p className="text-sm text-gray-500">Current Balance</p>
                      <p className="text-lg font-medium">
                        ${property.mortgage.currentBalance.toLocaleString()}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Interest Rate</p>
                      <p className="text-lg font-medium">
                        {property.mortgage.interestRate}%
                      </p>
                    </div> */}
                    <div>
                      <p className="text-sm text-gray-500">Monthly Payment</p>
                      <p className="text-lg font-medium">
                        ${result.monthlyPayment.toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700 grid grid-cols-3 gap-6">
                    {/* <div>
                      <p className="text-sm text-gray-500">Lender</p>
                      <p className="text-base font-medium">
                        {property.mortgage.lender}
                      </p>
                    </div> */}
                    {/* <div>
                      <p className="text-sm text-gray-500">Next Payment</p>
                      <p className="text-base font-medium">
                        {format(
                          new Date(property.mortgage.nextPaymentDate),
                          "MMM d, yyyy"
                        )}
                      </p>
                    </div> */}
                    <div>
                      <p className="text-sm text-gray-500">Maturity Date</p>
                      <p className="text-base font-medium">
                        {!maturityDate.toString() == "Invalid Date"
                          ? format(new Date(maturityDate), "MMM d, yyyy")
                          : "No date"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* {activeTab === "maintenance" && (
              <div className="space-y-6">
                <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-medium mb-4">
                    Improvements & Renovations
                  </h3>
                  <div className="space-y-4">
                    {property.improvements.map((improvement) => (
                      <div
                        key={improvement.id}
                        className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-lg"
                      >
                        <div>
                          <p className="font-medium">
                            {improvement.description}
                          </p>
                          <p className="text-sm text-gray-500">
                            {format(new Date(improvement.date), "MMM d, yyyy")}{" "}
                            • {improvement.contractor}
                          </p>
                          {improvement.warranty && (
                            <p className="text-xs text-gray-500">
                              Warranty: {improvement.warranty}
                            </p>
                          )}
                        </div>
                        <div className="text-right">
                          <p className="font-medium">
                            ${improvement.cost.toLocaleString()}
                          </p>
                          <p className="text-sm text-gray-500 capitalize">
                            {improvement.category}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )} */}

            {/* {activeTab === "documents" && (
              <div className="space-y-6">
                <div className="grid grid-cols-2 gap-6">
                  <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
                    <h3 className="text-lg font-medium mb-4">Documents</h3>
                    <div className="space-y-4">
                      {property.documents.map((document) => (
                        <div
                          key={document.id}
                          className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-lg"
                        >
                          <div className="flex items-center">
                            <FileText className="h-5 w-5 text-gray-400 mr-3" />
                            <div>
                              <p className="font-medium">{document.name}</p>
                              <p className="text-sm text-gray-500">
                                {format(new Date(document.date), "MMM d, yyyy")}
                              </p>
                            </div>
                          </div>
                          <a
                            href={document.url}
                            className="text-primary-400 hover:text-primary-500"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
                    <h3 className="text-lg font-medium mb-4">Images</h3>
                    <div className="grid grid-cols-2 gap-4">
                      {property.images.map((image) => (
                        <div
                          key={image.id}
                          className="relative aspect-video rounded-lg overflow-hidden"
                        >
                          <img
                            src={image.url}
                            alt={`Property ${image.type}`}
                            className="w-full h-full object-cover"
                          />
                          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-2">
                            <p className="text-xs text-white capitalize">
                              {image.type}
                            </p>
                            <p className="text-xs text-gray-300">
                              {format(new Date(image.date), "MMM d, yyyy")}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetailsModal;
