import React from 'react';
import { Settings, Bell, Lock, Globe } from 'lucide-react';
import { useSettingsStore } from '../store/settingsStore';
import NotificationSettings from '../components/settings/NotificationSettings';
import SecuritySettings from '../components/settings/SecuritySettings';
import PreferenceSettings from '../components/settings/PreferenceSettings';

function SettingsPage() {
  return (
    <div className="max-w-4xl mx-auto space-y-6">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Settings</h1>
      
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
        <div className="p-6">
          <div className="space-y-8">
            <NotificationSettings />
            <SecuritySettings />
            {/* <PreferenceSettings /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;