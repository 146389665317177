import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";
import { Wallet, ArrowLeft } from "lucide-react";
import { api } from "../../utils/api";

function TwoFactorVerification() {
  const [code, setCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [sixDigitCode, setSixDigitCode] = useState("");
  const navigate = useNavigate();
  const { twoFactorSetup, verifyTwoFactor, logout, clearLoginState, user } =
    useAuthStore();
  const hasFetched = useRef(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (code.length !== 6) {
      setError("Please enter a 6-digit code");
      return;
    }

    try {
      setIsVerifying(true);
      setError(null);

      await verifyTwoFactor(code, sixDigitCode);
      navigate("/");
    } catch (error: any) {
      setError(error.message || "Invalid verification code");
    } finally {
      setIsVerifying(false);
    }
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length <= 6) {
      setCode(value);
      setError(null);
    }
  };

  const handleBackToLogin = async () => {
    try {
      await logout(); // Use the store's logout function
      clearLoginState(); // Clear the login state
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
      setError("Failed to logout. Please try again.");
    }
  };

  const searchLead = async () => {
    try {
      const response = await api.get("/search-lead", user.id, {
        "X-User-Email": user.email,
      });

      await getContacts(response.data[0].id);
      return response;
    } catch (error) {
      console.error("Error searching for lead:", error);
    }
  };

  const getContacts = async (leadId: any) => {
    try {
      const response = await api.get("get-contact", user.id, {
        "X-Lead-Id": leadId,
      });

      if (!response.contacts || response.contacts.length === 0) {
        throw new Error("Failed to fetch contacts");
      }

      await sendSMS(leadId, response.contacts[0].id);
      return response.contacts;
    } catch (error: any) {
      console.error("Error fetching contacts:", error.message);
      return [];
    }
  };

  const sendSMS = async (leadId: string, contactId: string) => {
    try {
      const response = await api.post("send-sms", {
        lead_id: leadId,
        contact_id: contactId,
        to: user.phoneNumber,
      });

      if (!response.success) {
        throw new Error("Failed to send SMS");
      }

      setSixDigitCode(response.code);
      return { success: true, code: response.code };
    } catch (error) {
      console.error("Error sending SMS:", error);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      searchLead();
    }
  }, [user]);

  return (
    <div className="auth-container min-h-screen bg-gradient-to-br from-primary-50 to-white flex flex-col justify-center px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex flex-col items-center">
          <div className="rounded-full bg-white/90 p-2.5 shadow-lg">
            <Wallet className="h-10 w-10 sm:h-12 sm:w-12 text-primary-400" />
          </div>
          <h2 className="mt-4 sm:mt-8 text-center text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">
            Two-Factor Authentication
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {twoFactorSetup?.verified
              ? "Please enter the verification code from your authenticator app"
              : "Scan the QR code with your authenticator app"}
          </p>
        </div>
      </div>

      <div className="mt-4 sm:mt-8 mx-auto w-full max-w-[min(400px,calc(100%-2rem))] sm:max-w-md">
        <div className="bg-white/90 backdrop-blur-xl py-5 px-4 shadow-2xl rounded-2xl sm:py-8 sm:px-10">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
                {error}
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-900">
                Verification Code
              </label>
              <input
                type="text"
                value={code}
                onChange={handleCodeChange}
                placeholder="Enter 6-digit code"
                className="mt-1.5 sm:mt-2 block w-full rounded-xl border border-gray-200 shadow-sm focus:ring-2 focus:ring-primary-400 focus:ring-opacity-30 focus:border-primary-400 focus:outline-none text-gray-900 text-sm sm:text-base px-3 py-2.5 sm:px-4 sm:py-3.5 bg-white"
                maxLength={6}
                pattern="[0-9]*"
                inputMode="numeric"
                autoComplete="one-time-code"
              />
            </div>

            <button
              type="submit"
              disabled={isVerifying || code.length !== 6}
              className="w-full flex justify-center py-3 sm:py-4 px-4 border border-transparent rounded-xl shadow-lg text-sm sm:text-base font-semibold text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 hover:shadow-xl"
            >
              {isVerifying ? "Verifying..." : "Verify Code"}
            </button>

            <button
              type="button"
              onClick={handleBackToLogin}
              className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back to Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TwoFactorVerification;
