import { useEffect, useState } from "react";
import { Building2, DollarSign, Search, Receipt, Wrench } from "lucide-react";
import PropertyCard from "./components/PropertyCard";
import PropertyDetailsModal from "./components/PropertyDetailsModal";
import { Property } from "../../types/property";
import { properties } from "../../../server/rents";
import { useAuthStore } from "../../store/authStore";
import { api } from "../../utils/api";

function RealEstatePage({
  investments,
  liabilities,
  transactions,
}: {
  investments: any;
  liabilities: any;
  transactions: any;
}) {
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useAuthStore();

  const calculateEquity = () => {
    // 1. Calculate total assets (all accounts except loans and credit)
    const totalAssets =
      investments?.accounts?.reduce(
        (sum: number, account: any) =>
          !["loan", "credit"].includes(account.type)
            ? sum + (account?.balances?.current || 0)
            : sum,
        0
      ) || 0;

    // 2. Calculate total liabilities (all loans and credit accounts)
    const totalLiabilities =
      liabilities?.accounts
        ?.filter((account) => account.subtype.includes("mortgage"))
        .reduce(
          (sum: number, mortgage: any) =>
            sum + (mortgage?.balances?.current || 0),
          0
        ) || 0;

    const totalStudentLoans =
      liabilities?.accounts
        ?.filter((account) => account.subtype.includes("student"))
        .reduce(
          (sum: number, student: any) =>
            sum + (student?.balances?.current || 0),
          0
        ) || 0;

    const totalCreditCards =
      liabilities?.accounts
        ?.filter((account) => account.subtype.includes("credit"))
        .reduce(
          (sum: number, credit: any) => sum + (credit?.balances?.current || 0),
          0
        ) || 0;

    const totalLiabilitiesSum =
      totalLiabilities + totalStudentLoans + totalCreditCards;

    // 3. Calculate total equity
    const totalEquity = totalAssets - totalLiabilitiesSum;

    return {
      totalAssets: totalAssets.toFixed(2),
      totalLiabilities: totalLiabilitiesSum.toFixed(2),
      totalEquity: totalEquity.toFixed(2),
    };
  };

  // Example usage
  const equity = calculateEquity();

  const calculateMonthlyIncome = (): string => {
    // Get current date if no targetDate is provided
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;

    // Filter for income transactions within the target month
    const incomeTransactions = transactions?.filter((transaction: any) => {
      if (!transaction.date) return false;

      const [transYear, transMonth] = transaction.date.split("-").map(Number);

      return (
        transaction.amount > 0 && // Ensure it's income (positive amounts)
        transYear === year &&
        transMonth === month
      );
    });

    // Calculate total monthly income
    const totalIncome = incomeTransactions
      ?.reduce((sum, transaction) => sum + transaction.amount, 0)
      ?.toFixed(2);

    // Return the calculated total income as a string
    return totalIncome || "0.00";
  };

  const income = calculateMonthlyIncome();

  const totalRentalIncome = properties.reduce(
    (sum, prop) => sum + (prop.rental?.monthlyRent || 0),
    0
  );

  const totalExpenses = properties.reduce((sum, prop) => {
    const propertyExpenses = {
      propertyTax: prop.propertyTaxes?.["2023"]?.total || 0,
      maintenance: ((prop.taxAssessments?.["2023"]?.value || 0) * 0.01) / 12, // Estimated maintenance
      insurance: 0, // No insurance data available, set to 0 or estimate
      utilities: 0, // No utilities data available, set to 0
      management: 0, // No management fees available, set to 0
    };

    const totalPropertyExpenses = Object.values(propertyExpenses).reduce(
      (a, b) => a + b,
      0
    );

    return sum + totalPropertyExpenses;
  }, 0);

  // Filter accounts to include only investments (exclude loans and others)
  const investmentAccounts = investments?.accounts?.filter(
    (account: any) => account.type === "investment"
  );

  // Calculate the total portfolio value
  const portfolioValue = investmentAccounts?.reduce(
    (sum: number, account: any) => sum + (account.balances.current || 0),
    0
  );

  // Filter properties based on search query
  const filteredProperties = properties.filter((property) => {
    const searchFields = [
      property.formattedAddress,
      property.city,
      property.state,
      property.zipCode,
      property.propertyType,
    ];

    return searchFields.some((field) =>
      field?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // async function getMortgageRates() {
  //   try {
  //     if (!user.id) {
  //       console.error("Error: userId is required");
  //       return null;
  //     }

  //     const response = await api.get("/mortgageRates", user.id, {});

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch mortgage rates");
  //     }

  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.error("Error fetching mortgage rates:", error);
  //     return null;
  //   }
  // }

  // // Example usage inside a React component
  // useEffect(() => {
  //   getMortgageRates();
  // }, [user]);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search by address, city, state, or property type..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Portfolio Value</h3>
            <Building2 className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">
            ${portfolioValue?.toLocaleString() || 0}
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Equity</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          {parseFloat(equity?.totalEquity) >= 0 ? (
            <p className="text-2xl font-bold text-green-500">
              ${Math.abs(Number(equity?.totalEquity))?.toLocaleString()}
            </p>
          ) : (
            <p className="text-2xl font-bold text-red-500">
              -${Math.abs(Number(equity?.totalEquity))?.toLocaleString()}
            </p>
          )}
          <p className="text-sm text-gray-500 mt-2">Across all properties</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Annual Expenses</h3>
            <Wrench className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">
            ${totalExpenses.toLocaleString()}
          </p>
          <p className="text-sm text-gray-500 mt-2">Maintenance & fees</p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredProperties.map((property) => (
          <PropertyCard
            key={property.id}
            property={{
              id: property.id,
              address: property.formattedAddress,
              city: property.city,
              state: property.state,
              zipCode: property.zipCode,
              type: property.propertyType,
              purchaseDate: property.lastSaleDate,
              purchasePrice: property.history?.["2017-10-19"]?.price || 0,
              currentValue: property.taxAssessments?.["2023"].value || 0,
              squareFeet: property.squareFootage,
              yearBuilt: property.yearBuilt,
              garageSpaces: property.features.garageSpaces,
              bedrooms: property.bedrooms,
              bathrooms: property.bathrooms,
              lotSize: property.lotSize,
              status: property.ownerOccupied ? "owner-occupied" : "rental",
              expenses: {
                propertyTax: property.propertyTaxes?.["2023"].total || 0,
                maintenance: 0, // Add actual maintenance if available
              },
              rental: {
                isRented: !property.ownerOccupied,
                monthlyRent: 0, // No rental data in the given dataset
                leaseStart: "",
                leaseEnd: "",
              },
            }}
            onClick={() => setSelectedProperty(property)}
          />
        ))}
      </div>

      {selectedProperty && (
        <PropertyDetailsModal
          property={selectedProperty}
          onClose={() => setSelectedProperty(null)}
        />
      )}
    </div>
  );
}

export default RealEstatePage;
