import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { X, DollarSign, Percent, Calendar } from "lucide-react";
import { format } from "date-fns";
import { db } from "../../config/firebase";
import {
  doc,
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useAuthStore } from "../../store/authStore";

const loanSchema = z.object({
  type: z.enum(["personal", "student", "mortgage", "auto", "business"]),
  lender: z.string().min(2, "Lender name is required"),
  accountNumber: z.string().min(4, "Account number is required"),
  originalAmount: z.number().min(1, "Original amount is required"),
  currentBalance: z.number().min(0, "Current balance is required"),
  interestRate: z.number().min(0, "Interest rate is required"),
  apr: z.number().min(0, "APR is required"),
  originationDate: z.string(),
  termLength: z.number().min(1, "Term length is required"),
  paymentAmount: z.number().min(0, "Payment amount is required"),
  paymentFrequency: z.enum(["monthly", "bi-weekly", "weekly"]),
  nextPaymentDate: z.string(),
  additionalDetails: z.object({
    gracePeriod: z.number().optional(),
    prepaymentPenalty: z.boolean(),
    defermentEligible: z.boolean(),
    forbearanceEligible: z.boolean(),
  }),
});

type LoanFormData = z.infer<typeof loanSchema>;

interface AddLoanModalProps {
  isOpen: boolean;
  onClose: () => void;
  editingLoan?: any;
  onEdit?: (id: string, data: any) => void;
}

const calculateLoanBalances = (
  currentBalance: number,
  interestRate: number,
  termLength: number,
  paymentAmount: number
) => {
  // Convert annual rate to monthly
  const monthlyRate = interestRate / 100 / 12;

  // Calculate interest portion of next payment
  const interestPortion = currentBalance * monthlyRate;

  // Calculate principal portion of next payment
  const principalPortion = paymentAmount - interestPortion;

  // Calculate total interest over remaining term
  const totalInterest = paymentAmount * termLength - currentBalance;

  // Calculate current interest balance based on proportion of total interest remaining
  const interestBalance =
    (totalInterest * currentBalance) / (currentBalance + totalInterest);

  // Principal balance is the difference
  const principalBalance = currentBalance - interestBalance;

  return {
    principalBalance: Math.max(0, principalBalance),
    interestBalance: Math.max(0, interestBalance),
  };
};

function AddLoanModal({
  isOpen,
  onClose,
  editingLoan,
  onEdit,
}: AddLoanModalProps) {
  const { user } = useAuthStore();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<LoanFormData>({
    resolver: zodResolver(loanSchema),
    defaultValues: {
      paymentFrequency: "monthly",
      additionalDetails: {
        prepaymentPenalty: false,
        defermentEligible: false,
        forbearanceEligible: false,
      },
    },
  });

  // Set form values when editing
  useEffect(() => {
    if (editingLoan) {
      // Set each field with the existing loan data
      setValue("type", editingLoan.type);
      setValue("lender", editingLoan.lender);
      setValue("accountNumber", editingLoan.accountNumber);
      setValue("originalAmount", editingLoan.originalAmount);
      setValue("currentBalance", editingLoan.currentBalance);
      setValue("interestRate", editingLoan.interestRate);
      setValue("apr", editingLoan.apr);
      setValue(
        "originationDate",
        format(new Date(editingLoan.originationDate), "yyyy-MM-dd")
      );
      setValue("termLength", editingLoan.termLength);
      setValue("paymentAmount", editingLoan.paymentAmount);
      setValue("paymentFrequency", editingLoan.paymentFrequency);
      setValue(
        "nextPaymentDate",
        format(new Date(editingLoan.nextPaymentDate), "yyyy-MM-dd")
      );
      setValue("additionalDetails", {
        gracePeriod: editingLoan.additionalDetails?.gracePeriod,
        prepaymentPenalty:
          editingLoan.additionalDetails?.prepaymentPenalty || false,
        defermentEligible:
          editingLoan.additionalDetails?.defermentEligible || false,
        forbearanceEligible:
          editingLoan.additionalDetails?.forbearanceEligible || false,
      });
    }
  }, [editingLoan, setValue]);

  const onSubmit = async (data: LoanFormData) => {
    try {
      if (editingLoan) {
        await updateDoc(
          doc(db, "userAddedLoans", user.id, "loans", editingLoan.id),
          {
            ...data,
            updatedAt: serverTimestamp(),
          }
        );
        // Update existing loan
        await onEdit?.(editingLoan.id, {
          ...data,
          updatedAt: serverTimestamp(),
        });
      } else {
        // Calculate principal and interest balances
        const { principalBalance, interestBalance } = calculateLoanBalances(
          data.currentBalance,
          data.interestRate,
          data.termLength,
          data.paymentAmount
        );

        // Add new loan
        const loansCollectionRef = collection(
          db,
          "userAddedLoans",
          user.id,
          "loans"
        );
        await addDoc(loansCollectionRef, {
          ...data,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          userId: user.id,
          principalBalance,
          interestBalance,
          termRemaining: data.termLength,
          status: "active",
          paymentHistory: [],
        });
      }

      reset();
      onClose();
    } catch (error) {
      console.error("Failed to handle loan:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div
          className="relative w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              {editingLoan ? "Edit Loan" : "Add New Loan"}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Loan Type
                </label>
                <select
                  {...register("type")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="personal">Personal Loan</option>
                  <option value="student">Student Loan</option>
                  <option value="mortgage">Mortgage</option>
                  <option value="auto">Auto Loan</option>
                  <option value="business">Business Loan</option>
                </select>
                {errors.type && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.type.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Lender
                </label>
                <input
                  type="text"
                  {...register("lender")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
                {errors.lender && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.lender.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Account Number
                </label>
                <input
                  type="text"
                  {...register("accountNumber")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
                {errors.accountNumber && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.accountNumber.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Original Amount
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    {...register("originalAmount", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
                {errors.originalAmount && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.originalAmount.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Current Balance
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    {...register("currentBalance", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
                {errors.currentBalance && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.currentBalance.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Monthly Payment
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    {...register("paymentAmount", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
                {errors.paymentAmount && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.paymentAmount.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Interest Rate (%)
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Percent className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    {...register("interestRate", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
                {errors.interestRate && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.interestRate.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  APR (%)
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Percent className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    {...register("apr", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
                {errors.apr && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.apr.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Origination Date
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Calendar className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="date"
                    {...register("originationDate")}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
                {errors.originationDate && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.originationDate.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Next Payment Date
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Calendar className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="date"
                    {...register("nextPaymentDate")}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
                {errors.nextPaymentDate && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.nextPaymentDate.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Term Length (months)
                </label>
                <input
                  type="number"
                  {...register("termLength", { valueAsNumber: true })}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
                {errors.termLength && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.termLength.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Payment Frequency
                </label>
                <select
                  {...register("paymentFrequency")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="monthly">Monthly</option>
                  <option value="bi-weekly">Bi-weekly</option>
                  <option value="weekly">Weekly</option>
                </select>
              </div>
            </div>

            <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
              <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-4">
                Additional Details
              </h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Grace Period (days)
                  </label>
                  <input
                    type="number"
                    {...register("additionalDetails.gracePeriod", {
                      valueAsNumber: true,
                    })}
                    className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>

                <div className="flex items-center space-x-6">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      {...register("additionalDetails.prepaymentPenalty")}
                      className="h-4 w-4 text-primary-400 focus:ring-primary-400 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                      Prepayment Penalty
                    </span>
                  </label>

                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      {...register("additionalDetails.defermentEligible")}
                      className="h-4 w-4 text-primary-400 focus:ring-primary-400 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                      Deferment Eligible
                    </span>
                  </label>

                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      {...register("additionalDetails.forbearanceEligible")}
                      className="h-4 w-4 text-primary-400 focus:ring-primary-400 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                      Forbearance Eligible
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md disabled:opacity-50"
              >
                {isSubmitting
                  ? editingLoan
                    ? "Updating..."
                    : "Adding..."
                  : editingLoan
                  ? "Update Loan"
                  : "Add Loan"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddLoanModal;
