import React, { useCallback, useContext, useEffect } from "react";
import { Plus } from "lucide-react";
import { useRetirementStore } from "../../store/retirementStore";
import RetirementOverview from "../../components/retirement/RetirementOverview";
import RetirementAccountCard from "../../components/retirement/RetirementAccountCard";
import { usePlaidLink } from "react-plaid-link";
import { createDocument } from "../../utils/firebase-db";
import { api } from "../../utils/api";
import { useAuthStore } from "../../store/authStore";
import Context from "../../Context";

function RetirementAccountsPage({ investments, investmentTransactions }) {
  const { accounts, removeAccount } = useRetirementStore();

  const {
    addAccessTokenAndItemId,
    user,
    plaidInvestmentsHoldings,
    plaidInvestmentTransactions,
  } = useAuthStore();
  const { linkToken, isPaymentInitiation, isCraProductsExclusively, dispatch } =
    useContext(Context);

  enum CraCheckReportProduct {
    BaseReport = "cra_base_report",
    IncomeInsights = "cra_income_insights",
    PartnerInsights = "cra_partner_insights",
    NetworkInsights = "cra_network_insights",
  }

  const retiredAccounts = investments?.accounts?.filter(
    (account) =>
      (account.type === "investment" &&
        account.subtype.toLowerCase().includes("401k")) ||
      account.subtype.toLowerCase().includes("ira")
  );

  const getInfo = useCallback(async () => {
    if (!dispatch) {
      console.error("Dispatch is not defined");
      return;
    }

    const response = await api.post("/info", {
      access_token: user.access_token,
      item_id: user.item_id,
    });

    // if (!response.accesss_token || !response.item_id) {
    //   return { paymentInitiation: false };
    // }

    // const data = await response.json();

    const paymentInitiation: boolean =
      response.products.includes("payment_initiation");
    const craEnumValues = Object.values(CraCheckReportProduct);
    const isUserTokenFlow: boolean = response.products.some(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );
    const isCraProductsExclusively: boolean = response.products.every(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );

    dispatch({
      type: "SET_STATE",
      state: {
        products: response.products,
        isPaymentInitiation: paymentInitiation,
        isCraProductsExclusively: isCraProductsExclusively,
        isUserTokenFlow: isUserTokenFlow,
      },
    });
    return { paymentInitiation, isUserTokenFlow };
  }, [dispatch]);

  const generateUserToken = useCallback(async () => {
    const response = await api.post("/create_user_token", {});

    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { userToken: null } });

      return;
    }

    const data = await response.json();
    if (data) {
      if (data.error != null) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
            linkTokenError: data.error,
          },
        });
        return;
      }
      dispatch({ type: "SET_STATE", state: { userToken: data.user_token } });

      return data.user_token;
    }
  }, [dispatch]);

  const generateToken = useCallback(
    async (isPaymentInitiation) => {
      // Link tokens for 'payment_initiation' use a different creation flow in your backend.

      const path =
        // isPaymentInitiation
        // ? "/api/create_link_token_for_payment"
        "/create_link_token";
      const response = await api.post(path, {
        user_id: user.id,
        token_type: "investment",
      });

      // if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { linkToken: null } });

      // return;
      // }
      // const data = await response.text();

      // if (data) {
      if (!response.link_token) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
          },
        });

        return;
      }

      dispatch({
        type: "SET_STATE",
        state: { linkToken: response.link_token },
      });
    },

    [dispatch]
  );

  useEffect(() => {
    const init = async () => {
      const { paymentInitiation, isUserTokenFlow } = await getInfo(); // used to determine which path to take when generating token
      // do not generate a new token for OAuth redirect; instead

      if (isUserTokenFlow) {
        await generateUserToken();
      }

      generateToken(paymentInitiation);
    };

    init();
  }, [dispatch, generateToken, generateUserToken, getInfo]);

  const onSuccess = React.useCallback(
    (public_token: string, metadata: any) => {
      const tokenType = "investment";
      // If the access_token is needed, send public_token to server
      const exchangePublicTokenForAccessToken = async () => {
        try {
          const response = await api.post("/set_access_token", {
            public_token: public_token,
          });

          if (!response.access_token) {
            throw new Error("Failed to exchange token");
          }

          dispatch({
            type: "SET_STATE",
            state: {
              itemId: response.item_id,
              accessToken: response.access_token,
              isItemAccess: true,
            },
          });

          const investmentResults = await plaidInvestmentsHoldings(
            response.access_token
          );

          const transactionResults = await plaidInvestmentTransactions(
            response.access_token
          );

          await addAccessTokenAndItemId(response, user, tokenType);

          await createDocument(
            "investments",
            user.id,
            investmentResults.result
          );

          await createDocument(
            "investmentTransactions",
            user.id,
            transactionResults
          );
        } catch (error) {
          console.error("Token exchange failed:", error);
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: `no item_id retrieved`,
              accessToken: `no access_token retrieved`,
              isItemAccess: false,
            },
          });
        }
      };
      // 'payment_initiation' products do not require the public_token to be exchanged for an access_token.
      if (isPaymentInitiation) {
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else if (isCraProductsExclusively) {
        // When only CRA products are enabled, only user_token is needed. access_token/public_token exchange is not needed.
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else {
        exchangePublicTokenForAccessToken();
      }

      dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
      window.history.pushState("", "", "/");
    },
    [dispatch, isPaymentInitiation, isCraProductsExclusively]
  );

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  const linkRetirementAccount = async () => {
    if (!ready) {
      console.warn("Plaid Link not ready");
      return;
    }

    if (!linkToken) {
      console.error("No link token available");
      return;
    }

    open();
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Retirement Accounts
        </h1>
        <button
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500"
          onClick={() => linkRetirementAccount()}
        >
          <Plus className="h-5 w-5 mr-2" />
          Link New Account
        </button>
      </div>

      <RetirementOverview
        accounts={accounts}
        investments={investments}
        investmentTransactions={investmentTransactions}
        retiredAccounts={retiredAccounts}
      />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {retiredAccounts?.map((account) => (
          <RetirementAccountCard
            key={account.id}
            account={account}
            onDelete={removeAccount}
            investments={investments}
            investmentTransactions={investmentTransactions}
            retiredAccounts={retiredAccounts}
          />
        ))}
      </div>
    </div>
  );
}

export default RetirementAccountsPage;
