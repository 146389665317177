import React, { useEffect, useState } from "react";
import {
  TrendingUp,
  Search,
  Filter,
  Plus,
  Edit2,
  Trash2,
  Crown,
  DollarSign,
} from "lucide-react";
import AddOtherInvestmentModal from "../../components/investments/AddOtherInvestmentModal";
import { useAuthStore } from "../../store/authStore";
import { deleteDocument, updateDocument } from "../../utils/firebase-db";
import { collection, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

interface OtherInvestmentFilters {
  search: string;
  sortBy:
    | "value_desc"
    | "value_asc"
    | "gain_desc"
    | "gain_asc"
    | "gainPercent_desc"
    | "gainPercent_asc"
    | "name_asc"
    | "name_desc";
}

function OtherInvestmentsPage({ investments }) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingInvestment, setEditingInvestment] = useState<any>(null);
  const [filters, setFilters] = useState<OtherInvestmentFilters>({
    search: "",
    sortBy: "value_desc",
  });

  const { user } = useAuthStore();

  const uniqueInvestments = investments?.filter(
    (investment) => investment.userId === user.id
  );

  // Filter and sort investments
  const filteredInvestments = uniqueInvestments
    ?.filter((investment) => {
      const searchFields = [
        investment.name,
        investment.location,
        investment.category,
      ];

      return searchFields.some((field) =>
        field?.toLowerCase().includes(filters.search.toLowerCase())
      );
    })
    .sort((a, b) => {
      const [field, direction] = filters.sortBy.split("_");
      const getValue = (investment: any) => {
        switch (field) {
          case "name":
            return investment.name;
          case "value":
            return investment.currentValue;
          case "gain":
            return investment.gain;
          case "gainPercent":
            return investment.gainPercent;
          default:
            return investment.currentValue;
        }
      };

      const aValue = getValue(a);
      const bValue = getValue(b);

      if (typeof aValue === "string") {
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return direction === "asc" ? aValue - bValue : bValue - aValue;
    });

  const totalValue = filteredInvestments?.reduce(
    (sum, inv) => sum + (inv.currentValue || 0),
    0
  );
  const totalGain = filteredInvestments?.reduce(
    (sum, inv) => sum + (inv.gain || 0),
    0
  );
  const totalCost = filteredInvestments?.reduce(
    (sum, inv) => sum + (inv.purchasePrice || 0),
    0
  );

  const handleDelete = async (id: string) => {
    const otherDoc = doc(
      db,
      "userAddedInvestments",
      user.id,
      "otherInvestments",
      id
    );
    await deleteDoc(otherDoc);
  };

  const handleEdit = (investment: any) => {
    setEditingInvestment(investment);
    setIsAddModalOpen(true);
  };

  const handleUpdate = async (id: string, data: any) => {
    try {
      const otherDoc = doc(
        db,
        "userAddedInvestments",
        user.id,
        "otherInvestments",
        id
      );
      await updateDoc(otherDoc, data);
      setEditingInvestment(null);
    } catch (error) {
      console.error("Error updating investment:", error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search alternative investments..."
            value={filters.search}
            onChange={(e) =>
              setFilters((f) => ({ ...f, search: e.target.value }))
            }
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <div className="flex items-center">
            <Filter className="h-5 w-5 text-gray-400 mr-2" />
            <select
              value={filters.sortBy}
              onChange={(e) =>
                setFilters((f) => ({ ...f, sortBy: e.target.value as any }))
              }
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-400"
            >
              <option value="value_desc">Market Value (High to Low)</option>
              <option value="value_asc">Market Value (Low to High)</option>
              <option value="gain_desc">Total Gain/Loss (High to Low)</option>
              <option value="gain_asc">Total Gain/Loss (Low to High)</option>
              <option value="gainPercent_desc">
                % Gain/Loss (High to Low)
              </option>
              <option value="gainPercent_asc">% Gain/Loss (Low to High)</option>
              <option value="name_asc">Name (A to Z)</option>
              <option value="name_desc">Name (Z to A)</option>
            </select>
          </div>
          <button
            onClick={() => {
              setEditingInvestment(null);
              setIsAddModalOpen(true);
            }}
            className="flex items-center px-4 py-2 text-white bg-primary-400 hover:bg-primary-500 rounded-lg"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Investment
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Value</h3>
            <Crown className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">
            ${(totalValue || 0).toLocaleString()}
          </p>
          <div className="flex items-center mt-2 text-green-500">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+${(totalGain || 0).toLocaleString()} Total Return</span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Cost Basis</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">
            ${(totalCost || 0).toLocaleString()}
          </p>
          <p className="text-sm text-gray-500 mt-2">Initial Investment</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Return</h3>
            <TrendingUp className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold text-green-500">
            {totalCost > 0
              ? `+${(((totalValue - totalCost) / totalCost) * 100).toFixed(2)}`
              : "0.00"}
            %
          </p>
          <p className="text-sm text-gray-500 mt-2">Overall Performance</p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium">Alternative Investments</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Asset/Category
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Purchase Price
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Value
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Location
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Insurance
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Total Return
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {filteredInvestments?.map((investment) => (
                <tr
                  key={investment.id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="font-medium text-gray-900 dark:text-white">
                        {investment.name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {investment.category}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${investment.purchasePrice?.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${investment.currentValue?.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm text-gray-900 dark:text-white">
                        {investment.location}
                      </div>
                      <div className="text-xs text-gray-500">
                        Condition: {investment.condition}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm text-gray-900 dark:text-white">
                        {investment.insurance?.provider}
                      </div>
                      <div className="text-xs text-gray-500">
                        Coverage: $
                        {investment.insurance?.coverage?.toLocaleString()}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex flex-col items-end">
                      <div className="flex items-center text-green-500">
                        <TrendingUp className="h-4 w-4 mr-1" />
                        <span>${investment.gain?.toLocaleString()}</span>
                      </div>
                      <span className="text-sm text-green-500">
                        +{investment.gainPercent?.toFixed(2)}%
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex items-center justify-end space-x-2">
                      <button
                        onClick={() => handleEdit(investment)}
                        className="p-2 text-gray-400 hover:text-primary-400 transition-colors"
                      >
                        <Edit2 className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(investment.id)}
                        className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AddOtherInvestmentModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setEditingInvestment(null);
        }}
        onAdd={(investment) => {
          setIsAddModalOpen(false);
        }}
        editingInvestment={editingInvestment}
        onUpdate={handleUpdate}
      />
    </div>
  );
}

export default OtherInvestmentsPage;
