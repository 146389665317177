import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/authStore";
import { format } from "date-fns";
import { PiggyBank, Target, Activity } from "lucide-react";
import NetWorthCard from "../components/cards/NetWorthCard";

interface Liabilities {
  accounts: {
    type: string;
    subtype: string;
    balances: {
      current: number;
    };
  }[];
}

function Dashboard({
  transactions,
  userAccounts,
  liabilities,
  budgets,
  addedStocks,
  addedBonds,
  commodities,
  userAddedInvestments,
  goals,
}: {
  transactions: any[];
  userAccounts: any;
  liabilities: Liabilities;
  budgets: any[];
  addedStocks: any[];
  addedBonds: any[];
  commodities: any[];
  userAddedInvestments: any[];
  goals: any[];
}) {
  const navigate = useNavigate();
  const { user }: any = useAuthStore();

  const creditCardAcc = liabilities?.accounts?.filter((account) =>
    account.type.includes("credit")
  );

  const loans = liabilities?.accounts?.filter((account) =>
    account.type.includes("loan")
  );

  const totalBankBalance =
    userAccounts?.accounts?.reduce(
      (sum, acc) => sum + acc.balances.current,
      0
    ) +
    liabilities?.accounts?.reduce((sum, acc) => sum + acc.balances.current, 0) +
    addedStocks?.reduce((sum, stock) => sum + stock.institution_value, 0) +
    addedBonds?.reduce((sum, bond) => sum + bond.institution_value, 0) +
    commodities?.reduce((sum, comm) => sum + comm.totalValue, 0) +
    userAddedInvestments?.reduce((sum, inv) => sum + inv.currentValue, 0);

  const totalAssets = totalBankBalance;

  const totalCreditDebt = creditCardAcc?.reduce(
    (sum, card) => sum + card?.balances?.current,
    0
  );

  const totalLoanDebt = loans?.reduce(
    (sum, loan) => sum + loan.balances.current,
    0
  );
  const totalLiabilities = totalCreditDebt + totalLoanDebt;

  // Calculate net worth
  const netWorth = totalAssets - totalLiabilities || 0;
  // Get first name only
  const firstName = user?.name.split(" ")[0];

  function getAssetBreakdown() {
    const categories = {
      "Real Estate": 0,
      Investments: 0,
      Cash: 0,
      Other: 0,
    };

    liabilities?.accounts?.forEach((account) => {
      const balance = account?.balances?.current || 0;

      switch (account.subtype) {
        case "mortgage":
          categories["Real Estate"] += balance;
          break;
        case "401k":
        case "ira":
        case "cd":
        case "money market":
          categories["Investments"] += balance;
          break;
        case "checking":
        case "savings":
        case "cash management":
        case "hsa":
          categories["Cash"] += balance;
          break;
        default:
          categories["Other"] += balance;
          break;
      }
    });

    return Object.keys(categories).map((key) => ({
      name: key,
      value: categories[key],
    }));
  }

  // Extract account data from userAccounts
  const assetBreakdown = getAssetBreakdown();

  // Calculate financial health score
  const calculateHealthScore = () => {
    let score = 0;

    // Net worth factor (30 points)
    if (netWorth > 0) {
      score += 30;
    } else if (netWorth === 0) {
      score += 15;
    }

    // Budget adherence (20 points)
    const budgetAdherence =
      budgets?.reduce((acc, item) => {
        return acc + (item.spent <= item.limit ? 1 : 0);
      }, 0) / budgets?.length || 0;
    score += budgetAdherence * 20;

    // Savings goals progress (20 points)
    const savingsProgress =
      goals?.reduce((acc, goal) => {
        return acc + goal.progress / 100;
      }, 0) / goals?.length || 0;
    score += savingsProgress * 20;

    // Asset diversification (30 points)

    const realEstate = assetBreakdown.find(
      (asset) => asset.name === "Real Estate"
    );
    const investments = assetBreakdown.find(
      (asset) => asset.name === "Investments"
    );

    const totalAssets = assetBreakdown.reduce(
      (sum, asset) => sum + asset.value,
      0
    );
    if (totalAssets > 0) {
      const diversificationScore = Math.min(
        (investments?.value / totalAssets) * 15 +
          (realEstate?.value / totalAssets) * 5,
        30
      );
      score += diversificationScore;
    }

    return Math.round(score);
  };

  const healthScore = calculateHealthScore();

  // Calculate Monthly Savings
  const calculateMonthlySavings = () => {
    const currentMonth = new Date().getMonth() + 1; // Current month (1-based)
    const currentYear = new Date().getFullYear(); // Current year

    let totalIncome = 0;
    let totalExpenses = 0;

    // Filter transactions for the current month and year
    const filteredTransactions = transactions.filter((transaction) => {
      const [year, month] = transaction.date.split("-").map(Number);
      return year === currentYear && month === currentMonth;
    });

    // Categorize transactions as income or expenses
    filteredTransactions.forEach((transaction) => {
      if (transaction.amount >= 0) {
        totalIncome += Math.abs(transaction.amount); // Income is positive
      } else {
        totalExpenses += transaction.amount; // Expenses are positive amounts
      }
    });

    const monthlySavings = totalIncome - totalExpenses;

    return {
      totalIncome,
      totalExpenses,
      monthlySavings,
    };
  };

  const { totalIncome, totalExpenses, monthlySavings } =
    calculateMonthlySavings();

  const getLatestTransactions = () => {
    return transactions
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) // Sort by date (latest first)
      .slice(0, 3); // Get the top 3 transactions
  };

  const latestTransactions = getLatestTransactions();

  return (
    <div className="space-y-6">
      {/* Welcome Section */}
      <div className="card">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
              Welcome Back, {firstName}
            </h1>
            <p className="text-gray-500 dark:text-dark-secondary mt-1">
              Here's your financial overview as of{" "}
              {format(new Date(), "MMMM d, yyyy")}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <div
              className={`px-4 py-2 rounded-full ${
                healthScore >= 80
                  ? "bg-green-100 dark:bg-green-900/20 text-green-800 dark:text-green-300"
                  : healthScore >= 60
                  ? "bg-yellow-100 dark:bg-yellow-900/20 text-yellow-800 dark:text-yellow-300"
                  : "bg-red-100 dark:bg-red-900/20 text-red-800 dark:text-red-300"
              }`}
            >
              <span className="text-sm font-medium">
                Financial Health Score: {healthScore}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="card">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium dark:text-white">
              Net Worth (Excluding Real Estate)
            </h3>
            <Activity className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold dark:text-white text-green-500">
            <NetWorthCard
              userAccounts={userAccounts}
              addedStocks={addedStocks}
              liabilities={liabilities}
              addedBonds={addedBonds}
              commodities={commodities}
              userAddedInvestments={userAddedInvestments}
            />
          </p>
          {/* <div className="flex items-center mt-2 text-green-500 dark:text-green-400">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+12.5% from last month</span>
          </div> */}
        </div>

        <div className="card">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium dark:text-white">
              Monthly Savings
            </h3>
            <PiggyBank className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold dark:text-white">
            ${monthlySavings.toLocaleString()}
          </p>
          <div className="flex items-center mt-2 text-green-500 dark:text-green-400">
            <Target className="h-4 w-4 mr-1" />
            <span>
              {isNaN(monthlySavings / totalIncome)
                ? 0
                : ((monthlySavings / totalIncome) * 100).toFixed(1)}
              % of income
            </span>
          </div>
        </div>

        {/* <div className="card">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium dark:text-white">
              Investment Returns
            </h3>
            <TrendingUp className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold text-green-500 dark:text-green-400">
            +15.8%
          </p>
          <p className="text-sm text-gray-500 dark:text-dark-secondary mt-2">
            YTD Performance
          </p>
        </div> */}
      </div>

      {/* Recent Activity and Insights */}
      <div className="grid grid-cols-1 lg:grid-cols-1 gap-6">
        {/* <div className="card">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium dark:text-white">
              Recent Activities
            </h3>
            <button
              onClick={() => navigate("/reports")}
              className="text-sm text-primary-400 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300"
            >
              View All →
            </button>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-secondary rounded-lg">
              <div>
                <p className="font-medium dark:text-white">Stock Purchase</p>
                <p className="text-sm text-gray-500 dark:text-dark-secondary">
                  AAPL - 10 shares
                </p>
              </div>
              <span className="text-red-500 dark:text-red-400">-$1,750.00</span>
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-secondary rounded-lg">
              <div>
                <p className="font-medium dark:text-white">Salary Deposit</p>
                <p className="text-sm text-gray-500 dark:text-dark-secondary">
                  Direct Deposit
                </p>
              </div>
              <span className="text-green-500 dark:text-green-400">
                +$5,000.00
              </span>
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-secondary rounded-lg">
              <div>
                <p className="font-medium dark:text-white">401k Contribution</p>
                <p className="text-sm text-gray-500 dark:text-dark-secondary">
                  Retirement Savings
                </p>
              </div>
              <span className="text-green-500 dark:text-green-400">
                +$750.00
              </span>
            </div>
          </div>
        </div> */}

        <div className="card">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium dark:text-white">
              Recent Activities
            </h3>
            <button
              onClick={() => navigate("/reports")}
              className="text-sm text-primary-400 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300"
            >
              View All →
            </button>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-secondary rounded-lg">
              <div>
                <p className="font-medium dark:text-white">
                  {latestTransactions[0]?.merchant_name ||
                    latestTransactions[0]?.name}
                </p>
                <p className="text-sm text-gray-500 dark:text-dark-secondary">
                  {latestTransactions[0]?.category?.join(", ")}
                </p>
              </div>
              {latestTransactions[0]?.amount >= 0 ? (
                <span className="text-green-500 dark:text-green-400">
                  ${latestTransactions[0]?.amount.toLocaleString()}
                </span>
              ) : (
                <span className="text-red-500 dark:text-red-400">
                  -$
                  {Math.abs(
                    isNaN(latestTransactions[0]?.amount)
                      ? 0
                      : latestTransactions[0]?.amount
                  ).toLocaleString()}
                </span>
              )}
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-secondary rounded-lg">
              <div>
                <p className="font-medium dark:text-white">
                  {latestTransactions[1]?.merchant_name ||
                    latestTransactions[1]?.name}
                </p>
                <p className="text-sm text-gray-500 dark:text-dark-secondary">
                  {latestTransactions[1]?.category?.join(", ")}
                </p>
              </div>
              {latestTransactions[1]?.amount >= 0 ? (
                <span className="text-green-500 dark:text-green-400">
                  ${latestTransactions[1]?.amount.toLocaleString()}
                </span>
              ) : (
                <span className="text-red-500 dark:text-red-400">
                  -$
                  {Math.abs(
                    isNaN(latestTransactions[1]?.amount)
                      ? 0
                      : latestTransactions[1]?.amount
                  ).toLocaleString()}
                </span>
              )}
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-secondary rounded-lg">
              <div>
                <p className="font-medium dark:text-white">
                  {latestTransactions[2]?.merchant_name ||
                    latestTransactions[2]?.name}
                </p>
                <p className="text-sm text-gray-500 dark:text-dark-secondary">
                  {latestTransactions[2]?.category?.join(", ")}
                </p>
              </div>
              {latestTransactions[2]?.amount >= 0 ? (
                <span className="text-green-500 dark:text-green-400">
                  ${latestTransactions[2]?.amount.toLocaleString()}
                </span>
              ) : (
                <span className="text-red-500 dark:text-red-400">
                  -$
                  {Math.abs(
                    isNaN(latestTransactions[2]?.amount)
                      ? 0
                      : latestTransactions[2]?.amount
                  ).toLocaleString()}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* <div className="card">
          <h3 className="text-lg font-medium dark:text-white mb-4">
            Financial Insights
          </h3>
          <div className="space-y-4">
            <div className="p-4 bg-green-50 dark:bg-green-900/20 rounded-lg">
              <div className="flex items-center">
                <CheckCircle className="h-5 w-5 text-green-500 dark:text-green-400 mr-2" />
                <p className="font-medium text-green-700 dark:text-green-300">
                  On Track for Retirement
                </p>
              </div>
              <p className="mt-1 text-sm text-green-600 dark:text-green-400">
                Your retirement savings are 15% ahead of schedule
              </p>
            </div>
            <div className="p-4 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg">
              <div className="flex items-center">
                <AlertTriangle className="h-5 w-5 text-yellow-500 dark:text-yellow-400 mr-2" />
                <p className="font-medium text-yellow-700 dark:text-yellow-300">
                  Budget Alert
                </p>
              </div>
              <p className="mt-1 text-sm text-yellow-600 dark:text-yellow-400">
                Dining out expenses are 85% of monthly budget
              </p>
            </div>
            <div className="p-4 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
              <div className="flex items-center">
                <Target className="h-5 w-5 text-primary-400 mr-2" />
                <p className="font-medium text-primary-700 dark:text-primary-300">
                  Goal Progress
                </p>
              </div>
              <p className="mt-1 text-sm text-primary-600 dark:text-primary-400">
                Emergency fund is 75% complete
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
