import React, { useCallback, useContext, useEffect, useState } from "react";
import { Plus, PencilLine } from "lucide-react";
import { useLoanStore } from "../../store/loanStore";
import LoanOverview from "../../components/loans/LoanOverview";
import LoanCard from "../../components/loans/LoanCard";
import Context from "../../Context";
import { api } from "../../utils/api";
import { useAuthStore } from "../../store/authStore";
import { usePlaidLink } from "react-plaid-link";
import { createDocument } from "../../utils/firebase-db";
import AddLoanModal from "../../components/loans/AddLoanModal";
import UserLoanCard from "../../components/loans/UserLoanCard";

function LoansPage({ liabilities, userAddedLoans }) {
  const { loans, removeLoan } = useLoanStore();
  const [isManualModalOpen, setIsManualModalOpen] = useState(false);
  const { plaidLiabilities, addAccessTokenAndItemId, user } = useAuthStore();
  const { linkToken, isPaymentInitiation, isCraProductsExclusively, dispatch } =
    useContext(Context);

  enum CraCheckReportProduct {
    BaseReport = "cra_base_report",
    IncomeInsights = "cra_income_insights",
    PartnerInsights = "cra_partner_insights",
    NetworkInsights = "cra_network_insights",
  }

  const getInfo = useCallback(async () => {
    if (!dispatch) {
      console.error("Dispatch is not defined");
      return;
    }

    const response = await api.post("/info", {
      access_token: user.access_token,
      item_id: user.item_id,
    });

    const paymentInitiation: boolean =
      response.products.includes("payment_initiation");
    const craEnumValues = Object.values(CraCheckReportProduct);
    const isUserTokenFlow: boolean = response.products.some(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );
    const isCraProductsExclusively: boolean = response.products.every(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );

    dispatch({
      type: "SET_STATE",
      state: {
        products: response.products,
        isPaymentInitiation: paymentInitiation,
        isCraProductsExclusively: isCraProductsExclusively,
        isUserTokenFlow: isUserTokenFlow,
      },
    });
    return { paymentInitiation, isUserTokenFlow };
  }, [dispatch]);

  const generateUserToken = useCallback(async () => {
    const response = await api.post("/create_user_token", {});

    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { userToken: null } });
      return;
    }

    const data = await response.json();
    if (data) {
      if (data.error != null) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
            linkTokenError: data.error,
          },
        });
        return;
      }
      dispatch({ type: "SET_STATE", state: { userToken: data.user_token } });
      return data.user_token;
    }
  }, [dispatch]);

  const generateToken = useCallback(
    async (isPaymentInitiation) => {
      const path = "/create_link_token";
      const response = await api.post(path, {
        user_id: user.id,
        token_type: "loan",
      });

      dispatch({ type: "SET_STATE", state: { linkToken: null } });

      if (!response.link_token) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
          },
        });
        return;
      }

      dispatch({
        type: "SET_STATE",
        state: { linkToken: response.link_token },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const init = async () => {
      const { paymentInitiation, isUserTokenFlow } = await getInfo();
      if (isUserTokenFlow) {
        await generateUserToken();
      }
      generateToken(paymentInitiation);
    };
    init();
  }, [dispatch, generateToken, generateUserToken, getInfo]);

  const onSuccess = React.useCallback(
    (public_token: string, metadata: any) => {
      const tokenType = "Loan";
      const exchangePublicTokenForAccessToken = async () => {
        try {
          const response = await api.post("/set_access_token", {
            public_token: public_token,
          });

          if (!response.access_token) {
            throw new Error("Failed to exchange token");
          }

          dispatch({
            type: "SET_STATE",
            state: {
              itemId: response.item_id,
              accessToken: response.access_token,
              isItemAccess: true,
            },
          });

          const liabilityResults = await plaidLiabilities(
            response.access_token
          );

          await addAccessTokenAndItemId(response, user, tokenType);

          await createDocument("liabilities", user.id, liabilityResults);
        } catch (error) {
          console.error("Token exchange failed:", error);
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: `no item_id retrieved`,
              accessToken: `no access_token retrieved`,
              isItemAccess: false,
            },
          });
        }
      };
      if (isPaymentInitiation) {
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else if (isCraProductsExclusively) {
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else {
        exchangePublicTokenForAccessToken();
      }

      dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
      window.history.pushState("", "", "/");
    },
    [dispatch, isPaymentInitiation, isCraProductsExclusively]
  );

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  const linkLoanAccount = async () => {
    if (!ready) {
      console.warn("Plaid Link not ready");
      return;
    }

    if (!linkToken) {
      console.error("No link token available");
      return;
    }

    open();
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Loans
        </h1>
        <div className="flex space-x-4">
          <button
            onClick={() => linkLoanAccount()}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Loan
          </button>
          <button
            onClick={() => setIsManualModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-md shadow-sm text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            <PencilLine className="h-5 w-5 mr-2" />
            Add Manually
          </button>
        </div>
      </div>

      <LoanOverview
        loans={loans}
        liabilities={liabilities}
        userAddedLoans={userAddedLoans}
      />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {liabilities?.accounts
          ?.filter(
            (account) =>
              account.subtype.toLowerCase().includes("student") ||
              account.subtype.toLowerCase().includes("mortgage")
          )
          .map((account) => (
            <LoanCard
              key={account.id}
              loan={account}
              onDelete={removeLoan}
              liabilities={liabilities}
            />
          ))}

        {userAddedLoans.map((loan) => (
          <UserLoanCard
            key={loan.id}
            loan={loan}
            onDelete={removeLoan}
            userAddedLoans={userAddedLoans}
          />
        ))}
      </div>

      <AddLoanModal
        isOpen={isManualModalOpen}
        onClose={() => setIsManualModalOpen(false)}
      />
    </div>
  );
}

export default LoansPage;
