import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { X } from "lucide-react";
import { useBudgetStore } from "../../store/budgetStore";
import { updateDocument } from "../../utils/firebase-db";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useAuthStore } from "../../store/authStore";

const budgetItemSchema = z.object({
  category: z.string().min(2, "Category is required"),
  isCustomCategory: z.boolean().default(false),
  customCategory: z.string().optional(),
  name: z.string().min(2, "Name must be at least 2 characters"),
  limit: z.number().min(0, "Limit must be greater than 0"),
  spent: z.number().min(0, "Amount spent must be greater than 0").optional(),
});

type BudgetItemFormData = z.infer<typeof budgetItemSchema>;

interface EditBudgetItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  budgetItem: any;
  categories?: any[];
}

function EditBudgetItemModal({
  isOpen,
  onClose,
  categories,
  budgetItem,
}: EditBudgetItemModalProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<BudgetItemFormData>({
    resolver: zodResolver(budgetItemSchema),
    defaultValues: {
      category: budgetItem.category,
      name: budgetItem.name,
      limit: budgetItem.limit,
      spent: budgetItem.spent,
      isCustomCategory: !categories
        ?.map((category) => category.name)
        .includes(budgetItem.category),
      customCategory: !categories
        ?.map((category) => category.name)
        .includes(budgetItem.category)
        ? budgetItem.category
        : "",
    },
  });

  const { user } = useAuthStore();

  const isCustomCategory = watch("isCustomCategory");

  const onSubmit = async (data: BudgetItemFormData) => {
    try {
      const category = data.isCustomCategory
        ? data.customCategory!
        : data.category;

      await updateDoc(
        doc(db, "budgetCategories", user.id, "categories", budgetItem.id),
        {
          category,
          name: data.name,
          limit: data.limit,
          // Only update spent amount if it's a custom category
          ...(isCustomCategory && { spent: data.spent }),
        }
      );

      onClose();
    } catch (error) {
      console.error("Failed to update budget item:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25"
          onClick={onClose}
        />

        <div className="relative w-full max-w-md rounded-lg bg-white dark:bg-gray-800 p-6 shadow-xl">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              Edit Budget Category
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          {/* Current Status Section */}
          <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Current Status
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">Spent</p>
                <p className="text-lg font-medium">
                  ${budgetItem.spent.toLocaleString()}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Remaining</p>
                <p
                  className={`text-lg font-medium ${
                    budgetItem.limit - budgetItem.spent >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  $
                  {Math.abs(
                    budgetItem.limit - budgetItem.spent
                  ).toLocaleString()}
                  {budgetItem.limit - budgetItem.spent >= 0 ? " left" : " over"}
                </p>
              </div>
            </div>
            <div className="mt-2">
              <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
                <div
                  className={`h-2 rounded-full ${
                    (budgetItem.spent / budgetItem.limit) * 100 >= 100
                      ? "bg-red-500"
                      : (budgetItem.spent / budgetItem.limit) * 100 >= 80
                      ? "bg-yellow-500"
                      : "bg-green-500"
                  }`}
                  style={{
                    width: `${Math.min(
                      (budgetItem.spent / budgetItem.limit) * 100,
                      100
                    )}%`,
                  }}
                />
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    {...register("isCustomCategory")}
                    className="rounded border-gray-300 text-primary-400 focus:ring-primary-400"
                  />
                  <span>Use Custom Category</span>
                </div>
              </label>
            </div>

            {isCustomCategory ? (
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Custom Category Name
                </label>
                <input
                  type="text"
                  {...register("customCategory")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="Enter custom category name"
                />
                {errors.customCategory && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.customCategory.message}
                  </p>
                )}
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Category
                </label>
                <select
                  {...register("category")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="">Select a category</option>
                  {categories?.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                  <option value="other">Other</option>
                </select>
                {errors.category && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.category.message}
                  </p>
                )}
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Name
              </label>
              <input
                type="text"
                {...register("name")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="e.g., Monthly Rent or Groceries"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Monthly Limit
              </label>
              <div className="relative mt-1">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  {...register("limit", { valueAsNumber: true })}
                  className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="0.00"
                  step="0.01"
                />
              </div>
              {errors.limit && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.limit.message}
                </p>
              )}
            </div>

            {/* Show spent amount field only for custom categories */}
            {isCustomCategory && (
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Amount Spent
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    {...register("spent", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                    placeholder="0.00"
                    step="0.01"
                  />
                </div>
                {errors.spent && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.spent.message}
                  </p>
                )}
              </div>
            )}

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md disabled:opacity-50"
              >
                {isSubmitting ? "Updating..." : "Update Category"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditBudgetItemModal;
