import React, { useState } from "react";
import { Landmark, ExternalLink, Clock, X } from "lucide-react";
import { RetirementAccount } from "../../types/retirement";
import { format } from "date-fns";

interface RetirementAccountCardProps {
  account: RetirementAccount;
  onDelete: (id: string) => void;
  investments?: any;
  investmentTransactions?: any;
  retiredAccounts?: any;
}

function RetirementAccountCard({
  account,
  investments,
  investmentTransactions,
  retiredAccounts,
}: RetirementAccountCardProps) {
  const [showTransactions, setShowTransactions] = useState(false);

  const contributionLimits = {
    "401k": 23000, // 2024 limit
    ira: 7000, // 2024 limit
  };

  // Determine account type and ID
  const accountId = account?.account_id;
  const accountType = account?.subtype?.toLowerCase()?.includes("401k")
    ? "401k"
    : "ira";

  // Calculate contributions by filtering transactions for this specific account
  const accountTransactions =
    investmentTransactions?.investmentTransactions?.filter((t: any) => {
      // Match by account_id first
      if (accountId && t.account_id === accountId) return true;

      // Fallback to type matching if no direct ID match
      if (!t.account_id) return false;

      // Check if the transaction belongs to a 401k or IRA based on account name/type
      const is401k =
        t.account_id.toLowerCase().includes("401k") ||
        t.name?.toLowerCase().includes("401k") ||
        t.subtype?.toLowerCase().includes("401k");

      const isIRA =
        t.account_id.toLowerCase().includes("ira") ||
        t.name?.toLowerCase().includes("ira") ||
        t.subtype?.toLowerCase().includes("ira");

      // Return true if the account types match
      return accountType === "401k" ? is401k : isIRA;
    }) || [];

  // Group transactions by type
  const groupedTransactions = accountTransactions.reduce(
    (acc: Record<string, any[]>, transaction: any) => {
      const type = transaction.subtype || "other";
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(transaction);
      return acc;
    },
    {}
  );

  // Calculate totals
  const totals = {
    contributions: accountTransactions
      .filter((t: any) => t.subtype === "contribution")
      .reduce((sum: number, t: any) => sum + Math.abs(t.amount || 0), 0),
    dividends: accountTransactions
      .filter((t: any) => t.subtype === "dividend")
      .reduce((sum: number, t: any) => sum + Math.abs(t.amount || 0), 0),
    interest: accountTransactions
      .filter((t: any) => t.subtype === "interest")
      .reduce((sum: number, t: any) => sum + Math.abs(t.amount || 0), 0),
  };

  // Calculate contribution progress
  const contributionProgress =
    (totals.contributions / contributionLimits[accountType]) * 100;

  const formatCurrency = (amount: number | undefined | null) => {
    if (amount === undefined || amount === null) return "$0";
    return `$${amount.toLocaleString()}`;
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transition-all hover:shadow-xl">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
            <Landmark className="h-6 w-6 text-primary-400" />
          </div>
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">
              {account?.subtype?.toUpperCase().replace("_", " ") ||
                "Retirement Account"}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {account?.type || ""}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Current Balance
          </p>
          <p className="text-xl font-bold text-gray-900 dark:text-white">
            {formatCurrency(account?.balances?.current)}
          </p>
        </div>
        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            YTD Contributions
          </p>
          <p className="text-xl font-bold text-gray-900 dark:text-white">
            {formatCurrency(totals.contributions)}
          </p>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <div className="flex justify-between items-center mb-2">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Contribution Progress
            </p>
            <p className="text-sm font-medium text-primary-400">
              {formatCurrency(totals.contributions)} of{" "}
              {formatCurrency(contributionLimits[accountType])}
            </p>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
            <div
              className="bg-primary-400 h-2 rounded-full transition-all duration-300"
              style={{ width: `${Math.min(contributionProgress || 0, 100)}%` }}
            />
          </div>
        </div>

        <button
          onClick={() => setShowTransactions(true)}
          className="w-full mt-4 flex items-center justify-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
        >
          <Clock className="h-4 w-4 mr-2" />
          View Recent Activity
        </button>

        {showTransactions && (
          <div
            className="fixed inset-0 z-50 overflow-y-auto"
            onClick={() => setShowTransactions(false)}
          >
            <div className="flex min-h-screen items-center justify-center p-4">
              <div className="fixed inset-0 bg-black bg-opacity-25" />

              <div
                className="relative w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg shadow-xl"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
                  <h3 className="text-lg font-medium">
                    Recent Account Activity
                  </h3>
                  <button
                    onClick={() => setShowTransactions(false)}
                    className="text-gray-400 hover:text-gray-500 transition-colors"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>

                <div className="p-6 space-y-6">
                  {/* Summary Section */}
                  <div className="grid grid-cols-3 gap-4">
                    {[
                      {
                        label: "Total Contributions",
                        value: totals.contributions,
                      },
                      { label: "Total Dividends", value: totals.dividends },
                      { label: "Total Interest", value: totals.interest },
                    ].map(({ label, value }) => (
                      <div
                        key={label}
                        className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg"
                      >
                        <p className="text-sm text-gray-500">{label}</p>
                        <p className="text-lg font-semibold text-green-500">
                          {formatCurrency(value)}
                        </p>
                      </div>
                    ))}
                  </div>

                  {/* Transactions List */}
                  <div className="space-y-4">
                    {Object.entries(groupedTransactions).map(
                      ([type, transactions]) => (
                        <div key={type} className="space-y-2">
                          <h4 className="font-medium capitalize">{type}s</h4>
                          <div className="space-y-2">
                            {transactions.map((transaction: any) => (
                              <div
                                key={transaction.investment_transaction_id}
                                className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg"
                              >
                                <div>
                                  <p className="text-sm font-medium">
                                    {transaction.name?.replace(
                                      "INVBANKTRAN DEP ",
                                      ""
                                    ) || "Transaction"}
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    {format(
                                      new Date(transaction.date),
                                      "MMM d, yyyy"
                                    )}
                                  </p>
                                </div>
                                <div className="text-right">
                                  <p className="text-sm font-medium text-green-500">
                                    {formatCurrency(
                                      Math.abs(transaction.amount)
                                    )}
                                  </p>
                                  {transaction.quantity !== 0 && (
                                    <p className="text-xs text-gray-500">
                                      Qty: {Math.abs(transaction.quantity)}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RetirementAccountCard;
