import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { Loan } from "../../types/loan";
import { format } from "date-fns";

interface LoanOverviewProps {
  loans: Loan[];
  liabilities?: any;
  userAddedLoans?: any[];
}

function LoanOverview({
  loans,
  liabilities,
  userAddedLoans = [],
}: LoanOverviewProps) {
  // Calculate payments from different sources
  const mortgagePayments =
    liabilities?.liabilities?.mortgage?.reduce(
      (sum: number, loan: any) => sum + (loan.next_monthly_payment || 0),
      0
    ) || 0;

  const studentPayments =
    liabilities?.liabilities?.student?.reduce(
      (sum: number, loan: any) => sum + (loan.last_payment_amount || 0),
      0
    ) || 0;

  const userAddedPayments =
    userAddedLoans?.reduce(
      (sum: number, loan: any) => sum + (loan.paymentAmount || 0),
      0
    ) || 0;

  const monthlyPayments =
    mortgagePayments + studentPayments + userAddedPayments;

  // Combine all loan data for the chart
  const chartData = [
    // Add Plaid loans
    ...(liabilities?.accounts
      ?.filter(
        (account: any) =>
          account.subtype.toLowerCase().includes("student") ||
          account.subtype.toLowerCase().includes("mortgage")
      )
      .map((account: any) => ({
        name:
          account.subtype.charAt(0).toUpperCase() + account.subtype.slice(1),
        value: account.balances.current || 0,
      })) || []),
    // Add user added loans
    ...(userAddedLoans?.map((loan: any) => ({
      name: loan.type.charAt(0).toUpperCase() + loan.type.slice(1),
      value: loan.currentBalance || 0,
    })) || []),
  ];

  const COLORS = ["#B100FF", "#10B981", "#F59E0B", "#6366F1"];

  function calculatePrincipalVsInterest() {
    let totalPrincipal = 0;
    let totalInterest = 0;

    // Calculate from Plaid loans
    const plaidBalance =
      liabilities?.accounts
        ?.filter((account: any) => account.type.includes("loan"))
        .reduce(
          (sum: number, account: any) => sum + (account.balances.current || 0),
          0
        ) || 0;

    // Add principal and interest from Plaid mortgage loans
    liabilities?.liabilities?.mortgage?.forEach((loan: any) => {
      const principal =
        (loan.origination_principal_amount || 0) -
        (loan.ytd_principal_paid || 0);
      totalPrincipal += principal;
    });

    // Add principal and interest from Plaid student loans
    liabilities?.liabilities?.student?.forEach((loan: any) => {
      const principal =
        (loan.origination_principal_amount || 0) -
        (loan.ytd_principal_paid || 0);
      totalPrincipal += principal;
    });

    // Add principal and interest from user added loans
    userAddedLoans?.forEach((loan: any) => {
      totalPrincipal += loan.principalBalance || 0;
      totalInterest += loan.interestBalance || 0;
    });

    // Calculate total balance including user added loans
    const totalBalance =
      plaidBalance +
        userAddedLoans?.reduce(
          (sum: number, loan: any) => sum + (loan.currentBalance || 0),
          0
        ) || 0;

    // Avoid division by zero
    if (totalBalance === 0) {
      return {
        totalPrincipal: 0,
        totalInterest: 0,
        totalBalance: 0,
        principalPercentage: 0,
        interestPercentage: 0,
      };
    }

    const principalPercentage = (totalPrincipal / totalBalance) * 100;
    const interestPercentage = (totalInterest / totalBalance) * 100;

    return {
      totalPrincipal,
      totalInterest,
      totalBalance,
      principalPercentage,
      interestPercentage,
    };
  }

  const result = calculatePrincipalVsInterest();

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
        Loans Overview
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {chartData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value: number) => [
                  `$${value.toLocaleString()}`,
                  "Balance",
                ]}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Total Balance
              </p>
              <p className="text-xl font-bold text-gray-900 dark:text-white">
                ${result.totalBalance.toLocaleString()}
              </p>
            </div>
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Monthly Payments
              </p>
              <p className="text-xl font-bold text-gray-900 dark:text-white">
                ${monthlyPayments.toLocaleString()}
              </p>
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Principal vs Interest
              </p>
              <p className="text-sm font-medium text-gray-900 dark:text-white">
                {result.principalPercentage > 100
                  ? 100
                  : result.principalPercentage.toFixed(1)}
                % Principal
              </p>
            </div>
            <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
              <div
                className="bg-primary-400 h-2 rounded-full"
                style={{
                  width: `${
                    result.principalPercentage > 100
                      ? 100
                      : result.principalPercentage
                  }%`,
                }}
              />
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Upcoming Payments
            </p>
            <div className="mt-2 space-y-2">
              {/* Plaid Mortgage Loans */}
              {liabilities?.liabilities?.mortgage?.map((loan: any) => (
                <div
                  key={loan?.id}
                  className="flex justify-between items-center"
                >
                  <span className="text-sm text-gray-900 dark:text-white capitalize">
                    Mortgage Loan
                  </span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    ${loan?.next_monthly_payment?.toLocaleString()} on{" "}
                    {format(new Date(loan?.next_payment_due_date), "MMM d")}
                  </span>
                </div>
              ))}

              {/* Plaid Student Loans */}
              {liabilities?.liabilities?.student?.map((loan: any) => (
                <div
                  key={loan?.id}
                  className="flex justify-between items-center"
                >
                  <span className="text-sm text-gray-900 dark:text-white capitalize">
                    Student Loan
                  </span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    Minimum ${loan?.minimum_payment_amount?.toLocaleString()} on{" "}
                    {format(new Date(loan?.next_payment_due_date), "MMM d")}
                  </span>
                </div>
              ))}

              {/* User Added Loans */}
              {userAddedLoans?.map((loan: any) => (
                <div
                  key={loan.id}
                  className="flex justify-between items-center"
                >
                  <span className="text-sm text-gray-900 dark:text-white capitalize">
                    {loan.type} Loan (Manual)
                  </span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    ${loan.paymentAmount?.toLocaleString()} on{" "}
                    {format(new Date(loan.nextPaymentDate), "MMM d")}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanOverview;
