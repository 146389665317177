import { Lock } from "lucide-react";
import { useSettingsStore } from "../../store/settingsStore";
import { format } from "date-fns";
import TwoFactorSetup from "./TwoFactorSetup";
import { useAuthStore } from "../../store/authStore";

function SecuritySettings() {
  const { security } = useSettingsStore();
  const { user } = useAuthStore();

  return (
    <div className="pt-8 border-t border-gray-200 dark:border-gray-700">
      <div className="flex items-center mb-6">
        <Lock className="h-6 w-6 text-primary-400 mr-3" />
        <h2 className="text-lg font-medium text-gray-900 dark:text-white">
          Security Settings
        </h2>
      </div>

      <div className="space-y-6">
        <TwoFactorSetup />

        <div>
          <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-4">
            Recent Login Activity
          </h3>
          <div className="space-y-4">
            {security.loginHistory.map((index) => (
              <div
                key={index}
                className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <div>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {user.deviceLocation}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {user.loginLocation.city +
                      ", " +
                      user.loginLocation.country}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-gray-900 dark:text-white">
                    {format(new Date(user.loginTime), "MMM d, yyyy h:mm a")}
                  </p>
                  <span
                    className={`text-xs text-green-600 dark:text-green-400`}
                  >
                    success
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecuritySettings;
