import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { X, Building2 } from "lucide-react";

const stockSchema = z.object({
  symbol: z
    .string()
    .min(1, "Symbol is required")
    .max(5, "Symbol must be 5 characters or less")
    .toUpperCase(),
  name: z.string().min(2, "Company name is required"),
  shares: z.number().min(0.0001, "Shares must be greater than 0"),
  avgCost: z.number().min(0.01, "Average cost must be greater than 0"),
  sector: z.string().min(2, "Sector is required"),
  purchaseDate: z.string().min(1, "Purchase date is required"),
  notes: z.string().optional(),
  security_id: z.string().optional(),
});

type StockFormData = z.infer<typeof stockSchema>;

interface AddStockModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (data: StockFormData) => void;
  editingStock?: any;
  onEdit?: (id: string, data: StockFormData) => void;
}

function AddStockModal({
  isOpen,
  onClose,
  onAdd,
  editingStock,
  onEdit,
}: AddStockModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<StockFormData>({
    resolver: zodResolver(stockSchema),
  });

  // Set form values when editingStock changes
  useEffect(() => {
    if (editingStock) {
      setValue("symbol", editingStock.symbol);
      setValue("name", editingStock.name || "Unknown");
      setValue("shares", editingStock.shares || 0);
      setValue("avgCost", parseFloat(editingStock.avgCost) || 0);
      setValue("sector", editingStock.originalData?.sector || "Technology");
      setValue(
        "purchaseDate",
        editingStock.originalData?.purchaseDate ||
          new Date().toISOString().split("T")[0]
      );
      setValue("notes", editingStock.originalData?.notes || "");
      setValue("security_id", editingStock.id);
    } else {
      reset(); // Clear form when not editing
    }
  }, [editingStock, setValue, reset]);

  const onSubmit = async (data: StockFormData) => {
    try {
      if (editingStock && editingStock.id) {
        await onEdit?.(editingStock.id, data);
      } else {
        await onAdd(data);
      }
      reset();
      onClose();
    } catch (error) {
      console.error("Failed to handle stock:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div
          className="relative w-full max-w-md bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                <Building2 className="h-6 w-6 text-primary-400" />
              </div>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {editingStock ? "Edit Stock" : "Add Stock"}
              </h2>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Stock Symbol
              </label>
              <input
                type="text"
                {...register("symbol")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="e.g., AAPL"
              />
              {errors.symbol && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.symbol.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Company Name
              </label>
              <input
                type="text"
                {...register("name")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="e.g., Apple Inc."
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Number of Shares
              </label>
              <input
                type="number"
                step="0.0001"
                {...register("shares", { valueAsNumber: true })}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="0.00"
              />
              {errors.shares && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.shares.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Average Cost Per Share
              </label>
              <input
                type="number"
                step="0.01"
                {...register("avgCost", { valueAsNumber: true })}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="0.00"
              />
              {errors.avgCost && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.avgCost.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Sector
              </label>
              <select
                {...register("sector")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              >
                <option value="">Select a sector</option>
                <option value="Technology">Technology</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Finance">Finance</option>
                <option value="Consumer">Consumer</option>
                <option value="Energy">Energy</option>
                <option value="Industrial">Industrial</option>
                <option value="Materials">Materials</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Utilities">Utilities</option>
                <option value="Communication">Communication</option>
              </select>
              {errors.sector && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.sector.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Purchase Date
              </label>
              <input
                type="date"
                {...register("purchaseDate")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              />
              {errors.purchaseDate && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.purchaseDate.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Notes (Optional)
              </label>
              <textarea
                {...register("notes")}
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="Add any notes about this investment..."
              />
            </div>

            {/* Hidden field for security_id */}
            <input type="hidden" {...register("security_id")} />

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md disabled:opacity-50"
              >
                {isSubmitting
                  ? editingStock
                    ? "Updating..."
                    : "Adding..."
                  : editingStock
                  ? "Update Stock"
                  : "Add Stock"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddStockModal;
