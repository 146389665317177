import { Plus, ChevronDown } from "lucide-react";

function LinkCryptoButton({ linkCrypto }) {
  return (
    <div className="relative">
      <button
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400"
        onClick={() => linkCrypto()}
      >
        <Plus className="h-5 w-5 mr-2" />
        Link Crypto Account
        <ChevronDown className="h-4 w-4 ml-2" />
      </button>
    </div>
  );
}

export default LinkCryptoButton;
