import { create } from "zustand";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  getFirestore,
  where,
  getDocs,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { query } from "firebase/firestore";

const db = getFirestore();
const auth = getAuth();

export interface Notification {
  id: string;
  title: string;
  message: string;
  type: "info" | "success" | "warning" | "error";
  read: boolean;
  timestamp: string;
}

interface NotificationState {
  notifications: Notification[];
  unreadCount: number;
  addNotification: (
    notification: Omit<Notification, "id" | "read" | "timestamp">
  ) => Promise<void>;
  markAsRead: (id: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  removeNotification: (id: string) => Promise<void>;
}

export const useNotificationStore = create<NotificationState>((set) => ({
  notifications: [],
  unreadCount: 0,

  addNotification: async (notification) => {
    const user = auth.currentUser;
    if (!user) return;

    const notificationsRef = collection(
      db,
      "notifications",
      user.uid,
      "userNotifications"
    );

    // 🔹 Step 1: Check if notification with same title & message exists
    const q = query(
      notificationsRef,
      where("title", "==", notification.title),
      where("message", "==", notification.message)
    );

    const existingNotifications = await getDocs(q);

    if (!existingNotifications.empty) {
      return; // 🔹 Don't add duplicate notification
    }

    // 🔹 Step 2: Add notification if it doesn't exist
    const newNotification = {
      ...notification,
      read: false,
      timestamp: new Date().toISOString(),
    };

    const docRef = await addDoc(notificationsRef, newNotification);

    set((state) => ({
      notifications: [
        { ...newNotification, id: docRef.id },
        ...state.notifications,
      ],
      unreadCount: state.unreadCount + 1,
    }));
  },

  markAsRead: async (id) => {
    const user = auth.currentUser;
    if (!user) return;
    const notificationRef = doc(
      db,
      "notifications",
      user.uid,
      "userNotifications",
      id
    );
    await updateDoc(notificationRef, { read: true });
    set((state) => ({
      notifications: state.notifications.map((n) =>
        n.id === id ? { ...n, read: true } : n
      ),
      unreadCount: state.unreadCount - 1,
    }));
  },

  markAllAsRead: async () => {
    const user = auth.currentUser;
    if (!user) return;
    set((state) => {
      state.notifications.forEach(async (n) => {
        const notificationRef = doc(
          db,
          "notifications",
          user.uid,
          "userNotifications",
          n.id
        );
        await updateDoc(notificationRef, { read: true });
      });
      return {
        notifications: state.notifications.map((n) => ({ ...n, read: true })),
        unreadCount: 0,
      };
    });
  },

  removeNotification: async (id) => {
    const user = auth.currentUser;
    if (!user) return;
    const notificationRef = doc(
      db,
      "notifications",
      user.uid,
      "userNotifications",
      id
    );
    await deleteDoc(notificationRef);
    set((state) => ({
      notifications: state.notifications.filter((n) => n.id !== id),
      unreadCount:
        state.unreadCount -
        (state.notifications.find((n) => n.id === id)?.read ? 0 : 1),
    }));
  },
}));
