interface Investment {
  currentValue: number;
}

interface Account {
  type: any;
  balances: {
    current: number;
  };
}

interface Stock {
  institution_value: number;
}

interface Bond {
  institution_value: number;
}

interface Commodity {
  totalValue: number;
}

interface NetWorthCardProps {
  userAccounts?: { accounts: Account[] };
  liabilities?: { accounts: Account[] };
  addedStocks?: Stock[];
  addedBonds?: Bond[];
  commodities?: Commodity[];
  userAddedInvestments?: Investment[];
}

function NetWorthCard({
  userAccounts,
  liabilities,
  addedStocks,
  addedBonds,
  commodities,
  userAddedInvestments,
}: NetWorthCardProps) {
  const creditCardAcc = liabilities?.accounts?.filter((account) =>
    account.type.includes("credit")
  );

  const loans = liabilities?.accounts?.filter((account) =>
    account.type.includes("loan")
  );

  const totalBankBalance =
    (userAccounts?.accounts?.reduce(
      (sum, acc) => sum + acc.balances.current,
      0
    ) || 0) +
    (liabilities?.accounts?.reduce(
      (sum, acc) => sum + acc.balances.current,
      0
    ) || 0) +
    (addedStocks?.reduce((sum, stock) => sum + stock.institution_value, 0) ||
      0) +
    (addedBonds?.reduce((sum, bond) => sum + bond.institution_value, 0) || 0) +
    (commodities?.reduce((sum, comm) => sum + comm.totalValue, 0) || 0) +
    (userAddedInvestments?.reduce((sum, inv) => sum + inv.currentValue, 0) ||
      0);

  const totalAssets = totalBankBalance;

  const totalCreditDebt = creditCardAcc?.reduce(
    (sum, card) => sum + card?.balances?.current,
    0
  );

  const totalLoanDebt = loans?.reduce(
    (sum, loan) => sum + loan.balances.current,
    0
  );
  const totalLiabilities = totalCreditDebt + totalLoanDebt;

  // Calculate net worth
  const netWorth = totalAssets - totalLiabilities || 0;

  return (
    <p className="text-2xl font-bold">${(netWorth || 0).toLocaleString()}</p>
  );
}

export default NetWorthCard;
