import { LineChart, TrendingUp, TrendingDown, DollarSign } from "lucide-react";

function InvestmentsOverview({
  investments,
  investmentTransactions,
  transactions,
}) {
  const totalPortfolio =
    investments?.holdings?.reduce(
      (sum, holding) => sum + (holding.institution_value || 0),
      0
    ) || 0;

  const today = new Date().toISOString().split("T")[0];

  // Filter today's transactions
  const todaysTransactions =
    transactions?.filter((txn) => txn.date === today) || [];
  const todaysInvestmentTransactions =
    investmentTransactions?.investmentTransactions?.filter(
      (txn) => txn.date === today
    ) || [];

  // Calculate cash flow impact from transactions
  const transactionsImpact = todaysTransactions?.reduce(
    (sum, txn) => sum + (txn.amount || 0),
    0
  );

  // Calculate cash flow impact from investments
  const investmentImpact = todaysInvestmentTransactions?.reduce((sum, txn) => {
    if (
      txn.type === "buy" ||
      txn.subtype === "contribution" ||
      txn.subtype === "fee"
    ) {
      return sum - Math.abs(txn.amount || 0); // Buying stocks, fees, contributions decrease cash
    }
    if (
      txn.type === "sell" ||
      txn.subtype === "dividend" ||
      txn.subtype === "interest"
    ) {
      return sum + Math.abs(txn.amount || 0); // Selling stocks, dividends, interest increase cash
    }
    return sum;
  }, 0);

  // Compute total change today
  const todaysChange = transactionsImpact + investmentImpact;

  // Compute percentage change
  const todaysChangePercentage = (
    (todaysChange / totalPortfolio) *
    100
  )?.toFixed(2);

  const totalContributions =
    investmentTransactions?.investmentTransactions?.reduce((sum, txn) => {
      if (txn.subtype === "contribution" || txn.type === "buy") {
        return sum + Math.abs(txn.amount || 0);
      }
      return sum;
    }, 0);

  // Compute total withdrawals (money gained from investments)
  const totalWithdrawals =
    investmentTransactions?.investmentTransactions?.reduce((sum, txn) => {
      if (
        txn.type === "sell" ||
        txn.subtype === "dividend" ||
        txn.subtype === "interest"
      ) {
        return sum + Math.abs(txn.amount || 0);
      }
      return sum;
    }, 0) || 0;

  // Compute total return percentage
  const totalReturn = totalContributions
    ? (
        ((totalPortfolio - totalContributions + totalWithdrawals) /
          totalContributions) *
        100
      ).toFixed(2)
    : "0.00";

  const securityMap = investments?.securities?.reduce((map, sec) => {
    map[sec.security_id] = sec.type;
    return map;
  }, {});

  // Aggregate holdings by asset type
  const allocation = investments?.holdings?.reduce((acc, holding) => {
    const type = securityMap[holding.security_id] || "Other"; // Default to "Other" if missing
    acc[type] = (acc[type] || 0) + (holding.institution_value || 0);
    return acc;
  }, {});

  // Compute total portfolio value for percentage calculations
  const totalPortfolioValue =
    Object.values(allocation || [])?.reduce((sum, val) => sum + val, 0) || 1;

  // Convert to percentage format
  const allocationPercentages = Object.keys(allocation || [])?.map((key) => ({
    name: key,
    value: ((allocation[key] / totalPortfolioValue) * 100).toFixed(2), // Format as %
  }));

  const securityMapPerformance = new Map(
    investments?.securities?.map((security) => [
      security.security_id,
      security.name,
    ])
  );

  const recentPerformance =
    investments?.holdings?.map((holding) => {
      const { security_id, institution_value, cost_basis, quantity } = holding;

      const name = securityMapPerformance.get(security_id) || "Unknown Asset"; // Get security name
      const currentValue = institution_value || 0;
      const purchaseValue = cost_basis ? cost_basis * quantity : 0;
      const change = currentValue - purchaseValue;
      const changePercentage = purchaseValue
        ? ((change / purchaseValue) * 100).toFixed(2)
        : "0.00";

      return {
        name,
        currentValue,
        change,
        changePercentage,
      };
    }) || [];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Portfolio Value</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">
            ${totalPortfolio?.toLocaleString()}
          </p>
          {/* <div className="flex items-center mt-2 text-green-500">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+12.5% YTD</span>
          </div> */}
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Today's Change</h3>
            <LineChart className="h-5 w-5 text-primary-400" />
          </div>
          <p
            className={`text-2xl font-bold ${
              todaysChange >= 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {todaysChange >= 0 ? "+" : "-"}$
            {Math.abs(todaysChange).toLocaleString()}
          </p>
          <p className="text-sm text-gray-500 mt-2">
            {todaysChangePercentage >= 0 ? "+" : "-"}
            {Math.abs(
              isNaN(todaysChangePercentage) ? 0 : todaysChangePercentage
            )}
            %
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Return</h3>
            <TrendingUp className="h-5 w-5 text-primary-400" />
          </div>
          <p
            className={`text-2xl font-bold ${
              totalReturn >= 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {totalReturn >= 0 ? "+" : "-"}
            {Math.abs(totalReturn)}%
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-1 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-lg font-medium mb-4">Asset Allocation</h3>
          <div className="space-y-4">
            {allocationPercentages.map((asset) => (
              <div>
                <div className="flex justify-between mb-1">
                  <span>{asset.name.charAt(0).toUpperCase() + asset.name.slice(1)}</span>
                  <span>{asset.value}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div
                    className="bg-primary-400 h-2 rounded-full"
                    style={{ width: `${asset.value}%` }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* 
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-lg font-medium mb-4">Recent Performance</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <div>
                <p className="font-medium">1 Day</p>
                <p className="text-sm text-gray-500">Mar 15, 2024</p>
              </div>
              <div className="flex items-center text-green-500">
                <TrendingUp className="h-4 w-4 mr-1" />
                <span>+1.25%</span>
              </div>
            </div>
            <div className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <div>
                <p className="font-medium">1 Week</p>
                <p className="text-sm text-gray-500">Mar 8 - Mar 15</p>
              </div>
              <div className="flex items-center text-green-500">
                <TrendingUp className="h-4 w-4 mr-1" />
                <span>+2.8%</span>
              </div>
            </div>
            <div className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <div>
                <p className="font-medium">1 Month</p>
                <p className="text-sm text-gray-500">Feb 15 - Mar 15</p>
              </div>
              <div className="flex items-center text-red-500">
                <TrendingDown className="h-4 w-4 mr-1" />
                <span>-1.5%</span>
              </div>
            </div>
            <div className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <div>
                <p className="font-medium">YTD</p>
                <p className="text-sm text-gray-500">Jan 1 - Mar 15</p>
              </div>
              <div className="flex items-center text-green-500">
                <TrendingUp className="h-4 w-4 mr-1" />
                <span>+12.5%</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default InvestmentsOverview;
