import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { X, DollarSign } from "lucide-react";
import { useBudgetStore } from "../../store/budgetStore";
import {
  savingsGoalSchema,
  SavingsGoalFormData,
  SavingsGoal,
} from "../../types/budget";
import { updateDocument } from "../../utils/firebase-db";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useAuthStore } from "../../store/authStore";

interface EditSavingsGoalModalProps {
  isOpen: boolean;
  onClose: () => void;
  goal: SavingsGoal;
}

function EditSavingsGoalModal({
  isOpen,
  onClose,
  goal,
}: EditSavingsGoalModalProps) {
  const { updateSavingsGoal } = useBudgetStore();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<SavingsGoalFormData>({
    resolver: zodResolver(savingsGoalSchema),
    defaultValues: {
      name: goal.name,
      type: goal.type,
      targetAmount: goal.targetAmount,
      targetDate: goal.targetDate.split("T")[0],
      currentAmount: goal.currentAmount || 0,
      contributions: {
        amount: 0,
      },
    },
  });

  const { user } = useAuthStore();

  const onSubmit = async (data: SavingsGoalFormData) => {
    try {
      // Calculate new current amount by adding the manual contribution
      const newCurrentAmount =
        (goal.currentAmount || 0) + (data.contributions?.amount || 0);

      // Calculate new progress percentage
      const newProgress = (newCurrentAmount / data.targetAmount) * 100;

      await updateDoc(doc(db, "savingGoals", user.id, "goals", goal.id), {
        name: data.name,
        type: data.type,
        targetAmount: data.targetAmount,
        targetDate: data.targetDate,
        currentAmount: newCurrentAmount,
        progress: newProgress,
      });

      // Then update the local state
      await updateSavingsGoal(goal.id, {
        ...data,
        currentAmount: newCurrentAmount,
        progress: newProgress,
      });

      // Reset form and close modal
      reset();
      onClose();
    } catch (error) {
      console.error("Failed to update savings goal:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div
          className="relative w-full max-w-md rounded-lg bg-white dark:bg-gray-800 p-6 shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              Edit Savings Goal
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Goal Name
              </label>
              <input
                type="text"
                {...register("name")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Goal Type
              </label>
              <select
                {...register("type")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              >
                <option value="emergency_fund">Emergency Fund</option>
                <option value="large_purchase">Large Purchase</option>
                <option value="debt_payoff">Debt Payoff</option>
                <option value="vacation">Vacation</option>
                <option value="education">Education</option>
                <option value="wedding">Wedding</option>
                <option value="home">Home</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Target Amount
              </label>
              <div className="relative mt-1">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <DollarSign className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  {...register("targetAmount", { valueAsNumber: true })}
                  className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="0.00"
                  step="0.01"
                />
              </div>
              {errors.targetAmount && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.targetAmount.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Target Date
              </label>
              <input
                type="date"
                {...register("targetDate")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              />
              {errors.targetDate && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.targetDate.message}
                </p>
              )}
            </div>

            <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Current Progress
                </label>
                <div className="mt-2 flex items-center justify-between">
                  <span className="text-sm text-gray-500">
                    ${goal.currentAmount?.toLocaleString() || "0"} saved
                  </span>
                  <span className="text-sm text-gray-500">
                    {goal.progress?.toFixed(1) || "0"}% complete
                  </span>
                </div>
                <div className="mt-2 w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-primary-400 h-2 rounded-full"
                    style={{ width: `${Math.min(goal.progress || 0, 100)}%` }}
                  />
                </div>
              </div>

              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Add Manual Contribution
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    {...register("contributions.amount", {
                      valueAsNumber: true,
                    })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                    placeholder="Enter contribution amount"
                    step="0.01"
                  />
                </div>
                {errors.contributions?.amount && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.contributions.amount.message}
                  </p>
                )}
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md disabled:opacity-50"
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditSavingsGoalModal;
