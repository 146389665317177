import React, { useState } from "react";
import { Calculator, DollarSign, Calendar, Percent } from "lucide-react";

function LoanCalculator() {
  const [loanAmount, setLoanAmount] = useState<string>("");
  const [interestRate, setInterestRate] = useState<string>("");
  const [loanTerm, setLoanTerm] = useState<string>("30");
  const [paymentFrequency, setPaymentFrequency] = useState<
    "monthly" | "biweekly"
  >("monthly");

  const calculateLoan = () => {
    const amount = parseFloat(loanAmount);
    const rate = parseFloat(interestRate);
    const term = parseFloat(loanTerm);

    if (!amount || !rate || !term) return null;

    const monthlyRate = rate / 100 / 12;
    const numberOfPayments = term * (paymentFrequency === "monthly" ? 12 : 26);
    const paymentInterval = paymentFrequency === "monthly" ? 1 : 12 / 26;

    const payment =
      ((amount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) /
        (Math.pow(1 + monthlyRate, numberOfPayments) - 1)) *
      paymentInterval;

    const totalInterest = payment * numberOfPayments - amount;
    const totalPayment = payment * numberOfPayments;

    // Calculate amortization schedule
    let balance = amount;
    let totalPrincipalPaid = 0;
    let totalInterestPaid = 0;
    const schedule = [];

    for (let i = 1; i <= Math.min(numberOfPayments, 360); i++) {
      const interestPayment = balance * monthlyRate;
      const principalPayment = payment - interestPayment;
      balance -= principalPayment;
      totalPrincipalPaid += principalPayment;
      totalInterestPaid += interestPayment;

      if (i <= 12 || i === numberOfPayments) {
        // Show first year and last payment
        schedule.push({
          payment: i,
          principal: principalPayment,
          interest: interestPayment,
          totalPrincipal: totalPrincipalPaid,
          totalInterest: totalInterestPaid,
          remainingBalance: Math.max(0, balance),
        });
      }
    }

    return {
      payment,
      totalInterest,
      totalPayment,
      schedule,
    };
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handleNumberInput = (
    value: string,
    setter: (value: string) => void,
    isPercentage: boolean = false
  ) => {
    const regex = isPercentage ? /^\d*\.?\d*$/ : /^[0-9]*\.?[0-9]*$/;
    if (value === "" || regex.test(value)) {
      setter(value);
    }
  };

  const results = loanAmount && interestRate ? calculateLoan() : null;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Loan Amount
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={loanAmount}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setLoanAmount)
                }
                onBlur={() => {
                  if (loanAmount) {
                    setLoanAmount(parseFloat(loanAmount).toFixed(2));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter loan amount"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Interest Rate (%)
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Percent className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={interestRate}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setInterestRate, true)
                }
                onBlur={() => {
                  if (interestRate) {
                    setInterestRate(parseFloat(interestRate).toFixed(2));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter interest rate"
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Current average: 30yr fixed ~7.1%, 15yr fixed ~6.4%
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Loan Term (Years)
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-5 w-5 text-gray-400" />
              </div>
              <select
                value={loanTerm}
                onChange={(e) => setLoanTerm(e.target.value)}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white appearance-none"
              >
                <option value="30">30 Years</option>
                <option value="20">20 Years</option>
                <option value="15">15 Years</option>
                <option value="10">10 Years</option>
                <option value="5">5 Years</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Payment Frequency
            </label>
            <select
              value={paymentFrequency}
              onChange={(e) =>
                setPaymentFrequency(e.target.value as "monthly" | "biweekly")
              }
              className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
            >
              <option value="monthly">Monthly</option>
              <option value="biweekly">Bi-weekly</option>
            </select>
            {paymentFrequency === "biweekly" && (
              <p className="mt-1 text-sm text-gray-500">
                Making bi-weekly payments can help reduce the total interest
                paid
              </p>
            )}
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
          <h3 className="text-lg font-medium mb-6">Loan Summary</h3>

          {results ? (
            <div className="space-y-6">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  {paymentFrequency === "monthly" ? "Monthly" : "Bi-weekly"}{" "}
                  Payment
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {formatCurrency(results.payment)}
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Total Interest
                </p>
                <p className="text-2xl font-bold text-red-500">
                  {formatCurrency(results.totalInterest)}
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Total Payment
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {formatCurrency(results.totalPayment)}
                </p>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Payment Breakdown
                </p>
                <div className="mt-2 w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2.5">
                  <div
                    className="bg-primary-400 h-2.5 rounded-full"
                    style={{
                      width: `${
                        (parseFloat(loanAmount) / results.totalPayment) * 100
                      }%`,
                    }}
                  />
                </div>
                <div className="flex justify-between mt-2 text-xs text-gray-500 dark:text-gray-400">
                  <span>
                    Principal:{" "}
                    {(
                      (parseFloat(loanAmount) / results.totalPayment) *
                      100
                    ).toFixed(1)}
                    %
                  </span>
                  <span>
                    Interest:{" "}
                    {(
                      (results.totalInterest / results.totalPayment) *
                      100
                    ).toFixed(1)}
                    %
                  </span>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Amortization Schedule
                </h4>
                <div className="space-y-3">
                  {results.schedule.map((entry) => (
                    <div
                      key={entry.payment}
                      className="grid grid-cols-3 gap-2 text-sm p-2 bg-white dark:bg-gray-800 rounded-lg"
                    >
                      <div>
                        <p className="text-gray-500">Payment {entry.payment}</p>
                        <p className="font-medium">
                          {formatCurrency(entry.principal + entry.interest)}
                        </p>
                      </div>
                      <div>
                        <p className="text-gray-500">Principal</p>
                        <p className="font-medium text-green-500">
                          {formatCurrency(entry.principal)}
                        </p>
                      </div>
                      <div>
                        <p className="text-gray-500">Balance</p>
                        <p className="font-medium">
                          {formatCurrency(entry.remainingBalance)}
                        </p>
                      </div>
                    </div>
                  ))}
                  {results.schedule.length > 12 && (
                    <div className="text-center text-sm text-gray-500">
                      Showing first year and final payment
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <Calculator className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500 dark:text-gray-400">
                Enter loan details to see the calculation results
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoanCalculator;
