import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthStore } from "../../store/authStore";
import { twoFactorVerifySchema, TwoFactorVerifyData } from "../../types/auth";
import { api } from "../../utils/api";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

function TwoFactorSetup() {
  const [isSettingUp, setIsSettingUp] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const { verifyTwoFactor, cancelTwoFactor, user, twoFactorSetup } =
    useAuthStore();
  const [sixDigitCode, setSixDigitCode] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TwoFactorVerifyData>({
    resolver: zodResolver(twoFactorVerifySchema),
  });

  const handleSetup = async () => {
    try {
      setIsSettingUp(true);
      await searchLead();
      setShowVerification(true); // Show verification input after sending SMS
    } catch (error: any) {
      console.error(error.message);
    } finally {
      setIsSettingUp(false);
    }
  };

  const onVerify = async (data: TwoFactorVerifyData) => {
    try {
      await verifyTwoFactor(data.code, sixDigitCode);

      // Update Firestore
      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, {
        twoFactorSetup: {
          enabled: true,
          verified: true,
        },
      });

      reset();
      setShowVerification(false);
    } catch (error) {
      console.error("Failed to verify 2FA:", error);
    }
  };

  const handleDisable = async () => {
    try {
      await cancelTwoFactor(user.id);

      // Update Firestore
      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, {
        twoFactorSetup: {
          enabled: false,
          verified: false,
        },
      });

      setShowVerification(false);
    } catch (error) {
      console.error("Failed to disable 2FA:", error);
    }
  };

  const searchLead = async () => {
    try {
      const response = await api.get("/search-lead", user.id, {
        "X-User-Email": user.email,
      });

      await getContacts(response.data[0].id);
      return response;
    } catch (error) {
      console.error("Error searching for lead:", error);
    }
  };

  const getContacts = async (leadId: any) => {
    try {
      const response = await api.get("get-contact", user.id, {
        "X-Lead-Id": leadId,
      });

      if (!response.contacts || response.contacts.length === 0) {
        throw new Error("Failed to fetch contacts");
      }

      await sendSMS(leadId, response.contacts[0].id);
      return response.contacts;
    } catch (error: any) {
      console.error("Error fetching contacts:", error.message);
      return [];
    }
  };

  const sendSMS = async (leadId: string, contactId: string) => {
    try {
      const response = await api.post("send-sms", {
        lead_id: leadId,
        contact_id: contactId,
        to: user.phoneNumber,
      });

      if (response?.success) {
        setSixDigitCode(response.code);

        // Update Firestore when starting 2FA setup
        const userRef = doc(db, "users", user.id);
        await updateDoc(userRef, {
          twoFactorSetup: {
            enabled: true,
            verified: false,
          },
        });

        return { success: true, code: response.code };
      } else {
        throw new Error("Failed to send SMS");
      }
    } catch (error) {
      console.error("Error sending SMS:", error);
      return { success: false, error: error.message };
    }
  };

  // If 2FA is not set up at all
  if (
    !user.twoFactorSetup?.enabled &&
    !user.twoFactorSetup?.verified &&
    !showVerification
  ) {
    return (
      <div>
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-sm font-medium text-gray-900 dark:text-white">
              Two-Factor Authentication
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Add an extra layer of security to your account
            </p>
          </div>
          <button
            onClick={handleSetup}
            disabled={isSettingUp}
            className="px-4 py-2 bg-primary-400 text-white rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2 disabled:opacity-50"
          >
            {isSettingUp ? "Setting up..." : "Enable 2FA"}
          </button>
        </div>
        <div id="recaptcha-container"></div>
      </div>
    );
  }

  // If showing verification input or 2FA is enabled but not verified
  if (
    showVerification ||
    (twoFactorSetup?.enabled && !twoFactorSetup?.verified)
  ) {
    return (
      <div className="space-y-6">
        <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
          Verify Two-Factor Authentication
        </h3>

        <form onSubmit={handleSubmit(onVerify)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Verification Code
            </label>
            <input
              type="text"
              {...register("code")}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-400 focus:ring-opacity-50"
              placeholder="Enter 6-digit code"
            />
            {errors.code && (
              <p className="mt-1 text-sm text-red-600">{errors.code.message}</p>
            )}
          </div>
          <div className="flex space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-primary-400 text-white rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
            >
              Verify
            </button>
            <button
              type="button"
              onClick={handleDisable}
              className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  }

  // If 2FA is enabled and verified
  return (
    <div className="flex items-center justify-between">
      <div>
        <h3 className="text-sm font-medium text-gray-900 dark:text-white">
          Two-Factor Authentication
        </h3>
        <p className="text-sm text-green-600 dark:text-green-400">
          ✅ 2FA is enabled and verified
        </p>
      </div>
      <button
        onClick={handleDisable}
        className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
      >
        Disable 2FA
      </button>
    </div>
  );
}

export default TwoFactorSetup;
