import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import "./i18n";
import { QuickstartProvider } from "./Context";

// Initialize app
const initializeApp = () => {
  // Ensure root element exists
  let rootElement = document.getElementById("root");
  if (!rootElement) {
    rootElement = document.createElement("div");
    rootElement.id = "root";
    document.body.appendChild(rootElement);
  }

  // Create root and render app
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <BrowserRouter>
        <QuickstartProvider>
          <App />
        </QuickstartProvider>
      </BrowserRouter>
    </StrictMode>
  );
};

// Initialize app when DOM is ready
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", initializeApp);
} else {
  initializeApp();
}
