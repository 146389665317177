import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { CreditCard } from "../../types/credit";
import { format } from "date-fns";

interface CreditCardOverviewProps {
  cards: CreditCard[];
}

function CreditCardOverview({ cards, liabilities }: CreditCardOverviewProps) {
  const creditCards = liabilities?.liabilities?.credit || [];

  const creditCardAccounts = liabilities?.accounts?.filter((account) =>
    account.type.includes("credit")
  );

  const totalCredit =
    creditCardAccounts?.reduce((sum, card) => sum + card.balances.limit, 0) ||
    0;

  const totalBalance =
    creditCardAccounts?.reduce((sum, card) => sum + card.balances.current, 0) ||
    0;

  const totalAvailable = totalCredit - totalBalance;
  const utilization = (totalBalance / totalCredit) * 100;

  const chartData = [
    { name: "Used Credit", value: totalBalance },
    { name: "Available Credit", value: totalAvailable },
  ];

  const COLORS = ["#EF4444", "#10B981"];

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
        Credit Overview
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value: number) => [
                  `$${value.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`,
                  "Amount",
                ]}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Total Credit Limit
              </p>
              <p className="text-xl font-bold text-gray-900 dark:text-white">
                ${totalCredit.toLocaleString()}
              </p>
            </div>
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Total Balance
              </p>
              <p className="text-xl font-bold text-gray-900 dark:text-white">
                ${totalBalance.toLocaleString()}
              </p>
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Credit Utilization
              </p>
              <p
                className={`text-sm font-medium ${
                  utilization > 35 ? "text-red-500" : "text-green-500"
                }`}
              >
                {isNaN(utilization) ? 0 : utilization.toFixed(1)}%
              </p>
            </div>
            <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
              <div
                className={`h-2 rounded-full ${
                  utilization > 30 ? "bg-red-500" : "bg-green-500"
                }`}
                style={{ width: `${utilization || 0}%` }}
              />
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Next Payment Due
            </p>
            <div className="mt-1">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-900 dark:text-white">
                  {/* {card.cardName} */}
                </span>
                <span className="text-sm font-medium text-gray-900 dark:text-white">
                  $
                  {creditCards[0]?.minimum_payment_amount?.toLocaleString() ||
                    0}{" "}
                  on{" "}
                  {format(
                    new Date(
                      creditCards[0]?.next_payment_due_date || Date.now()
                    ),
                    "MMM d"
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditCardOverview;
