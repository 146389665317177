import { format } from "date-fns";
import { Building2, Calendar, Clock, Edit2, Trash2 } from "lucide-react";
import { Loan } from "../../types/loan";
import { useState } from "react";
import AddLoanModal from "./AddLoanModal";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useAuthStore } from "../../store/authStore";

interface LoanCardProps {
  loan: Loan;
  onDelete: (id: string) => void;
  onEdit?: (id: string, data: any) => void;
}

function UserLoanCard({ loan, onDelete, onEdit }: LoanCardProps) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { user } = useAuthStore();

  const deleteLoanCard = async (loanId) => {
    try {
      const ref = doc(db, "userAddedLoans", user.id, "loans", loanId);
      await deleteDoc(ref);
      await onDelete(loan.id);
    } catch (error) {
      console.error("Error deleting loan card", error);
    }
  };

  const calculateLoanProgress = (
    originalAmount: number,
    currentBalance: number
  ): number => {
    if (originalAmount <= 0) return 0;
    return (1 - currentBalance / originalAmount) * 100;
  };

  const loanProgress = calculateLoanProgress(
    loan.originalAmount,
    loan.currentBalance
  );

  return (
    <>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transition-all hover:shadow-xl">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
              <Building2 className="h-6 w-6 text-primary-400" />
            </div>
            <div>
              <h3 className="font-semibold text-gray-900 dark:text-white capitalize">
                {loan.type} Loan
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {loan.lender}
              </p>
            </div>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setIsEditModalOpen(true)}
              className="p-2 text-gray-400 hover:text-primary-400 transition-colors"
            >
              <Edit2 className="h-5 w-5" />
            </button>
            <button
              onClick={() => deleteLoanCard(loan.id)}
              className="p-2 text-gray-400 hover:text-red-500 transition-colors"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Current Balance
            </p>
            <p className="text-xl font-bold text-gray-900 dark:text-white">
              ${loan.currentBalance.toLocaleString()}
            </p>
          </div>
          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Monthly Payment
            </p>
            <p className="text-xl font-bold text-gray-900 dark:text-white">
              ${loan.paymentAmount.toLocaleString()}
            </p>
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Loan Progress
              </p>
              <p className="text-sm font-medium text-primary-400">
                {loanProgress.toFixed(2)}%
              </p>
            </div>
            <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
              <div
                className="bg-primary-400 h-2 rounded-full"
                style={{
                  width: `${loanProgress}%`,
                }}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <p className="text-gray-500 dark:text-gray-400">
                Principal Balance
              </p>
              <p className="font-medium text-gray-900 dark:text-white">
                ${loan.principalBalance.toLocaleString()}
              </p>
            </div>
            <div>
              <p className="text-gray-500 dark:text-gray-400">
                Interest Balance
              </p>
              <p className="font-medium text-gray-900 dark:text-white">
                ${loan.interestBalance.toLocaleString()}
              </p>
            </div>
            <div>
              <p className="text-gray-500 dark:text-gray-400">Interest Rate</p>
              <p className="font-medium text-gray-900 dark:text-white">
                {loan.interestRate}%
              </p>
            </div>
            <div>
              <p className="text-gray-500 dark:text-gray-400">APR</p>
              <p className="font-medium text-gray-900 dark:text-white">
                {loan.apr}%
              </p>
            </div>
          </div>

          <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Next Payment
                </p>
                <div className="flex items-center mt-1">
                  <Calendar className="h-4 w-4 text-primary-400 mr-2" />
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {format(new Date(loan.nextPaymentDate), "MMM d, yyyy")}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Term Remaining
                </p>
                <div className="flex items-center mt-1">
                  <Clock className="h-4 w-4 text-primary-400 mr-2" />
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {loan.termRemaining} months
                  </p>
                </div>
              </div>
            </div>
          </div>

          {loan.paymentHistory?.length > 0 && (
            <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
              <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
                Last Payment
              </h4>
              <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-3">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      ${loan.paymentHistory[0].amount.toLocaleString()}
                    </p>
                    <p className="text-xs text-gray-500">
                      {format(
                        new Date(loan.paymentHistory[0].date),
                        "MMM d, yyyy"
                      )}
                    </p>
                  </div>
                  <span className="px-2 py-1 text-xs font-medium text-green-800 bg-green-100 rounded-full">
                    {loan.paymentHistory[0].status}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isEditModalOpen && (
        <AddLoanModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          editingLoan={loan}
          onEdit={onEdit}
        />
      )}
    </>
  );
}

export default UserLoanCard;
