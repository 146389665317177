import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { BankAccount } from "../../types/bank";

interface AccountsOverviewProps {
  accounts: BankAccount[];
}

function AccountsOverview({ userAccounts }: AccountsOverviewProps) {
  // Calculate total balance
  const totalBalance = userAccounts?.accounts?.reduce(
    (sum, account) => sum + (account?.balances?.current || 0),
    0
  );

  // Prepare chart data
  const chartData = userAccounts?.accounts
    ?.filter((account) => account?.balances?.current > 0) // Include only accounts with a balance
    .map((account) => ({
      name: account.official_name?.replace("Plaid ", "") || "Unnamed Account",
      value: account?.balances?.current || 0,
    }));

  // Deduplicate names for legend
  const uniqueLegendData = chartData?.reduce((acc, curr) => {
    if (!acc.find((item) => item.name === curr.name)) {
      acc.push(curr);
    }
    return acc;
  }, [] as typeof chartData);

  const COLORS = [
    "#B100FF",
    "#10B981",
    "#F59E0B",
    "#6366F1",
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FFC300",
    "#E91E63", // New colors maintaining the scheme
    "#4CAF50",
    "#FFC107",
    "#3F51B5",
    "#FF5722",
    "#8BC34A",
    "#2196F3",
    "#FFEB3B",
    "#9C27B0", // Additional shades in similar style
    "#03A9F4",
    "#FF9800",
    "#00BCD4",
    "#795548",
    "#607D8B",
    "#CDDC39",
    "#673AB7",
  ]; // Extended color palette

  // Custom Legend Renderer
  const renderCustomLegend = () => (
    <div className="flex flex-col max-h-64 overflow-y-auto space-y-2">
      {uniqueLegendData?.map((entry, index) => (
        <div key={`legend-${index}`} className="flex items-center">
          <div
            className="w-3 h-3 rounded-full mr-2"
            style={{
              backgroundColor: COLORS[index % COLORS.length],
            }}
          />
          <span className="text-sm text-gray-900 dark:text-white">
            {entry.name}
          </span>
        </div>
      ))}
    </div>
  );

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
        Accounts Overview
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Pie Chart Section */}
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {chartData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value: number) => [
                  `$${value.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`,
                  "Balance",
                ]}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Custom Legend Section */}
        <div className="space-y-4">
          {/* Total Balance */}
          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Total Balance
            </p>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">
              $
              {totalBalance?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0}
            </p>
          </div>

          {/* Scrollable Custom Legend */}
          <div
            className="space-y-2 overflow-y-auto max-h-64"
            style={{
              scrollbarWidth: "thin", // Minimal scrollbar
              scrollbarColor: "#6366F1 #F3F4F6", // Custom colors
            }}
          >
            {renderCustomLegend()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountsOverview;
