import { useBankStore } from "../../store/bankStore";
import AccountsOverview from "../../components/bank/AccountsOverview";
import BankAccountsSection from "../../components/bank/BankAccountsSection";

function BankAccountsPage({ userAccounts }) {
  const { accounts } = useBankStore();

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
        Bank Accounts
      </h1>

      {/* Overview section */}
      <AccountsOverview accounts={accounts} userAccounts={userAccounts} />

      {/* Accounts list section */}
      <BankAccountsSection />
    </div>
  );
}

export default BankAccountsPage;
