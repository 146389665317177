import { useEffect, useState } from "react";
import BudgetOverview from "../components/budget/BudgetOverview";
import BudgetCategoryList from "../components/budget/BudgetCategoryList";
import SavingsGoalsList from "../components/budget/SavingsGoalsList";
import SpendingInsights from "../components/budget/SpendingInsights";
import AddBudgetItemModal from "../components/budget/AddBudgetItemModal";
import AddSavingsGoalModal from "../components/budget/AddSavingsGoalModal";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAuthStore } from "../store/authStore";

function BudgetPage({ transactions, budgets, goals }) {
  const [isAddBudgetModalOpen, setIsAddBudgetModalOpen] = useState(false);
  const [isAddGoalModalOpen, setIsAddGoalModalOpen] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const { user } = useAuthStore();

  useEffect(() => {
    // Subscribe to Firestore updates
    const unsubscribe = onSnapshot(
      collection(db, "categories"),
      (snapshot) => {
        setCategories(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      },
      (error) => {
        console.error("Error fetching documents:", error.message);
      }
    );

    // Cleanup function to unsubscribe when component unmounts
    return () => unsubscribe();
  }, [user]);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Budget & Savings
        </h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          <BudgetOverview
            setIsAddBudgetModalOpen={setIsAddBudgetModalOpen}
            budgets={budgets}
            categories={categories}
          />
          <BudgetCategoryList budgets={budgets} />
          <SpendingInsights budgets={budgets} transactions={transactions} />
        </div>
        <div>
          <SavingsGoalsList goals={goals} />
        </div>
      </div>

      <AddBudgetItemModal
        isOpen={isAddBudgetModalOpen}
        onClose={() => setIsAddBudgetModalOpen(false)}
        transactions={transactions}
        categories={categories}
      />

      <AddSavingsGoalModal
        isOpen={isAddGoalModalOpen}
        onClose={() => setIsAddGoalModalOpen(false)}
      />
    </div>
  );
}

export default BudgetPage;
