// src/components/settings/PreferenceSettings.tsx
import { Globe } from "lucide-react";
import { useSettingsStore } from "../../store/settingsStore";

function PreferenceSettings() {
  const { preferences, updatePreferences } = useSettingsStore();

  const handleChange = (field: keyof typeof preferences, value: string) => {
    updatePreferences({
      [field]: value,
    } as any);
  };

  return (
    <div className="pt-8 border-t border-gray-200 dark:border-gray-700">
      <div className="flex items-center mb-6">
        <Globe className="h-6 w-6 text-primary-400 mr-3" />
        <h2 className="text-lg font-medium text-gray-900 dark:text-white">
          Preferences
        </h2>
      </div>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Language
          </label>
          <select
            value={preferences.language}
            onChange={(e) => handleChange("language", e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-400 focus:ring-opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Currency
          </label>
          <select
            value={preferences.currency}
            onChange={(e) => handleChange("currency", e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-400 focus:ring-opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="USD">US Dollar</option>
            <option value="EUR">Euro</option>
            <option value="GBP">British Pound</option>
          </select>
        </div>

        {/* <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Time Zone
          </label>
          <select
            value={preferences.timezone}
            onChange={(e) => handleChange("timezone", e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-400 focus:ring-opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            {Intl.supportedValuesOf("timeZone").map((timezone) => (
              <option key={timezone} value={timezone}>
                {timezone}
              </option>
            ))}
          </select>
        </div> */}
      </div>
    </div>
  );
}

export default PreferenceSettings;
