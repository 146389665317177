import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const generatePDF = async (elementId: string, fileName: string) => {
  try {
    const element = document.getElementById(elementId);
    if (!element) throw new Error("Element not found");

    // Create a clone of the element to modify for PDF
    const clone = element.cloneNode(true) as HTMLElement;

    // Create a temporary container with proper styling
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.left = "-9999px";
    container.style.top = "-9999px";
    container.style.width = "800px"; // Fixed width for better control
    container.style.margin = "0 auto"; // Center content
    container.style.background = "white";
    container.style.padding = "40px"; // Equal padding all around
    container.appendChild(clone);
    document.body.appendChild(container);

    // Apply necessary styles to maintain visual appearance
    const style = document.createElement("style");
    style.textContent = `
      /* Base styles */
      * {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        print-color-adjust: exact !important;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
      }

      /* Page title */
      h1.text-2xl {
        font-size: 1.25rem !important;
        line-height: 1.75rem !important;
        font-weight: 700 !important;
        color: #111827 !important;
        margin-bottom: 1.25rem !important;
        padding-bottom: 0.75rem !important;
        border-bottom: 2px solid #e5e7eb !important;
        text-align: center !important;
      }

      /* Card styles */
      .bg-white, .dark\\:bg-gray-800 {
        background-color: white !important;
        border: 1px solid #e5e7eb !important;
        border-radius: 0.5rem !important;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
        margin-bottom: 1rem !important;
        padding: 1rem !important;
        position: relative !important;
        overflow: hidden !important;
      }

      /* Card header */
      .bg-white h3, .dark\\:bg-gray-800 h3 {
        font-size: 0.875rem !important;
        font-weight: 600 !important;
        color: #111827 !important;
        margin-bottom: 0.75rem !important;
      }

      /* Grid layout */
      .grid {
        display: grid !important;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
        gap: 1rem !important;
        margin: 0 auto 1rem auto !important;
        max-width: 100% !important;
      }

      /* Spacing */
      .space-y-6 > * + * {
        margin-top: 1rem !important;
      }
      
      .gap-6 {
        gap: 1rem !important;
      }

      /* Typography */
      .text-2xl {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
        font-weight: 700 !important;
      }

      .font-bold {
        font-weight: 700 !important;
      }

      /* Colors */
      .text-gray-900 {
        color: #111827 !important;
      }

      .text-gray-500 {
        color: #6b7280 !important;
      }

      .text-green-500 {
        color: #10b981 !important;
      }

      .text-red-500 {
        color: #ef4444 !important;
      }

      /* Value displays */
      .text-2xl.font-bold {
        font-size: 1.125rem !important;
        line-height: 1.5rem !important;
        margin: 0.25rem 0 !important;
      }

      /* Charts and graphics */
      canvas {
        max-width: 100% !important;
        height: auto !important;
        margin: 0.75rem auto !important;
        display: block !important;
      }

      /* Individual cards */
      .grid > div {
        background: white !important;
        border: 1px solid #e5e7eb !important;
        border-radius: 0.5rem !important;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
        padding: 1rem !important;
        break-inside: avoid !important;
        position: relative !important;
        overflow: hidden !important;
        margin: 0 !important;
        text-align: center !important;
      }

      /* Card accent */
      .grid > div::before {
        content: '' !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        height: 2px !important;
        background: linear-gradient(to right, #b100ff, #10b981) !important;
      }

      /* Progress bars */
      .bg-gray-200 {
        background-color: #e5e7eb !important;
        border-radius: 9999px !important;
        height: 0.25rem !important;
        overflow: hidden !important;
      }

      .bg-primary-400 {
        background-color: #b100ff !important;
      }

      /* Data tables */
      table {
        width: 100% !important;
        border-collapse: separate !important;
        border-spacing: 0 !important;
        margin: 0.75rem auto !important;
      }

      th {
        text-align: left !important;
        padding: 0.5rem !important;
        font-weight: 600 !important;
        color: #374151 !important;
        border-bottom: 2px solid #e5e7eb !important;
        font-size: 0.75rem !important;
      }

      td {
        padding: 0.5rem !important;
        border-bottom: 1px solid #e5e7eb !important;
        font-size: 0.75rem !important;
      }

      /* Lists */
      ul, ol {
        margin: 0.5rem 0 !important;
        padding-left: 1rem !important;
      }

      li {
        margin: 0.25rem 0 !important;
        font-size: 0.75rem !important;
      }

      /* Text sizes */
      .text-sm {
        font-size: 0.75rem !important;
        line-height: 1rem !important;
      }

      .text-xs {
        font-size: 0.625rem !important;
        line-height: 0.875rem !important;
      }

      /* Ensure proper page breaks */
      @media print {
        .grid > div {
          page-break-inside: avoid !important;
        }
        
        h1, h2, h3 {
          page-break-after: avoid !important;
        }
        
        table {
          page-break-inside: avoid !important;
        }

        /* Center content on page */
        @page {
          margin: 20mm !important;
          size: A4 !important;
        }

        body {
          margin: 0 auto !important;
          padding: 0 !important;
          width: 100% !important;
        }
      }
    `;
    clone.appendChild(style);

    // Force all elements to maintain their styles
    const allElements = clone.getElementsByTagName("*");
    for (let i = 0; i < allElements.length; i++) {
      const el = allElements[i] as HTMLElement;
      const styles = getComputedStyle(el);

      // Preserve layout
      el.style.display = styles.display;
      el.style.margin = styles.margin;
      el.style.padding = styles.padding;

      // Preserve colors and backgrounds
      el.style.color = styles.color;
      if (styles.backgroundColor !== "rgba(0, 0, 0, 0)") {
        el.style.backgroundColor = styles.backgroundColor;
      }

      // Preserve borders and shadows
      el.style.border = styles.border;
      el.style.borderRadius = styles.borderRadius;
      el.style.boxShadow = styles.boxShadow;

      // Preserve dimensions
      el.style.width = styles.width;
      el.style.height = styles.height;

      // Center text in cards
      if (
        el.classList.contains("grid") ||
        el.parentElement?.classList.contains("grid")
      ) {
        el.style.textAlign = "center";
      }
    }

    // Generate canvas with improved settings
    const canvas = await html2canvas(clone, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      backgroundColor: "#ffffff",
      logging: false,
      width: 800, // Fixed width for better control
      height: element.scrollHeight,
      onclone: (clonedDoc) => {
        const clonedElement = clonedDoc.getElementById(elementId);
        if (clonedElement) {
          clonedElement.style.transform = "none";
          clonedElement.style.width = "800px";
          clonedElement.style.margin = "0 auto";
        }
      },
    });

    // Remove temporary container
    document.body.removeChild(container);

    // Calculate dimensions
    const imgWidth = 190; // A4 width minus margins
    const pageHeight = 277; // A4 height minus margins
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    const pdf = new jsPDF("p", "mm", "a4");
    let firstPage = true;

    while (heightLeft >= 0) {
      if (!firstPage) {
        pdf.addPage();
      }

      // Add image with proper positioning and dimensions
      pdf.addImage(
        canvas,
        "PNG",
        10, // Center horizontally with 10mm margin on each side
        position,
        imgWidth,
        imgHeight,
        "",
        "FAST"
      );

      heightLeft -= pageHeight;
      position -= pageHeight;
      firstPage = false;
    }

    // Add metadata
    pdf.setProperties({
      title: fileName,
      subject: "Financial Report",
      author: "Her Finances",
      keywords: "finance, report, dashboard",
      creator: "Her Finances PDF Generator",
    });

    // Save the PDF
    pdf.save(`${fileName}.pdf`);
  } catch (error) {
    console.error("Error generating PDF:", error);
    throw error;
  }
};
