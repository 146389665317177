import { useCreditStore } from "../../store/creditStore";
import CreditCardOverview from "../../components/credit/CreditCardOverview";
import CreditCardDetails from "../../components/credit/CreditCardDetails";

function CreditCardsPage({ liabilities }) {
  const { cards, removeCard } = useCreditStore();

  const creditCardAccounts = liabilities?.accounts?.filter(
    (account) => account.type.includes("credit")
    // account.account_id.includes(
    //   liabilities?.liabilities?.credit.map((credit) => credit.account_id)
    // )
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Credit Cards
        </h1>
        {/* <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500">
          <Plus className="h-5 w-5 mr-2" />
          Link New Card
        </button> */}
      </div>

      <CreditCardOverview cards={cards} liabilities={liabilities} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {creditCardAccounts?.map((card) => (
          <CreditCardDetails
            key={card.id}
            card={card}
            onDelete={removeCard}
            liabilities={liabilities}
          />
        ))}
      </div>
    </div>
  );
}

export default CreditCardsPage;
