import React, { useState, useEffect } from "react";
import {
  Calculator,
  DollarSign,
  Percent,
  Calendar,
  TrendingUp,
} from "lucide-react";

function InvestmentCalculator() {
  const [initialInvestment, setInitialInvestment] = useState<string>("");
  const [monthlyContribution, setMonthlyContribution] = useState<string>("");
  const [annualReturn, setAnnualReturn] = useState<string>("7");
  const [investmentPeriod, setInvestmentPeriod] = useState<string>("10");
  const [inflationRate, setInflationRate] = useState<string>("2");

  const calculateInvestment = () => {
    const initial = parseFloat(initialInvestment) || 0;
    const monthly = parseFloat(monthlyContribution) || 0;
    const returnRate = parseFloat(annualReturn) || 0;
    const period = parseFloat(investmentPeriod) || 0;
    const inflation = parseFloat(inflationRate) || 0;

    const monthlyRate = (1 + returnRate / 100) ** (1 / 12) - 1;
    const months = period * 12;
    let futureValue = initial;

    // Calculate compound interest with monthly contributions
    for (let i = 0; i < months; i++) {
      futureValue = (futureValue + monthly) * (1 + monthlyRate);
    }

    // Calculate inflation-adjusted value
    const realValue = futureValue / (1 + inflation / 100) ** period;

    const totalContributions = initial + monthly * months;
    const totalEarnings = futureValue - totalContributions;

    return {
      futureValue,
      realValue,
      totalContributions,
      totalEarnings,
      monthlyInvestmentNeeded: calculateRequiredMonthly(
        initial,
        futureValue,
        returnRate,
        period
      ),
    };
  };

  const calculateRequiredMonthly = (
    initial: number,
    target: number,
    rate: number,
    years: number
  ) => {
    const monthlyRate = (1 + rate / 100) ** (1 / 12) - 1;
    const months = years * 12;
    const futureValueFactor = (1 + monthlyRate) ** months;

    return (
      (target - initial * futureValueFactor) /
      ((futureValueFactor - 1) / monthlyRate)
    );
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleNumberInput = (
    value: string,
    setter: (value: string) => void,
    isPercentage: boolean = false
  ) => {
    const regex = isPercentage ? /^\d*\.?\d*$/ : /^[0-9]*\.?[0-9]*$/;
    if (value === "" || regex.test(value)) {
      setter(value);
    }
  };

  const results =
    initialInvestment || monthlyContribution ? calculateInvestment() : null;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Initial Investment
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={initialInvestment}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setInitialInvestment)
                }
                onBlur={() => {
                  if (initialInvestment) {
                    setInitialInvestment(
                      parseFloat(initialInvestment).toFixed(2)
                    );
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter initial amount"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Monthly Contribution
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={monthlyContribution}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setMonthlyContribution)
                }
                onBlur={() => {
                  if (monthlyContribution) {
                    setMonthlyContribution(
                      parseFloat(monthlyContribution).toFixed(2)
                    );
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter monthly contribution"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Expected Annual Return (%)
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Percent className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={annualReturn}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setAnnualReturn, true)
                }
                onBlur={() => {
                  if (annualReturn) {
                    setAnnualReturn(parseFloat(annualReturn).toFixed(1));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter expected return"
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Historical S&P 500 average: ~10% (before inflation)
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Investment Period (Years)
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={investmentPeriod}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setInvestmentPeriod)
                }
                onBlur={() => {
                  if (investmentPeriod) {
                    setInvestmentPeriod(
                      Math.round(parseFloat(investmentPeriod)).toString()
                    );
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter investment period"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Expected Inflation Rate (%)
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Percent className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={inflationRate}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setInflationRate, true)
                }
                onBlur={() => {
                  if (inflationRate) {
                    setInflationRate(parseFloat(inflationRate).toFixed(1));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter inflation rate"
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Historical average: 2-3% per year
            </p>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
          <h3 className="text-lg font-medium mb-6">Investment Summary</h3>

          {results ? (
            <div className="space-y-6">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Future Value
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {formatCurrency(results.futureValue)}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  ({formatCurrency(results.realValue)} in today's dollars)
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Total Contributions
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {formatCurrency(results.totalContributions)}
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Total Earnings
                </p>
                <p className="text-2xl font-bold text-green-500">
                  {formatCurrency(results.totalEarnings)}
                </p>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Investment Breakdown
                </p>
                <div className="mt-2 w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2.5">
                  <div
                    className="bg-primary-400 h-2.5 rounded-full"
                    style={{
                      width: `${
                        (results.totalContributions / results.futureValue) * 100
                      }%`,
                    }}
                  />
                </div>
                <div className="flex justify-between mt-2 text-xs text-gray-500 dark:text-gray-400">
                  <span>
                    Contributions:{" "}
                    {(
                      (results.totalContributions / results.futureValue) *
                      100
                    ).toFixed(1)}
                    %
                  </span>
                  <span>
                    Earnings:{" "}
                    {(
                      (results.totalEarnings / results.futureValue) *
                      100
                    ).toFixed(1)}
                    %
                  </span>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Monthly Investment Analysis
                </h4>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Current Monthly:</span>
                    <span className="font-medium">
                      {formatCurrency(parseFloat(monthlyContribution) || 0)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Required for Goal:</span>
                    <span className="font-medium">
                      {formatCurrency(
                        Math.max(0, results.monthlyInvestmentNeeded)
                      )}
                    </span>
                  </div>
                  {results.monthlyInvestmentNeeded >
                    (parseFloat(monthlyContribution) || 0) && (
                    <div className="p-3 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg mt-3">
                      <p className="text-sm text-yellow-800 dark:text-yellow-200">
                        To reach your goal, consider increasing your monthly
                        contribution by{" "}
                        {formatCurrency(
                          results.monthlyInvestmentNeeded -
                            (parseFloat(monthlyContribution) || 0)
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <Calculator className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500 dark:text-gray-400">
                Enter investment details to see the calculation results
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InvestmentCalculator;
