import React, { useEffect, useState } from "react";
import {
  TrendingUp,
  TrendingDown,
  Search,
  Filter,
  Plus,
  Edit2,
  Trash2,
  DollarSign,
  Percent,
  Calendar,
} from "lucide-react";
import AddBondModal from "../../../src/pages/investments/components/AddBondModal";
import { format } from "date-fns";
import {
  createDocumentWithRandomId,
  deleteDocument,
  getDocument,
  updateDocument,
} from "../../utils/firebase-db";
import { useAuthStore } from "../../store/authStore";
import { useNotificationStore } from "../../store/notificationStore";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../config/firebase";

interface BondFilters {
  search: string;
  sortBy:
    | "value_desc"
    | "value_asc"
    | "couponRate_desc"
    | "couponRate_asc"
    | "ytm_desc"
    | "ytm_asc"
    | "maturity_desc"
    | "maturity_asc"
    | "name_asc"
    | "name_desc";
}

function BondsPage({ investments, addedBonds }) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingBond, setEditingBond] = useState(null);
  const [filters, setFilters] = useState<BondFilters>({
    search: "",
    sortBy: "value_desc",
  });
  const [userNotifications, setUserNotifications] = useState(null);
  const { addNotification } = useNotificationStore();

  const { user } = useAuthStore();

  const bondSecurities = investments?.securities?.filter(
    (security) => security.type === "fixed income"
  );

  const bondSecurityIds = bondSecurities?.map(
    (security) => security.security_id
  );
  const bondHoldingsSecond = investments?.holdings?.filter((holding) =>
    bondSecurityIds.includes(holding.security_id)
  );

  // Merging dynamically based on `security_id`
  const mergedData = bondHoldingsSecond?.map((holding) => {
    const matchingSecurity = bondSecurities?.find(
      (security) => security.security_id === holding.security_id
    );

    return {
      ...holding,
      ...(matchingSecurity || {}), // Merge security data if found
      isManuallyAdded: false,
      userId: user.id,
    };
  });

  const allBonds = [
    ...(mergedData || []),
    ...(addedBonds?.map((bond) => ({
      ...bond,
      isManuallyAdded: true,
      userId: user.id,
    })) || []),
  ];

  const uniqueBonds = allBonds?.filter((bond) => bond.userId === user.id);

  // Filter and sort bonds
  const filteredBonds = uniqueBonds
    ?.filter((bond) => {
      const searchFields = [
        bond.name,
        bond.type,
        bond.couponRate?.toString(),
        bond.maturityDate,
      ];

      return searchFields.some((field) =>
        field?.toLowerCase().includes(filters.search.toLowerCase())
      );
    })
    .sort((a, b) => {
      const [field, direction] = filters.sortBy.split("_");
      const getValue = (bond: any) => {
        switch (field) {
          case "name":
            return cleanBondName(bond.name);
          case "value":
            return bond.institution_value || 0;
          case "couponRate":
            return bond.couponRate || 0;
          case "ytm":
            return Number(calculateApproxYTM(bond)) || 0;
          case "maturity":
            return new Date(bond.maturityDate || 0).getTime();
          default:
            return bond.institution_value || 0;
        }
      };

      const aValue = getValue(a);
      const bValue = getValue(b);

      if (typeof aValue === "string") {
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return direction === "asc" ? aValue - bValue : bValue - aValue;
    });

  // Get Total Bond Value
  const totalBondValue = filteredBonds?.reduce(
    (sum, holding) => sum + holding.institution_price * holding.quantity,
    0
  );

  const totalCostBasis = filteredBonds?.reduce(
    (sum, holding) => sum + holding.cost_basis * holding.quantity,
    0
  );

  const totalReturn = totalBondValue - totalCostBasis;

  function cleanBondName(fullName) {
    const keywordMappings = [
      { regex: /treas(ury)? bills?/i, shortName: "US Treas Bills" },
      { regex: /treas(ury)? notes?/i, shortName: "US Treas Notes" },
      { regex: /treas(ury)? bonds?/i, shortName: "US Treas Bonds" },
      { regex: /corporate bonds?/i, shortName: "Corporate Bond" },
      { regex: /municipal bonds?/i, shortName: "Municipal Bond" },
      { regex: /agency bonds?/i, shortName: "Agency Bond" },
      { regex: /fixed income/i, shortName: "Fixed Income" },
      { regex: /government securities/i, shortName: "Gov Securities" },
    ];

    for (const { regex, shortName } of keywordMappings) {
      if (regex.test(fullName)) {
        return shortName;
      }
    }

    return fullName
      .replace(/([0-9]+\/[0-9]+\/[0-9]+|\d{2}\/\d{2}\/\d{2})/g, "")
      .replace(/\d+\.\d+%?/g, "")
      .replace(/B\/E Dtd \d{2}\/\d{2}\/\d{2}/g, "")
      .trim();
  }

  const averageYield =
    filteredBonds?.reduce(
      (sum, bond) => sum + (calculateApproxYTM(bond) || 0),
      0
    ) / filteredBonds?.length;

  const handleAddBond = async (data: any) => {
    const currentPrice = data.purchasePrice * 1.02;
    const totalValue = data.quantity * currentPrice;

    const newBond = {
      security_id: Math.random().toString(36).substr(2, 9),
      quantity: data.quantity,
      cost_basis: data.purchasePrice,
      institution_price: currentPrice,
      institution_value: totalValue,
      couponRate: data.couponRate,
      faceValue: data.faceValue,
      name: data.name,
      type: data.type,
      maturityDate: format(new Date(data.maturityDate), "MMM d, yyyy"),
      couponFrequency: data.couponFrequency,
      userId: user.id,
    };

    // await createDocumentWithRandomId("addedBonds", newBond);
    await addDoc(collection(db, "addedBonds", user.id, "bonds"), newBond);
    if (userNotifications?.notifications.push.investmentAlerts) {
      await addNotification({
        type: "success",
        message: `Bond ${data.name} added successfully!`,
        title: `New bond`,
      });
    }

    setIsAddModalOpen(false);
  };

  const handleEditBond = async (id: string, data: any) => {
    const currentPrice = data.purchasePrice * 1.02;
    const totalValue = data.quantity * currentPrice;

    const updatedBond = {
      quantity: data.quantity,
      cost_basis: data.purchasePrice,
      institution_price: currentPrice,
      institution_value: totalValue,
      couponRate: data.couponRate,
      faceValue: data.faceValue,
      name: data.name,
      type: data.type,
      maturityDate: format(new Date(data.maturityDate), "MMM d, yyyy"),
      couponFrequency: data.couponFrequency,
      userId: user.id,
    };

    const bondRef = doc(db, "addedBonds", user.id, "bonds", id);
    await updateDoc(bondRef, updatedBond);
    setEditingBond(null);
    setIsAddModalOpen(false);
  };

  const handleDeleteBond = async (bondId: string) => {
    const bondRef = doc(db, "addedBonds", user.id, "bonds", bondId);
    await deleteDoc(bondRef);
  };

  function calculateApproxYTM({
    faceValue,
    cost_basis,
    couponRate,
    maturityDate,
  }) {
    const annualCoupon = !isNaN(couponRate)
      ? (couponRate / 100) * faceValue
      : 0;

    const yearsToMaturity = maturityDate
      ? new Date(maturityDate).getFullYear() - new Date().getFullYear()
      : 0;

    if (faceValue && cost_basis && couponRate && maturityDate) {
      return (
        ((annualCoupon + (faceValue - cost_basis) / yearsToMaturity) /
          ((faceValue + cost_basis) / 2)) *
        100
      ).toFixed(2);
    }
  }

  useEffect(() => {
    const fetchnotifications = async () => {
      const userNotifications = await getDocument(
        "notificationSettings",
        user.id
      );

      setUserNotifications(userNotifications);
    };

    fetchnotifications();
  }, []);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search bonds..."
            value={filters.search}
            onChange={(e) =>
              setFilters((f) => ({ ...f, search: e.target.value }))
            }
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <div className="flex items-center">
            <Filter className="h-5 w-5 text-gray-400 mr-2" />
            <select
              value={filters.sortBy}
              onChange={(e) =>
                setFilters((f) => ({ ...f, sortBy: e.target.value as any }))
              }
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-400"
            >
              <option value="value_desc">Market Value (High to Low)</option>
              <option value="value_asc">Market Value (Low to High)</option>
              <option value="couponRate_desc">Coupon Rate (High to Low)</option>
              <option value="couponRate_asc">Coupon Rate (Low to High)</option>
              <option value="ytm_desc">Yield to Maturity (High to Low)</option>
              <option value="ytm_asc">Yield to Maturity (Low to High)</option>
              <option value="maturity_desc">
                Maturity Date (Latest to Earliest)
              </option>
              <option value="maturity_asc">
                Maturity Date (Earliest to Latest)
              </option>
              <option value="name_asc">Name (A to Z)</option>
              <option value="name_desc">Name (Z to A)</option>
            </select>
          </div>
          <button
            onClick={() => {
              setEditingBond(null);
              setIsAddModalOpen(true);
            }}
            className="flex items-center px-4 py-2 text-white bg-primary-400 hover:bg-primary-500 rounded-lg"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Bond
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Bond Value</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">
            ${(totalBondValue || 0)?.toLocaleString()}
          </p>
          {totalReturn >= 0 ? (
            <div className="flex items-center mt-2 text-green-500">
              <TrendingUp className="h-4 w-4 mr-1" />
              <span>+${totalReturn.toLocaleString()} Total Return</span>
            </div>
          ) : (
            <div className="flex items-center mt-2 text-red-500">
              <TrendingDown className="h-4 w-4 mr-1" />
              <span>
                -${Math.abs(totalReturn).toLocaleString()} Total Return
              </span>
            </div>
          )}
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Average Yield</h3>
            <Percent className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">
            {(averageYield || 0)?.toFixed(2)}%
          </p>
          <p className="text-sm text-gray-500 mt-2">Yield to Maturity</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Next Coupon Payment</h3>
            <Calendar className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">Jun 15, 2024</p>
          <p className="text-sm text-gray-500 mt-2">Multiple bonds due</p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium">Bond Holdings</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Bond Name/Type
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Face Value
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Price
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Coupon Rate
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  YTM
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Maturity
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Total Value
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {filteredBonds?.map((bond) => {
                const currentPrice = bond.institution_price || 0;
                const totalValue = bond.institution_value || 0;
                const gainPercent =
                  bond.cost_basis && bond.cost_basis !== 0
                    ? ((totalValue - bond.cost_basis) / bond.cost_basis) * 100
                    : 0;

                return (
                  <tr
                    key={bond.security_id}
                    className="hover:bg-gray-50 dark:hover:bg-gray-700"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div>
                        <div className="font-medium text-gray-900 dark:text-white">
                          {cleanBondName(bond.name)}
                        </div>
                        <div className="text-sm text-gray-500">Bond</div>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      <span className="text-sm text-gray-900 dark:text-white">
                        {bond.quantity}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      <span className="text-sm text-gray-900 dark:text-white">
                        ${bond?.faceValue?.toLocaleString() || 0}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      <span className="text-sm text-gray-900 dark:text-white">
                        ${currentPrice.toLocaleString()}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      <span className="text-sm text-gray-900 dark:text-white">
                        {bond?.couponRate?.toLocaleString() || 0}%
                      </span>
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      <span className="text-sm text-gray-900 dark:text-white">
                        {isNaN(Number(calculateApproxYTM(bond)))
                          ? 0
                          : calculateApproxYTM(bond)}
                        %
                      </span>
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      <span className="text-sm text-gray-900 dark:text-white">
                        {bond?.maturityDate?.toLocaleString() || 0}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      <div className="flex flex-col items-end">
                        <span className="text-sm text-gray-900 dark:text-white">
                          ${totalValue.toLocaleString()}
                        </span>
                        <span className="text-xs text-green-500">
                          +{gainPercent.toFixed(2)}%
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      {bond.isManuallyAdded && (
                        <div className="flex items-center justify-end space-x-2">
                          <button
                            onClick={() => {
                              setEditingBond(bond);
                              setIsAddModalOpen(true);
                            }}
                            className="p-2 text-gray-400 hover:text-primary-400 transition-colors"
                          >
                            <Edit2 className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteBond(bond.id)}
                            className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                          >
                            <Trash2 className="h-5 w-5" />
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <AddBondModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setEditingBond(null);
        }}
        onAdd={handleAddBond}
        editingBond={editingBond}
        onEdit={handleEditBond}
      />
    </div>
  );
}

export default BondsPage;

{
  /* {bondHoldings?.map((bond) => (
  <tr
    key={bond.id}
    className="hover:bg-gray-50 dark:hover:bg-gray-700"
  >
    <td className="px-6 py-4 whitespace-nowrap">
      <div>
        <div className="font-medium text-gray-900 dark:text-white">
          {bond.name}
        </div>
        <div className="text-sm text-gray-500">{bond.type}</div>
      </div>
    </td>
    <td className="px-6 py-4 text-right whitespace-nowrap">
      <span className="text-sm text-gray-900 dark:text-white">
        {bond.quantity}
      </span>
    </td>
    <td className="px-6 py-4 text-right whitespace-nowrap">
      <span className="text-sm text-gray-900 dark:text-white">
        ${bond.faceValue.toLocaleString()}
      </span>
    </td>
    <td className="px-6 py-4 text-right whitespace-nowrap">
      <span className="text-sm text-gray-900 dark:text-white">
        ${bond.currentPrice.toLocaleString()}
      </span>
    </td>
    <td className="px-6 py-4 text-right whitespace-nowrap">
      <span className="text-sm text-gray-900 dark:text-white">
        {bond.couponRate}%
      </span>
    </td>
    <td className="px-6 py-4 text-right whitespace-nowrap">
      <span className="text-sm text-gray-900 dark:text-white">
        {bond.yieldToMaturity}%
      </span>
    </td>
    <td className="px-6 py-4 text-right whitespace-nowrap">
      <div>
        <div className="text-sm text-gray-900 dark:text-white">
          {new Date(bond.maturityDate).toLocaleDateString(
            "en-US",
            { year: "numeric", month: "short", day: "numeric" }
          )}
        </div>
        <div className="text-xs text-gray-500">
          Next Coupon:{" "}
          {new Date(bond.nextCouponDate).toLocaleDateString(
            "en-US",
            { month: "short", day: "numeric" }
          )}
        </div>
      </div>
    </td>
    <td className="px-6 py-4 text-right whitespace-nowrap">
      <div className="flex flex-col items-end">
        <span className="text-sm text-gray-900 dark:text-white">
          ${bond.totalValue.toLocaleString()}
        </span>
        <span className="text-xs text-green-500">
          +{bond.gainPercent.toFixed(2)}%
        </span>
      </div>
    </td>
  </tr>
))} */
}
