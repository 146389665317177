import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import { useThemeStore } from "../../store/themeStore";
import { User } from "../../types/user";
import { Menu, X } from "lucide-react";
import AIChatbot from "../chat/AIChatbot";

interface AppLayoutProps {
  userOverride?: User;
  children?: React.ReactNode;
}

function AppLayout({
  userOverride,
  children,
  transactions,
  userAccounts,
  liabilities,
  investments,
  budgets,
  addedStocks,
  addedBonds,
  commodities,
  userAddedInvestments,
  goals,
}: AppLayoutProps) {
  const { isDarkMode } = useThemeStore();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div
      className={`min-h-screen w-full overflow-x-hidden ${
        isDarkMode ? "dark" : ""
      }`}
    >
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200">
        {/* Mobile menu button */}
        <div className="lg:hidden fixed top-4 left-4 z-50">
          <button
            onClick={toggleMobileMenu}
            className="p-2 rounded-lg bg-white dark:bg-gray-800 shadow-lg"
          >
            {isMobileMenuOpen ? (
              <X className="h-6 w-6 text-gray-600 dark:text-gray-300" />
            ) : (
              <Menu className="h-6 w-6 text-gray-600 dark:text-gray-300" />
            )}
          </button>
        </div>

        {/* Mobile sidebar backdrop */}
        {isMobileMenuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          />
        )}

        {/* Sidebar */}
        <div
          className={`fixed inset-y-0 left-0 transform ${
            isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0 transition-transform duration-200 ease-in-out z-40 lg:z-0`}
        >
          <Sidebar
            userOverride={userOverride}
            onCloseMobileMenu={() => setIsMobileMenuOpen(false)}
          />
        </div>

        {/* Main content */}
        <div className="w-full lg:pl-72">
          <TopBar userOverride={userOverride} />
          <main className="py-8 px-4 sm:px-6 lg:px-8 w-full max-w-full overflow-x-hidden">
            <div className="max-w-7xl mx-auto w-full">
              {children || <Outlet />}
            </div>
          </main>
        </div>

        {/* AI Chatbot */}
        <AIChatbot
          transactions={transactions}
          userAccounts={userAccounts}
          investments={investments}
          liabilities={liabilities}
          budgets={budgets}
          addedStocks={addedStocks}
          addedBonds={addedBonds}
          commodities={commodities}
          userAddedInvestments={userAddedInvestments}
          goals={goals}
        />
      </div>
    </div>
  );
}

export default AppLayout;
