export const transactions = [
  {
    date: "2024-11-15",
    amount: -4.22,
    transaction_type: "special",
    category: ["Transfer", "Payroll"],
    merchant_entity_id: null,
    account_owner: null,
    transaction_id: "Rz9AeKLaG1cDqvpyeM9qU5dWLJejA7iwKzVgm",
    pending: false,
    name: "INTRST PYMNT",
    unofficial_currency_code: null,
    personal_finance_category: {
      detailed: "INCOME_WAGES",
      confidence_level: "LOW",
      primary: "INCOME",
    },
    location: {
      postal_code: null,
      country: null,
      address: null,
      city: null,
      lon: null,
      store_number: null,
      lat: null,
      region: null,
    },
    datetime: null,
    counterparties: [],
    iso_currency_code: "USD",
    payment_meta: {
      reason: null,
      payer: null,
      by_order_of: null,
      payee: null,
      payment_method: null,
      ppd_id: null,
      reference_number: null,
      payment_processor: null,
    },
    authorized_datetime: null,
    pending_transaction_id: null,
    transaction_code: null,
    logo_url: null,
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_INCOME.png",
    account_id: "qKQom3EGAjHGZBAlj1MZie1BKqAg5qcgrzRRr",
    website: null,
    check_number: null,
    merchant_name: null,
    authorized_date: "2024-11-15",
    category_id: "21009000",
    payment_channel: "other",
  },
  {
    counterparties: [
      {
        confidence_level: "LOW",
        phone_number: null,
        type: "merchant",
        logo_url: null,
        entity_id: null,
        website: null,
        name: "FUN",
      },
    ],
    name: "SparkFun",
    transaction_type: "place",
    payment_meta: {
      reference_number: null,
      ppd_id: null,
      payee: null,
      payment_processor: null,
      reason: null,
      payer: null,
      by_order_of: null,
      payment_method: null,
    },
    category_id: "13005000",
    logo_url: null,
    unofficial_currency_code: null,
    pending: false,
    authorized_datetime: null,
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    personal_finance_category: {
      detailed: "ENTERTAINMENT_SPORTING_EVENTS_AMUSEMENT_PARKS_AND_MUSEUMS",
      primary: "ENTERTAINMENT",
      confidence_level: "LOW",
    },
    check_number: null,
    datetime: null,
    merchant_name: "FUN",
    pending_transaction_id: null,
    transaction_id: "nyj9XroGAJiEqoXMQweqFGlBLmRjgptZd8qjJ",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_ENTERTAINMENT.png",
    date: "2024-11-16",
    website: null,
    account_owner: null,
    merchant_entity_id: null,
    authorized_date: "2024-11-15",
    payment_channel: "in store",
    iso_currency_code: "USD",
    amount: 89.4,
    category: ["Food and Drink", "Restaurants"],
    location: {
      postal_code: null,
      lon: null,
      city: null,
      region: null,
      store_number: null,
      country: null,
      address: null,
      lat: null,
    },
    transaction_code: null,
  },
  {
    iso_currency_code: "USD",
    account_owner: null,
    transaction_type: "place",
    name: "McDonald's",
    category: ["Food and Drink", "Restaurants", "Fast Food"],
    authorized_date: "2024-11-17",
    merchant_name: "McDonald's",
    category_id: "13005032",
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    transaction_id: "ALmWlPrZK9cbeV5GmjDeS8e4GBjwVyFa7WNQy",
    website: "mcdonalds.com",
    authorized_datetime: null,
    check_number: null,
    transaction_code: null,
    pending: false,
    pending_transaction_id: null,
    datetime: null,
    date: "2024-11-17",
    counterparties: [
      {
        phone_number: null,
        logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
        name: "McDonald's",
        confidence_level: "VERY_HIGH",
        website: "mcdonalds.com",
        entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
        type: "merchant",
      },
    ],
    location: {
      lon: null,
      postal_code: null,
      lat: null,
      region: null,
      address: null,
      country: null,
      store_number: "3322",
      city: null,
    },
    merchant_entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
    personal_finance_category: {
      detailed: "FOOD_AND_DRINK_FAST_FOOD",
      primary: "FOOD_AND_DRINK",
      confidence_level: "VERY_HIGH",
    },
    payment_meta: {
      reason: null,
      payee: null,
      payment_method: null,
      payment_processor: null,
      reference_number: null,
      payer: null,
      by_order_of: null,
      ppd_id: null,
    },
    payment_channel: "in store",
    amount: 12,
    unofficial_currency_code: null,
  },
  {
    pending: false,
    payment_meta: {
      by_order_of: null,
      reference_number: null,
      payment_processor: null,
      payment_method: null,
      ppd_id: null,
      reason: null,
      payer: null,
      payee: null,
    },
    transaction_type: "place",
    counterparties: [
      {
        phone_number: null,
        name: "Starbucks",
        website: "starbucks.com",
        confidence_level: "VERY_HIGH",
        entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
        type: "merchant",
        logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
      },
    ],
    merchant_name: "Starbucks",
    payment_channel: "in store",
    merchant_entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
    logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
    amount: 4.33,
    datetime: null,
    unofficial_currency_code: null,
    check_number: null,
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    date: "2024-11-17",
    category: ["Food and Drink", "Restaurants", "Coffee Shop"],
    personal_finance_category: {
      detailed: "FOOD_AND_DRINK_COFFEE",
      confidence_level: "VERY_HIGH",
      primary: "FOOD_AND_DRINK",
    },
    transaction_id: "GWm6GMagdktwEmzg83vEt8oV1geavrFqlWg5q",
    authorized_date: "2024-11-17",
    pending_transaction_id: null,
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    location: {
      postal_code: null,
      address: null,
      store_number: null,
      country: null,
      city: null,
      region: null,
      lat: null,
      lon: null,
    },
    transaction_code: null,
    website: "starbucks.com",
    name: "Starbucks",
    category_id: "13005043",
    authorized_datetime: null,
    iso_currency_code: "USD",
    account_owner: null,
  },
  {
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    account_owner: null,
    iso_currency_code: "USD",
    check_number: null,
    name: "United Airlines",
    pending_transaction_id: null,
    counterparties: [
      {
        logo_url:
          "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
        type: "merchant",
        phone_number: null,
        entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
        confidence_level: "VERY_HIGH",
        website: "united.com",
        name: "United Airlines",
      },
    ],
    pending: false,
    category: ["Travel", "Airlines and Aviation Services"],
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRAVEL_FLIGHTS",
      primary: "TRAVEL",
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
    transaction_code: null,
    merchant_entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRAVEL.png",
    amount: -500,
    website: "united.com",
    location: {
      city: null,
      country: null,
      postal_code: null,
      lat: null,
      address: null,
      lon: null,
      region: null,
      store_number: null,
    },
    authorized_datetime: null,
    payment_channel: "in store",
    transaction_id: "Wa9AoxE7k4H7Wg8PbwyWFB7DrEoAl1iXrWzk4",
    payment_meta: {
      payee: null,
      payment_method: null,
      reason: null,
      payment_processor: null,
      by_order_of: null,
      payer: null,
      reference_number: null,
      ppd_id: null,
    },
    datetime: null,
    authorized_date: "2024-11-18",
    date: "2024-11-18",
    transaction_type: "special",
    merchant_name: "United Airlines",
    category_id: "22001000",
    unofficial_currency_code: null,
  },
  {
    name: "Uber 063015 SF**POOL**",
    unofficial_currency_code: null,
    iso_currency_code: "USD",
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    authorized_date: "2024-11-19",
    merchant_name: "Uber",
    personal_finance_category: {
      primary: "TRANSPORTATION",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      confidence_level: "VERY_HIGH",
    },
    category: ["Travel", "Taxi"],
    transaction_type: "special",
    payment_meta: {
      payment_method: null,
      payer: null,
      ppd_id: null,
      payee: null,
      reason: null,
      payment_processor: null,
      by_order_of: null,
      reference_number: null,
    },
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    transaction_id: "E86WzQxp3rCv8l7XLr38Fy1VpJGBEqfrwjnq9",
    datetime: null,
    counterparties: [
      {
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        website: "uber.com",
        type: "merchant",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        phone_number: null,
        confidence_level: "VERY_HIGH",
      },
    ],
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    date: "2024-11-20",
    authorized_datetime: null,
    category_id: "22016000",
    pending: false,
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    pending_transaction_id: null,
    account_owner: null,
    website: "uber.com",
    check_number: null,
    amount: 5.4,
    transaction_code: null,
    location: {
      country: null,
      lon: null,
      address: null,
      lat: null,
      postal_code: null,
      city: null,
      store_number: null,
      region: null,
    },
    payment_channel: "online",
  },
  {
    transaction_id: "vxkJrNzwlRcqb5y6r8xbfbEDBaVMvWugjoQML",
    category: ["Payment", "Credit Card"],
    account_owner: null,
    location: {
      address: null,
      store_number: null,
      region: null,
      lat: null,
      country: null,
      city: null,
      lon: null,
      postal_code: null,
    },
    authorized_date: "2024-11-19",
    website: null,
    date: "2024-11-20",
    merchant_name: null,
    iso_currency_code: "USD",
    transaction_code: null,
    counterparties: [],
    authorized_datetime: null,
    pending: false,
    transaction_type: "special",
    personal_finance_category: {
      detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
      primary: "LOAN_PAYMENTS",
      confidence_level: "LOW",
    },
    payment_channel: "other",
    amount: 25,
    category_id: "16001000",
    check_number: null,
    logo_url: null,
    unofficial_currency_code: null,
    pending_transaction_id: null,
    name: "CREDIT CARD 3333 PAYMENT *//",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_LOAN_PAYMENTS.png",
    merchant_entity_id: null,
    account_id: "qKQom3EGAjHGZBAlj1MZie1BKqAg5qcgrzRRr",
    payment_meta: {
      payment_method: null,
      ppd_id: null,
      payer: null,
      payee: null,
      by_order_of: null,
      reference_number: null,
      reason: null,
      payment_processor: null,
    },
    datetime: null,
  },
  {
    check_number: null,
    website: "uber.com",
    transaction_id: "8NQPxrLdZRcEApLgMzbAFd9lmr1JpvigAxobZ",
    authorized_date: "2024-12-02",
    datetime: null,
    payment_channel: "online",
    pending: false,
    iso_currency_code: "USD",
    pending_transaction_id: null,
    category_id: "22016000",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    location: {
      store_number: null,
      address: null,
      lon: null,
      city: null,
      lat: null,
      postal_code: null,
      region: null,
      country: null,
    },
    unofficial_currency_code: null,
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    authorized_datetime: null,
    amount: 6.33,
    transaction_type: "special",
    category: ["Travel", "Taxi"],
    payment_meta: {
      payee: null,
      payment_processor: null,
      payment_method: null,
      reference_number: null,
      reason: null,
      by_order_of: null,
      ppd_id: null,
      payer: null,
    },
    counterparties: [
      {
        website: "uber.com",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        confidence_level: "VERY_HIGH",
        type: "merchant",
        name: "Uber",
        phone_number: null,
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
      },
    ],
    transaction_code: null,
    name: "Uber 072515 SF**POOL**",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    date: "2024-12-03",
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    merchant_name: "Uber",
    account_owner: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
  },
  {
    unofficial_currency_code: null,
    authorized_datetime: null,
    transaction_code: null,
    location: {
      postal_code: null,
      region: null,
      city: null,
      country: null,
      address: null,
      store_number: null,
      lon: null,
      lat: null,
    },
    date: "2024-12-15",
    datetime: null,
    website: null,
    category: ["Transfer", "Payroll"],
    logo_url: null,
    payment_channel: "other",
    account_id: "qKQom3EGAjHGZBAlj1MZie1BKqAg5qcgrzRRr",
    name: "INTRST PYMNT",
    personal_finance_category: {
      primary: "INCOME",
      detailed: "INCOME_WAGES",
      confidence_level: "LOW",
    },
    payment_meta: {
      payment_method: null,
      payment_processor: null,
      reference_number: null,
      reason: null,
      by_order_of: null,
      ppd_id: null,
      payee: null,
      payer: null,
    },
    amount: -4.22,
    transaction_type: "special",
    pending: false,
    category_id: "21009000",
    check_number: null,
    pending_transaction_id: null,
    transaction_id: "9PZjvzpa6wtDJveXGB4JUAxwMlGyvkukLlNpb",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_INCOME.png",
    iso_currency_code: "USD",
    merchant_name: null,
    merchant_entity_id: null,
    counterparties: [],
    account_owner: null,
    authorized_date: "2024-12-15",
  },
  {
    personal_finance_category: {
      detailed: "ENTERTAINMENT_SPORTING_EVENTS_AMUSEMENT_PARKS_AND_MUSEUMS",
      primary: "ENTERTAINMENT",
      confidence_level: "LOW",
    },
    transaction_type: "place",
    amount: 89.4,
    merchant_entity_id: null,
    transaction_code: null,
    payment_meta: {
      payment_processor: null,
      payer: null,
      by_order_of: null,
      reason: null,
      ppd_id: null,
      reference_number: null,
      payment_method: null,
      payee: null,
    },
    transaction_id: "gE36rgeG7JimK3yWdJQKfVvEJArozmfPblpxE",
    merchant_name: "FUN",
    website: null,
    payment_channel: "in store",
    date: "2024-12-16",
    counterparties: [
      {
        website: null,
        phone_number: null,
        confidence_level: "LOW",
        name: "FUN",
        logo_url: null,
        entity_id: null,
        type: "merchant",
      },
    ],
    iso_currency_code: "USD",
    category_id: "13005000",
    datetime: null,
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_ENTERTAINMENT.png",
    account_owner: null,
    pending_transaction_id: null,
    check_number: null,
    authorized_date: "2024-12-15",
    category: ["Food and Drink", "Restaurants"],
    pending: false,
    location: {
      address: null,
      city: null,
      postal_code: null,
      country: null,
      store_number: null,
      lon: null,
      region: null,
      lat: null,
    },
    unofficial_currency_code: null,
    logo_url: null,
    name: "SparkFun",
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    authorized_datetime: null,
  },
  {
    merchant_name: "McDonald's",
    transaction_type: "place",
    category: ["Food and Drink", "Restaurants", "Fast Food"],
    check_number: null,
    transaction_code: null,
    personal_finance_category: {
      primary: "FOOD_AND_DRINK",
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_FAST_FOOD",
    },
    name: "McDonald's",
    merchant_entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
    amount: 12,
    location: {
      lat: null,
      region: null,
      address: null,
      city: null,
      country: null,
      store_number: "3322",
      lon: null,
      postal_code: null,
    },
    account_owner: null,
    date: "2024-12-17",
    authorized_datetime: null,
    logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
    category_id: "13005032",
    transaction_id: "pqemJB8yAXSvr7gl4aZrFxl9kp7Zg5ib4DMZD",
    website: "mcdonalds.com",
    iso_currency_code: "USD",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payment_method: null,
      payer: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    unofficial_currency_code: null,
    authorized_date: "2024-12-17",
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    counterparties: [
      {
        entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
        logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
        name: "McDonald's",
        website: "mcdonalds.com",
        phone_number: null,
        type: "merchant",
        confidence_level: "VERY_HIGH",
      },
    ],
    pending_transaction_id: null,
    datetime: null,
    pending: false,
    payment_channel: "in store",
  },
  {
    category_id: "13005043",
    check_number: null,
    datetime: null,
    amount: 4.33,
    authorized_datetime: null,
    pending_transaction_id: null,
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    location: {
      lat: null,
      region: null,
      country: null,
      store_number: null,
      postal_code: null,
      city: null,
      address: null,
      lon: null,
    },
    pending: false,
    authorized_date: "2024-12-17",
    category: ["Food and Drink", "Restaurants", "Coffee Shop"],
    transaction_code: null,
    transaction_type: "place",
    website: "starbucks.com",
    account_owner: null,
    personal_finance_category: {
      primary: "FOOD_AND_DRINK",
      detailed: "FOOD_AND_DRINK_COFFEE",
      confidence_level: "VERY_HIGH",
    },
    counterparties: [
      {
        name: "Starbucks",
        website: "starbucks.com",
        confidence_level: "VERY_HIGH",
        entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
        type: "merchant",
        logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
        phone_number: null,
      },
    ],
    date: "2024-12-17",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    iso_currency_code: "USD",
    payment_channel: "in store",
    payment_meta: {
      payer: null,
      reason: null,
      by_order_of: null,
      payment_processor: null,
      ppd_id: null,
      payee: null,
      payment_method: null,
      reference_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
    unofficial_currency_code: null,
    merchant_entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
    merchant_name: "Starbucks",
    name: "Starbucks",
    transaction_id: "ol7oRzgGyLtaKz9x4WnKFb8JAXDdgNu1aXedR",
  },
  {
    transaction_type: "special",
    counterparties: [
      {
        logo_url:
          "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
        confidence_level: "VERY_HIGH",
        entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
        type: "merchant",
        phone_number: null,
        website: "united.com",
        name: "United Airlines",
      },
    ],
    website: "united.com",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRAVEL.png",
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    pending_transaction_id: null,
    authorized_date: "2024-12-18",
    location: {
      store_number: null,
      city: null,
      postal_code: null,
      lat: null,
      country: null,
      address: null,
      lon: null,
      region: null,
    },
    account_owner: null,
    authorized_datetime: null,
    amount: -500,
    iso_currency_code: "USD",
    merchant_entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
    check_number: null,
    transaction_id: "Lm9wRk7rPbFAEJXVwo8ECkG9Jrv8ldIqbLnXg",
    personal_finance_category: {
      primary: "TRAVEL",
      detailed: "TRAVEL_FLIGHTS",
      confidence_level: "VERY_HIGH",
    },
    category: ["Travel", "Airlines and Aviation Services"],
    name: "United Airlines",
    transaction_code: null,
    pending: false,
    payment_channel: "in store",
    datetime: null,
    logo_url: "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
    date: "2024-12-18",
    category_id: "22001000",
    payment_meta: {
      reason: null,
      payer: null,
      payment_method: null,
      ppd_id: null,
      by_order_of: null,
      reference_number: null,
      payee: null,
      payment_processor: null,
    },
    unofficial_currency_code: null,
    merchant_name: "United Airlines",
  },
  {
    amount: 5.4,
    location: {
      lon: null,
      postal_code: null,
      region: null,
      address: null,
      lat: null,
      city: null,
      country: null,
      store_number: null,
    },
    merchant_name: "Uber",
    website: "uber.com",
    pending: false,
    counterparties: [
      {
        phone_number: null,
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        confidence_level: "VERY_HIGH",
        type: "merchant",
        website: "uber.com",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        name: "Uber",
      },
    ],
    date: "2024-12-20",
    payment_channel: "online",
    transaction_id: "1zaBeRjqWwcVwG61vA4wcXQ1y3KjRrCNgQd8y",
    iso_currency_code: "USD",
    account_owner: null,
    pending_transaction_id: null,
    personal_finance_category: {
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
      confidence_level: "VERY_HIGH",
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    authorized_date: "2024-12-19",
    name: "Uber 063015 SF**POOL**",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    category: ["Travel", "Taxi"],
    transaction_code: null,
    unofficial_currency_code: null,
    payment_meta: {
      payment_processor: null,
      by_order_of: null,
      payee: null,
      payment_method: null,
      reference_number: null,
      reason: null,
      ppd_id: null,
      payer: null,
    },
    authorized_datetime: null,
    datetime: null,
    transaction_type: "special",
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    category_id: "22016000",
    check_number: null,
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
  },
  {
    transaction_type: "special",
    payment_meta: {
      payee: null,
      reason: null,
      by_order_of: null,
      payment_processor: null,
      ppd_id: null,
      payment_method: null,
      payer: null,
      reference_number: null,
    },
    website: null,
    unofficial_currency_code: null,
    account_owner: null,
    date: "2024-12-20",
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
      primary: "LOAN_PAYMENTS",
    },
    pending_transaction_id: null,
    authorized_date: "2024-12-19",
    pending: false,
    payment_channel: "other",
    counterparties: [],
    check_number: null,
    account_id: "qKQom3EGAjHGZBAlj1MZie1BKqAg5qcgrzRRr",
    merchant_entity_id: null,
    datetime: null,
    amount: 25,
    logo_url: null,
    name: "CREDIT CARD 3333 PAYMENT *//",
    merchant_name: null,
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_LOAN_PAYMENTS.png",
    transaction_id: "y4pRa7xXrbi5ma7JDo4mCPGQmlgzqKto9rRzv",
    category: ["Payment", "Credit Card"],
    transaction_code: null,
    authorized_datetime: null,
    category_id: "16001000",
    location: {
      lat: null,
      address: null,
      region: null,
      lon: null,
      postal_code: null,
      country: null,
      city: null,
      store_number: null,
    },
    iso_currency_code: "USD",
  },
  {
    check_number: null,
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    transaction_id: "4xPd3RQzZVcgjVdm7lbjfmg1KJbrn8c9egyQK",
    payment_channel: "online",
    account_owner: null,
    authorized_date: "2025-01-01",
    merchant_name: "Uber",
    transaction_code: null,
    date: "2025-01-02",
    location: {
      region: null,
      lat: null,
      city: null,
      postal_code: null,
      lon: null,
      country: null,
      address: null,
      store_number: null,
    },
    datetime: null,
    unofficial_currency_code: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    category_id: "22016000",
    iso_currency_code: "USD",
    name: "Uber 072515 SF**POOL**",
    transaction_type: "special",
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    pending: false,
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    amount: 6.33,
    category: ["Travel", "Taxi"],
    pending_transaction_id: null,
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    authorized_datetime: null,
    website: "uber.com",
    counterparties: [
      {
        type: "merchant",
        website: "uber.com",
        phone_number: null,
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        name: "Uber",
      },
    ],
    payment_meta: {
      payment_method: null,
      by_order_of: null,
      reference_number: null,
      payee: null,
      reason: null,
      ppd_id: null,
      payer: null,
      payment_processor: null,
    },
  },
  {
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_INCOME.png",
    merchant_entity_id: null,
    website: null,
    transaction_code: null,
    name: "INTRST PYMNT",
    payment_meta: {
      ppd_id: null,
      reason: null,
      payment_method: null,
      payer: null,
      reference_number: null,
      payment_processor: null,
      by_order_of: null,
      payee: null,
    },
    logo_url: null,
    category_id: "21009000",
    iso_currency_code: "USD",
    amount: -4.22,
    unofficial_currency_code: null,
    authorized_date: "2025-01-14",
    transaction_id: "rrZoLxlGw5c5ekDWXwBeCbEQ68848WipeXedm",
    pending: false,
    authorized_datetime: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "INCOME_WAGES",
      primary: "INCOME",
    },
    location: {
      lon: null,
      city: null,
      postal_code: null,
      lat: null,
      address: null,
      country: null,
      store_number: null,
      region: null,
    },
    date: "2025-01-14",
    transaction_type: "special",
    account_id: "qKQom3EGAjHGZBAlj1MZie1BKqAg5qcgrzRRr",
    check_number: null,
    merchant_name: null,
    category: ["Transfer", "Payroll"],
    counterparties: [],
    payment_channel: "other",
    pending_transaction_id: null,
    account_owner: null,
    datetime: null,
  },
  {
    personal_finance_category: {
      detailed: "GENERAL_MERCHANDISE_OTHER_GENERAL_MERCHANDISE",
      primary: "GENERAL_MERCHANDISE",
      confidence_level: "LOW",
    },
    category: ["Food and Drink", "Restaurants"],
    pending: false,
    transaction_id: "qKQom3EGAjHGZBAlj1MZie1EAdd8dviLz5z6L",
    location: {
      region: null,
      address: null,
      city: null,
      lat: null,
      store_number: null,
      country: null,
      lon: null,
      postal_code: null,
    },
    merchant_name: "FUN",
    category_id: "13005000",
    transaction_type: "place",
    iso_currency_code: "USD",
    unofficial_currency_code: null,
    merchant_entity_id: null,
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    authorized_datetime: null,
    logo_url: null,
    authorized_date: "2025-01-14",
    payment_channel: "in store",
    check_number: null,
    transaction_code: null,
    counterparties: [
      {
        type: "merchant",
        entity_id: null,
        website: null,
        logo_url: null,
        phone_number: null,
        confidence_level: "LOW",
        name: "FUN",
      },
    ],
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_GENERAL_MERCHANDISE.png",
    amount: 89.4,
    website: null,
    payment_meta: {
      payment_method: null,
      reference_number: null,
      payment_processor: null,
      payer: null,
      ppd_id: null,
      reason: null,
      by_order_of: null,
      payee: null,
    },
    name: "SparkFun",
    pending_transaction_id: null,
    account_owner: null,
    date: "2025-01-15",
    datetime: null,
  },
  {
    check_number: null,
    pending: false,
    transaction_type: "place",
    name: "McDonald's",
    pending_transaction_id: null,
    unofficial_currency_code: null,
    amount: 12,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        name: "McDonald's",
        type: "merchant",
        phone_number: null,
        entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
        logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
        website: "mcdonalds.com",
      },
    ],
    payment_channel: "in store",
    personal_finance_category: {
      primary: "FOOD_AND_DRINK",
      detailed: "FOOD_AND_DRINK_FAST_FOOD",
      confidence_level: "VERY_HIGH",
    },
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    location: {
      address: null,
      lon: null,
      country: null,
      store_number: "3322",
      lat: null,
      city: null,
      postal_code: null,
      region: null,
    },
    transaction_id: "kn4oel16Amf4gBdjLy3giQl98KKnKLCql3lJ8",
    category_id: "13005032",
    merchant_entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
    logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
    category: ["Food and Drink", "Restaurants", "Fast Food"],
    website: "mcdonalds.com",
    datetime: null,
    iso_currency_code: "USD",
    date: "2025-01-16",
    authorized_datetime: null,
    authorized_date: "2025-01-16",
    merchant_name: "McDonald's",
    transaction_code: null,
    payment_meta: {
      payment_processor: null,
      ppd_id: null,
      payment_method: null,
      reason: null,
      payer: null,
      by_order_of: null,
      reference_number: null,
      payee: null,
    },
    account_owner: null,
  },
  {
    date: "2025-01-16",
    name: "Starbucks",
    amount: 4.33,
    website: "starbucks.com",
    authorized_date: "2025-01-16",
    logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
    transaction_code: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_COFFEE",
      primary: "FOOD_AND_DRINK",
    },
    datetime: null,
    merchant_entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
    iso_currency_code: "USD",
    payment_channel: "in store",
    category: ["Food and Drink", "Restaurants", "Coffee Shop"],
    location: {
      lon: null,
      store_number: null,
      lat: null,
      city: null,
      postal_code: null,
      region: null,
      country: null,
      address: null,
    },
    transaction_id: "lbNoXkLGA1CnB6gZRX3BulV4b66Q6zinV5VaJ",
    account_owner: null,
    payment_meta: {
      ppd_id: null,
      payment_method: null,
      payee: null,
      by_order_of: null,
      reference_number: null,
      payment_processor: null,
      reason: null,
      payer: null,
    },
    unofficial_currency_code: null,
    transaction_type: "place",
    category_id: "13005043",
    pending_transaction_id: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
        type: "merchant",
        name: "Starbucks",
        phone_number: null,
        entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
        website: "starbucks.com",
      },
    ],
    pending: false,
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    check_number: null,
    merchant_name: "Starbucks",
    authorized_datetime: null,
  },
  {
    payment_channel: "in store",
    iso_currency_code: "USD",
    authorized_date: "2025-01-17",
    category: ["Travel", "Airlines and Aviation Services"],
    unofficial_currency_code: null,
    transaction_code: null,
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRAVEL.png",
    merchant_name: "United Airlines",
    website: "united.com",
    authorized_datetime: null,
    date: "2025-01-17",
    personal_finance_category: {
      primary: "TRAVEL",
      detailed: "TRAVEL_FLIGHTS",
      confidence_level: "VERY_HIGH",
    },
    pending_transaction_id: null,
    account_owner: null,
    category_id: "22001000",
    datetime: null,
    merchant_entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
    transaction_id: "JnmWGpPqVbfwqLma1GkqtA95WoovoqH1DGDQo",
    amount: -500,
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    name: "United Airlines",
    logo_url: "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
    transaction_type: "special",
    check_number: null,
    pending: false,
    counterparties: [
      {
        name: "United Airlines",
        logo_url:
          "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
        confidence_level: "VERY_HIGH",
        website: "united.com",
        entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
        phone_number: null,
        type: "merchant",
      },
    ],
    location: {
      store_number: null,
      country: null,
      lon: null,
      lat: null,
      city: null,
      postal_code: null,
      region: null,
      address: null,
    },
    payment_meta: {
      ppd_id: null,
      payment_processor: null,
      by_order_of: null,
      payer: null,
      reason: null,
      payment_method: null,
      payee: null,
      reference_number: null,
    },
  },
  {
    payment_meta: {
      ppd_id: null,
      reason: null,
      payment_method: null,
      by_order_of: null,
      payer: null,
      payee: null,
      payment_processor: null,
      reference_number: null,
    },
    iso_currency_code: "USD",
    check_number: null,
    date: "2025-01-19",
    authorized_date: "2025-01-18",
    transaction_code: null,
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    transaction_id: "5djawlZB5VCypzmjXqNpCqVALJJ3JWsb1E1GG",
    amount: 5.4,
    unofficial_currency_code: null,
    category_id: "22016000",
    pending: false,
    website: "uber.com",
    account_owner: null,
    authorized_datetime: null,
    pending_transaction_id: null,
    payment_channel: "online",
    transaction_type: "special",
    counterparties: [
      {
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        type: "merchant",
        name: "Uber",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        website: "uber.com",
        confidence_level: "VERY_HIGH",
        phone_number: null,
      },
    ],
    datetime: null,
    name: "Uber 063015 SF**POOL**",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    personal_finance_category: {
      primary: "TRANSPORTATION",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      confidence_level: "VERY_HIGH",
    },
    location: {
      address: null,
      city: null,
      country: null,
      store_number: null,
      lat: null,
      postal_code: null,
      lon: null,
      region: null,
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    merchant_name: "Uber",
    category: ["Travel", "Taxi"],
  },
  {
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
      primary: "LOAN_PAYMENTS",
    },
    transaction_type: "special",
    check_number: null,
    pending_transaction_id: null,
    iso_currency_code: "USD",
    payment_meta: {
      reference_number: null,
      ppd_id: null,
      by_order_of: null,
      payment_method: null,
      payer: null,
      reason: null,
      payment_processor: null,
      payee: null,
    },
    counterparties: [],
    unofficial_currency_code: null,
    logo_url: null,
    merchant_entity_id: null,
    transaction_code: null,
    date: "2025-01-19",
    account_id: "qKQom3EGAjHGZBAlj1MZie1BKqAg5qcgrzRRr",
    pending: false,
    category: ["Payment", "Credit Card"],
    location: {
      city: null,
      country: null,
      address: null,
      store_number: null,
      postal_code: null,
      lat: null,
      region: null,
      lon: null,
    },
    merchant_name: null,
    authorized_datetime: null,
    account_owner: null,
    authorized_date: "2025-01-18",
    payment_channel: "other",
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_LOAN_PAYMENTS.png",
    datetime: null,
    amount: 25,
    category_id: "16001000",
    website: null,
    transaction_id: "KbmWeojKPVCdEX6974RETyD6mbbRblFKxjxE5",
    name: "CREDIT CARD 3333 PAYMENT *//",
  },
  {
    transaction_code: null,
    payment_channel: "online",
    iso_currency_code: "USD",
    account_id: "lbNoXkLGA1CnB6gZRX3BulV13rbyNrtpoVRRg",
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    payment_meta: {
      reason: null,
      reference_number: null,
      payee: null,
      payment_processor: null,
      payer: null,
      ppd_id: null,
      payment_method: null,
      by_order_of: null,
    },
    merchant_name: "Uber",
    transaction_type: "special",
    transaction_id: "wdo1zPBGq5CzA9Z8bejATKZpe77w7yf3babEP",
    pending_transaction_id: null,
    authorized_datetime: null,
    account_owner: null,
    pending: false,
    date: "2025-02-01",
    website: "uber.com",
    counterparties: [
      {
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        website: "uber.com",
        phone_number: null,
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        confidence_level: "VERY_HIGH",
        name: "Uber",
        type: "merchant",
      },
    ],
    datetime: null,
    authorized_date: "2025-01-31",
    category: ["Travel", "Taxi"],
    name: "Uber 072515 SF**POOL**",
    check_number: null,
    category_id: "22016000",
    amount: 6.33,
    location: {
      address: null,
      postal_code: null,
      lon: null,
      region: null,
      store_number: null,
      country: null,
      city: null,
      lat: null,
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    unofficial_currency_code: null,
    personal_finance_category: {
      primary: "TRANSPORTATION",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      confidence_level: "VERY_HIGH",
    },
  },
];
