import React, { useEffect, useState } from "react";
import { BarChart2, Calendar, Filter, Search } from "lucide-react";
import SpendingReport from "../components/reports/SpendingReport";
import IncomeExpenseReport from "../components/reports/IncomeExpenseReport";
import NetWorthReport from "../components/reports/NetWorthReport";
import InvestmentReport from "../components/reports/InvestmentReport";
import RealEstateReport from "../components/reports/RealEstateReport";
import TaxReport from "../components/reports/TaxReport";
import ReportsOverview from "../components/reports/ReportsOverview";
import { useAuthStore } from "../store/authStore";
import { getDocument } from "../utils/firebase-db";

type TimeFrame = "1M" | "3M" | "6M" | "YTD" | "1Y" | "5Y" | "ALL";
type ReportType =
  | "overview"
  | "spending"
  | "income-expense"
  | "net-worth"
  | "investments"
  | "real-estate"
  | "tax";

function ReportsPage({
  transactions,
  userAccounts,
  liabilities,
  addedStocks,
  addedBonds,
  commodities,
  userAddedInvestments,
}) {
  const [timeFrame, setTimeFrame] = useState<TimeFrame>("1M");
  const [activeReport, setActiveReport] = useState<ReportType>("overview");
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const reports = [
    { id: "overview", name: "Overview", component: ReportsOverview },
    { id: "spending", name: "Spending Analysis", component: SpendingReport },
    {
      id: "income-expense",
      name: "Income vs Expenses",
      component: IncomeExpenseReport,
    },
    { id: "net-worth", name: "Net Worth Tracker", component: NetWorthReport },
    // {
    //   id: "investments",
    //   name: "Investment Performance",
    //   component: InvestmentReport,
    // },
    // {
    //   id: "real-estate",
    //   name: "Real Estate Analysis",
    //   component: RealEstateReport,
    // },
    // { id: "tax", name: "Tax Insights", component: TaxReport },
  ];

  const timeFrames: TimeFrame[] = ["1M", "3M", "6M", "YTD", "1Y", "5Y", "ALL"];

  const ActiveReportComponent = reports.find(
    (r) => r.id === activeReport
  )?.component;

  return (
    <div className="space-y-6">
      {/* Header with Search */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-white dark:bg-gray-800 shadow-sm transition-all duration-200">
        {isSearchOpen ? (
          <div className="container mx-auto px-4 py-4">
            <div className="relative max-w-2xl mx-auto">
              <input
                type="search"
                placeholder="Search reports and insights..."
                className="w-full pl-10 pr-4 py-2 bg-gray-100 dark:bg-gray-700 border-0 rounded-lg focus:ring-2 focus:ring-primary-400 focus:bg-white dark:focus:bg-gray-600"
                autoFocus
                onBlur={() => setIsSearchOpen(false)}
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>
        ) : null}
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            Reports & Insights
          </h1>
          <BarChart2 className="h-8 w-8 text-primary-400" />
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2 bg-white dark:bg-gray-800 rounded-lg shadow p-1">
            {timeFrames.map((tf) => (
              <button
                key={tf}
                onClick={() => setTimeFrame(tf)}
                className={`px-3 py-1 text-sm font-medium rounded-md ${
                  timeFrame === tf
                    ? "bg-primary-400 text-white"
                    : "text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                }`}
              >
                {tf}
              </button>
            ))}
          </div>
          {/* <button className="flex items-center px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300 bg-white dark:bg-gray-800 rounded-lg shadow hover:bg-gray-50 dark:hover:bg-gray-700">
            <Filter className="h-4 w-4 mr-2" />
            Filter
          </button> */}
        </div>
      </div>

      {/* Tab Navigation */}
      <div className="border-b border-gray-200 dark:border-gray-700">
        <nav className="-mb-px flex space-x-8">
          {reports.map((report) => (
            <button
              key={report.id}
              onClick={() => setActiveReport(report.id as ReportType)}
              className={`
                whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm
                ${
                  activeReport === report.id
                    ? "border-primary-400 text-primary-400"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }
              `}
            >
              {report.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        {ActiveReportComponent && (
          <ActiveReportComponent
            timeFrame={timeFrame}
            transactions={transactions}
            userAccounts={userAccounts}
            liabilities={liabilities}
            addedStocks={addedStocks}
            addedBonds={addedBonds}
            commodities={commodities}
            userAddedInvestments={userAddedInvestments}
          />
        )}
      </div>
    </div>
  );
}

export default ReportsPage;
