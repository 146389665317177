import React, { useState, useEffect } from "react";
import {
  Calculator,
  DollarSign,
  Percent,
  Building2,
  Briefcase,
} from "lucide-react";

function PropertyCalculator() {
  const [purchasePrice, setPurchasePrice] = useState<string>("");
  const [downPayment, setDownPayment] = useState<string>("20");
  const [interestRate, setInterestRate] = useState<string>("6.5");
  const [loanTerm, setLoanTerm] = useState<string>("30");
  const [monthlyRent, setMonthlyRent] = useState<string>("");
  const [propertyTax, setPropertyTax] = useState<string>("1.2");
  const [insurance, setInsurance] = useState<string>("1200");
  const [maintenance, setMaintenance] = useState<string>("1");
  const [vacancy, setVacancy] = useState<string>("5");
  const [propertyManagement, setPropertyManagement] = useState<string>("8");
  const [appreciation, setAppreciation] = useState<string>("3");
  const [utilities, setUtilities] = useState<string>("0");

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleNumberInput = (
    value: string,
    setter: (value: string) => void,
    isPercentage: boolean = false
  ) => {
    const regex = isPercentage ? /^\d*\.?\d*$/ : /^[0-9]*\.?[0-9]*$/;
    if (value === "" || regex.test(value)) {
      setter(value);
    }
  };

  const calculateInvestment = () => {
    if (!purchasePrice) return null;

    // Calculate loan details
    const price = parseFloat(purchasePrice);
    const downPaymentAmount = price * (parseFloat(downPayment) / 100);
    const loanAmount = price - downPaymentAmount;
    const monthlyRate = parseFloat(interestRate) / 100 / 12;
    const numberOfPayments = parseInt(loanTerm) * 12;
    const monthlyMortgage =
      (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) /
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    // Calculate monthly expenses
    const monthlyTax = (price * (parseFloat(propertyTax) / 100)) / 12;
    const monthlyInsurance = parseFloat(insurance) / 12;
    const monthlyMaintenance = (price * (parseFloat(maintenance) / 100)) / 12;
    const monthlyVacancyReserve =
      parseFloat(monthlyRent || "0") * (parseFloat(vacancy) / 100);
    const monthlyManagementFee =
      parseFloat(monthlyRent || "0") * (parseFloat(propertyManagement) / 100);
    const monthlyUtilities = parseFloat(utilities);

    const totalMonthlyExpenses =
      monthlyMortgage +
      monthlyTax +
      monthlyInsurance +
      monthlyMaintenance +
      monthlyVacancyReserve +
      monthlyManagementFee +
      monthlyUtilities;

    // Calculate cash flow
    const monthlyCashFlow =
      parseFloat(monthlyRent || "0") - totalMonthlyExpenses;
    const annualCashFlow = monthlyCashFlow * 12;

    // Calculate returns
    const totalInvestment = downPaymentAmount + price * 0.03; // Including closing costs
    const cashOnCashReturn = (annualCashFlow / totalInvestment) * 100;

    // Calculate 5-year projections
    const fiveYearProjection = Array.from({ length: 5 }, (_, year) => {
      const propertyValue =
        price * Math.pow(1 + parseFloat(appreciation) / 100, year + 1);
      const remainingLoanBalance = calculateRemainingLoan(
        loanAmount,
        monthlyRate,
        numberOfPayments,
        (year + 1) * 12
      );
      const equity = propertyValue - remainingLoanBalance;
      const yearlyRent =
        parseFloat(monthlyRent || "0") * 12 * Math.pow(1.02, year); // Assuming 2% annual rent increase
      return {
        year: year + 1,
        propertyValue,
        equity,
        yearlyRent,
      };
    });

    return {
      monthlyMortgage,
      totalMonthlyExpenses,
      monthlyCashFlow,
      annualCashFlow,
      cashOnCashReturn,
      totalInvestment,
      fiveYearProjection,
      expenses: {
        mortgage: monthlyMortgage,
        tax: monthlyTax,
        insurance: monthlyInsurance,
        maintenance: monthlyMaintenance,
        vacancy: monthlyVacancyReserve,
        management: monthlyManagementFee,
        utilities: monthlyUtilities,
      },
    };
  };

  const calculateRemainingLoan = (
    principal: number,
    monthlyRate: number,
    totalPayments: number,
    paymentsMade: number
  ) => {
    return (
      (principal *
        (Math.pow(1 + monthlyRate, totalPayments) -
          Math.pow(1 + monthlyRate, paymentsMade))) /
      (Math.pow(1 + monthlyRate, totalPayments) - 1)
    );
  };

  const results = purchasePrice ? calculateInvestment() : null;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Purchase Price
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={purchasePrice}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setPurchasePrice)
                }
                onBlur={() => {
                  if (purchasePrice) {
                    setPurchasePrice(parseFloat(purchasePrice).toFixed(2));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter purchase price"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Down Payment (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={downPayment}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setDownPayment, true)
                  }
                  onBlur={() => {
                    if (downPayment) {
                      setDownPayment(parseFloat(downPayment).toFixed(1));
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter down payment"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Interest Rate (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={interestRate}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setInterestRate, true)
                  }
                  onBlur={() => {
                    if (interestRate) {
                      setInterestRate(parseFloat(interestRate).toFixed(2));
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter interest rate"
                />
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Current average: ~7.1%
              </p>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Monthly Rental Income
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={monthlyRent}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setMonthlyRent)
                }
                onBlur={() => {
                  if (monthlyRent) {
                    setMonthlyRent(parseFloat(monthlyRent).toFixed(2));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter monthly rent"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Property Tax Rate (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={propertyTax}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setPropertyTax, true)
                  }
                  onBlur={() => {
                    if (propertyTax) {
                      setPropertyTax(parseFloat(propertyTax).toFixed(2));
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter property tax rate"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Annual Insurance
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={insurance}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setInsurance)
                  }
                  onBlur={() => {
                    if (insurance) {
                      setInsurance(parseFloat(insurance).toFixed(2));
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter annual insurance"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Maintenance (% of Value)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={maintenance}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setMaintenance, true)
                  }
                  onBlur={() => {
                    if (maintenance) {
                      setMaintenance(parseFloat(maintenance).toFixed(2));
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter maintenance percentage"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Monthly Utilities
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={utilities}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setUtilities)
                  }
                  onBlur={() => {
                    if (utilities) {
                      setUtilities(parseFloat(utilities).toFixed(2));
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter monthly utilities"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Vacancy Rate (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={vacancy}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setVacancy, true)
                  }
                  onBlur={() => {
                    if (vacancy) {
                      setVacancy(parseFloat(vacancy).toFixed(1));
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter vacancy rate"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Property Management (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={propertyManagement}
                  onChange={(e) =>
                    handleNumberInput(
                      e.target.value,
                      setPropertyManagement,
                      true
                    )
                  }
                  onBlur={() => {
                    if (propertyManagement) {
                      setPropertyManagement(
                        parseFloat(propertyManagement).toFixed(1)
                      );
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter management fee"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
          <h3 className="text-lg font-medium mb-6">Investment Analysis</h3>

          {results ? (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                    Monthly Cash Flow
                  </p>
                  <p
                    className={`text-2xl font-bold ${
                      results.monthlyCashFlow >= 0
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {formatCurrency(results.monthlyCashFlow)}
                  </p>
                </div>

                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                    Cash on Cash Return
                  </p>
                  <p
                    className={`text-2xl font-bold ${
                      results.cashOnCashReturn >= 0
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {results.cashOnCashReturn.toFixed(2)}%
                  </p>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Monthly Expenses Breakdown
                </h4>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Mortgage</span>
                    <span className="font-medium">
                      {formatCurrency(results.expenses.mortgage)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Property Tax</span>
                    <span className="font-medium">
                      {formatCurrency(results.expenses.tax)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Insurance</span>
                    <span className="font-medium">
                      {formatCurrency(results.expenses.insurance)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Maintenance</span>
                    <span className="font-medium">
                      {formatCurrency(results.expenses.maintenance)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Vacancy Reserve</span>
                    <span className="font-medium">
                      {formatCurrency(results.expenses.vacancy)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Property Management</span>
                    <span className="font-medium">
                      {formatCurrency(results.expenses.management)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Utilities</span>
                    <span className="font-medium">
                      {formatCurrency(results.expenses.utilities)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm font-medium pt-2 border-t border-gray-200 dark:border-gray-600">
                    <span>Total Monthly Expenses</span>
                    <span>{formatCurrency(results.totalMonthlyExpenses)}</span>
                  </div>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  5-Year Projections
                </h4>
                <div className="space-y-3">
                  {results.fiveYearProjection.map((year) => (
                    <div
                      key={year.year}
                      className="bg-white dark:bg-gray-800 rounded-lg p-3"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-medium">
                          Year {year.year}
                        </span>
                        <span className="text-sm text-gray-500">
                          Property Value: {formatCurrency(year.propertyValue)}
                        </span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500">Equity</span>
                        <span className="font-medium">
                          {formatCurrency(year.equity)}
                        </span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500">Annual Rent</span>
                        <span className="font-medium">
                          {formatCurrency(year.yearlyRent)}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {results.monthlyCashFlow < 0 && (
                <div className="p-4 bg-red-50 dark:bg-red-900/20 rounded-lg">
                  <p className="text-sm text-red-800 dark:text-red-200">
                    Warning: This property has negative cash flow. Consider:
                    <ul className="list-disc ml-4 mt-2 space-y-1">
                      <li>Increasing the down payment</li>
                      <li>Finding ways to reduce expenses</li>
                      <li>Negotiating a lower purchase price</li>
                      <li>Looking for properties with higher rental income</li>
                    </ul>
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center py-8">
              <Calculator className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500 dark:text-gray-400">
                Enter property details to see investment analysis
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PropertyCalculator;
