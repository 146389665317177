import { useState, useEffect } from "react";
import { Link as LinkIcon } from "lucide-react";
import { useBankStore } from "../../store/bankStore";
import AccountCard from "./AccountCard";
import AddBankAccountModal from "./AddBankAccountModal";
import LinkBankButton from "./LinkBankButton";

function BankAccountsSection() {
  const { accounts, error, removeAccount } = useBankStore();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  useEffect(() => {
    const handleOpenModal = () => setIsAddModalOpen(true);
    document.addEventListener("openAddBankModal", handleOpenModal);
    return () =>
      document.removeEventListener("openAddBankModal", handleOpenModal);
  }, []);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
          Linked Bank Accounts
        </h3>
        <LinkBankButton />
      </div>

      {error && (
        <div className="mb-4 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
          {error}
        </div>
      )}

      <div className="space-y-4">
        {accounts.length === 0 ? (
          <div className="text-center py-12">
            <LinkIcon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">
              No accounts
            </h3>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Get started by linking your bank account or adding one manually.
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <AccountCard onDelete={removeAccount} />
          </div>
        )}
      </div>

      <AddBankAccountModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
    </div>
  );
}

export default BankAccountsSection;
