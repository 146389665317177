import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { X, DollarSign, Percent, Calendar, Building2 } from "lucide-react";

const bondSchema = z.object({
  name: z.string().min(2, "Bond name is required"),
  type: z.enum(["Government", "Corporate", "Municipal"]),
  quantity: z.number().min(1, "Quantity must be at least 1"),
  faceValue: z.number().min(0.01, "Face value must be greater than 0"),
  purchasePrice: z.number().min(0.01, "Purchase price must be greater than 0"),
  couponRate: z.number().min(0, "Coupon rate must be 0 or greater"),
  maturityDate: z.string().min(1, "Maturity date is required"),
  couponFrequency: z.enum(["Annual", "Semi-Annual", "Quarterly"]),
  notes: z.string().optional(),
  security_id: z.string().optional(),
});

type BondFormData = z.infer<typeof bondSchema>;

interface AddBondModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (data: BondFormData) => void;
  editingBond?: any;
  onEdit?: (id: string, data: BondFormData) => void;
}

function AddBondModal({
  isOpen,
  onClose,
  onAdd,
  editingBond,
  onEdit,
}: AddBondModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<BondFormData>({
    resolver: zodResolver(bondSchema),
  });

  // Set form values when editingBond changes
  useEffect(() => {
    if (editingBond) {
      // Set each field individually to ensure proper type conversion
      setValue("name", editingBond.name);
      setValue("type", editingBond.type);
      setValue("quantity", Number(editingBond.quantity));
      setValue("faceValue", Number(editingBond.faceValue));
      setValue("purchasePrice", Number(editingBond.cost_basis));
      setValue("couponRate", Number(editingBond.couponRate));
      setValue(
        "maturityDate",
        editingBond.maturityDate
          ? new Date(editingBond.maturityDate).toISOString().split("T")[0]
          : new Date().toISOString().split("T")[0]
      );
      setValue("couponFrequency", editingBond.couponFrequency || "Annual");
      setValue("notes", editingBond.notes || "");
      setValue("security_id", editingBond.id);
    } else {
      reset(); // Clear form when not editing
    }
  }, [editingBond, setValue, reset]);

  const onSubmit = async (data: BondFormData) => {
    try {
      if (editingBond && editingBond.id) {
        await onEdit?.(editingBond.id, data);
      } else {
        await onAdd(data);
      }
      reset();
      onClose();
    } catch (error) {
      console.error("Failed to handle bond:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div
          className="relative w-full max-w-md bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                <Building2 className="h-6 w-6 text-primary-400" />
              </div>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {editingBond ? "Edit Bond" : "Add Bond"}
              </h2>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Bond Name
              </label>
              <input
                type="text"
                {...register("name")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="e.g., US Treasury Note 2026"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Bond Type
                </label>
                <select
                  {...register("type")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="Government">Government</option>
                  <option value="Corporate">Corporate</option>
                  <option value="Municipal">Municipal</option>
                </select>
                {errors.type && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.type.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Quantity
                </label>
                <input
                  type="number"
                  {...register("quantity", { valueAsNumber: true })}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="Number of bonds"
                />
                {errors.quantity && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.quantity.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Face Value
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    {...register("faceValue", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                    placeholder="0.00"
                  />
                </div>
                {errors.faceValue && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.faceValue.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Purchase Price
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    {...register("purchasePrice", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                    placeholder="0.00"
                  />
                </div>
                {errors.purchasePrice && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.purchasePrice.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Coupon Rate (%)
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Percent className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    {...register("couponRate", { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                    placeholder="0.00"
                  />
                </div>
                {errors.couponRate && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.couponRate.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Coupon Frequency
                </label>
                <select
                  {...register("couponFrequency")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="Annual">Annual</option>
                  <option value="Semi-Annual">Semi-Annual</option>
                  <option value="Quarterly">Quarterly</option>
                </select>
                {errors.couponFrequency && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.couponFrequency.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Maturity Date
              </label>
              <div className="relative mt-1">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <Calendar className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="date"
                  {...register("maturityDate")}
                  className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
              </div>
              {errors.maturityDate && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.maturityDate.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Notes (Optional)
              </label>
              <textarea
                {...register("notes")}
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="Add any notes about this bond..."
              />
            </div>

            {/* Hidden field for security_id */}
            <input type="hidden" {...register("security_id")} />

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md disabled:opacity-50"
              >
                {isSubmitting
                  ? editingBond
                    ? "Updating..."
                    : "Adding..."
                  : editingBond
                  ? "Update Bond"
                  : "Add Bond"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBondModal;
