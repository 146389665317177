import { useEffect, useState } from "react";
import { differenceInDays } from "date-fns";
import { Target, Clock, ArrowRight, Edit2, Trash2, Plus } from "lucide-react";
import { SavingsGoal } from "../../types/budget";
import EditSavingsGoalModal from "./EditSavingsGoalModal";
import AddSavingsGoalModal from "./AddSavingsGoalModal";
import { deleteDocument, getDocument } from "../../utils/firebase-db";
import { useAuthStore } from "../../store/authStore";
import { useNotificationStore } from "../../store/notificationStore";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";

function SavingsGoalsList({ goals }) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingGoal, setEditingGoal] = useState<SavingsGoal | null>(null);
  const { user } = useAuthStore();
  const { addNotification } = useNotificationStore();

  const handleEdit = (goal: SavingsGoal) => {
    setEditingGoal(goal);
  };

  const handleDelete = async (goalId: string) => {
    try {
      const ref = doc(db, "savingGoals", user.id, "goals", goalId);
      await deleteDoc(ref);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const userNotifications = await getDocument(
          "notificationSettings",
          user.id
        );

        if (userNotifications.notifications.push.goalAlerts) {
          goals?.forEach((goal) => {
            if (goal.progress >= 80) {
              addNotification({
                title: "Savings Goal Near Completion",
                message: `Your savings goal '${goal.name}' is ${goal.progress}% complete! Keep going!`,
                type: "success",
              });
            }
          });
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [goals, addNotification, user]);

  if (goals.length === 0) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Savings Goals
          </h2>
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Goal
          </button>
        </div>
        <div className="text-center py-12">
          <Target className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <p className="text-gray-500 dark:text-gray-400 mb-4">
            No savings goals yet
          </p>
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-primary-400 hover:text-primary-500"
          >
            <Plus className="h-5 w-5 mr-2" />
            Create your first goal
          </button>
        </div>
        <AddSavingsGoalModal
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
        />
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
          Savings Goals
        </h2>
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Goal
        </button>
      </div>

      <div className="space-y-6">
        {goals
          ?.filter((goal) => goal.userId === user.id)
          ?.map((goal) => {
            const daysRemaining = differenceInDays(
              new Date(goal.targetDate),
              new Date()
            );

            return (
              <div key={goal.id} className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                      <Target className="h-5 w-5 text-primary-400" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900 dark:text-white">
                        {goal.name}
                      </h3>
                      <p className="text-sm text-gray-500 capitalize">
                        {goal.type.replace("_", " ")}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleEdit(goal)}
                      className="p-2 text-gray-400 hover:text-primary-400 rounded-lg transition-colors"
                    >
                      <Edit2 className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(goal.id)}
                      className="p-2 text-gray-400 hover:text-red-500 rounded-lg transition-colors"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                <div>
                  <div className="flex justify-between mb-2">
                    <span className="text-sm text-gray-500">Progress</span>
                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                      ${goal.currentAmount.toLocaleString()} of $
                      {goal.targetAmount.toLocaleString()}
                    </span>
                  </div>
                  <div className="relative w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                    <div
                      className="absolute top-0 left-0 h-full bg-primary-400 rounded-full transition-all duration-500"
                      style={{ width: `${goal.progress}%` }}
                    />
                  </div>
                  <p className="text-right text-xs text-gray-500 mt-1">
                    {goal.progress}% complete
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    <div className="flex items-center space-x-2 mb-1">
                      <Clock className="h-4 w-4 text-gray-400" />
                      <span className="text-sm text-gray-500">
                        Time Remaining
                      </span>
                    </div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {daysRemaining} days
                    </p>
                  </div>
                  <div className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    <div className="flex items-center space-x-2 mb-1">
                      <ArrowRight className="h-4 w-4 text-gray-400" />
                      <span className="text-sm text-gray-500">
                        Next Contribution
                      </span>
                    </div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      ${goal.contributions.amount}{" "}
                      {goal.contributions.frequency}
                    </p>
                  </div>
                </div>

                {/* {goal.automate && goal.contributions.nextContribution && (
                <div className="flex items-center justify-between p-3 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                  <span className="text-sm text-primary-600 dark:text-primary-400">
                    Automated savings enabled
                  </span>
                  <span className="text-sm font-medium text-primary-600 dark:text-primary-400">
                    Next:{" "}
                    {format(
                      new Date(goal.contributions.nextContribution),
                      "MMM d"
                    )}
                  </span>
                </div>
              )} */}
              </div>
            );
          })}
      </div>

      <AddSavingsGoalModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
      {editingGoal && (
        <EditSavingsGoalModal
          isOpen={true}
          onClose={() => setEditingGoal(null)}
          goal={editingGoal}
        />
      )}
    </div>
  );
}

export default SavingsGoalsList;
