import React, { useState, useRef, useEffect } from "react";
import {
  Bell,
  Search,
  Settings,
  LogOut,
  User,
  CreditCard,
  HelpCircle,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import ThemeToggle from "./ThemeToggle";
import LanguageSelector from "./LanguageSelector";
import { useAuthStore } from "../../store/authStore";
import { useNotificationStore } from "../../store/notificationStore";
import { useSearchStore } from "../../store/searchStore";
import { User as UserType } from "../../types/user";
import NotificationPanel from "../notifications/NotificationPanel";
import SearchResults from "../search/SearchResults";

interface TopBarProps {
  userOverride?: UserType;
}

function TopBar({ userOverride }: TopBarProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const profileRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { user: authUser, logout } = useAuthStore();
  const { unreadCount } = useNotificationStore();
  const { query, results, setQuery, clearSearch } = useSearchStore();
  const user = userOverride || authUser;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        profileRef.current &&
        !profileRef.current.contains(event.target as Node)
      ) {
        setIsProfileOpen(false);
      }
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target as Node)
      ) {
        setIsNotificationsOpen(false);
      }
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsSearchOpen(false);
        clearSearch();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [clearSearch]);

  useEffect(() => {
    if (isSearchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchOpen]);

  const handleLogout = () => {
    logout();
    setIsProfileOpen(false);
    navigate("/login");
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
    clearSearch();
  };

  return (
    <div className="sticky top-0 z-30 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 transition-colors duration-200">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex-1 flex items-center justify-end">
            <div className="flex items-center gap-4">
              <div className="relative flex items-center" ref={searchRef}>
                <div
                  className={`flex items-center transition-all duration-300 ${
                    isSearchOpen ? "w-64 sm:w-96" : "w-10"
                  }`}
                >
                  <button
                    onClick={() => setIsSearchOpen(!isSearchOpen)}
                    className={`p-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 ${
                      isSearchOpen ? "absolute left-3 z-10" : ""
                    }`}
                  >
                    <Search className="h-5 w-5" />
                  </button>
                  <input
                    ref={searchInputRef}
                    type="search"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search..."
                    className={`
                      w-full pl-10 pr-4 py-2 bg-gray-50 dark:bg-gray-700 
                      border border-gray-300 dark:border-gray-600 rounded-lg 
                      text-gray-900 dark:text-white outline-none
                      focus:ring-1 focus:ring-primary-400 focus:border-primary-400
                      transition-all duration-300 origin-right
                      ${
                        isSearchOpen
                          ? "opacity-100 scale-x-100"
                          : "opacity-0 scale-x-0 w-0 p-0 border-0"
                      }
                    `}
                  />
                </div>
                {isSearchOpen && query && (
                  <div className="absolute top-full right-0 mt-2 w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg py-2">
                    <SearchResults
                      results={results}
                      onResultClick={handleSearchClose}
                    />
                  </div>
                )}
              </div>

              {/* <LanguageSelector /> */}
              <ThemeToggle />

              <div className="relative" ref={notificationsRef}>
                <button
                  onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                  className="p-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 relative"
                >
                  <Bell className="h-5 w-5" />
                  {unreadCount > 0 && (
                    <span className="absolute -top-1 -right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform bg-red-500 rounded-full">
                      {unreadCount}
                    </span>
                  )}
                </button>
                {isNotificationsOpen && (
                  <NotificationPanel
                    onClose={() => setIsNotificationsOpen(false)}
                  />
                )}
              </div>

              <div className="relative" ref={profileRef}>
                <button
                  onClick={() => setIsProfileOpen(!isProfileOpen)}
                  className="flex items-center"
                >
                  <img
                    className="h-8 w-8 rounded-full ring-2 ring-primary-400 transition-all duration-200 hover:ring-4"
                    src={user?.avatar}
                    alt={user?.name}
                  />
                </button>

                {isProfileOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg py-1 ring-1 ring-black ring-opacity-5 z-50">
                    <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
                      <p className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
                        {user?.name}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
                        {user?.email}
                      </p>
                    </div>

                    <div className="py-1">
                      <button
                        onClick={() => {
                          navigate("/profile");
                          setIsProfileOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <User className="h-4 w-4 mr-3" />
                        Profile
                      </button>
                      <button
                        onClick={() => {
                          navigate("/billing");
                          setIsProfileOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <CreditCard className="h-4 w-4 mr-3" />
                        Billing
                      </button>
                      <button
                        onClick={() => {
                          navigate("/settings");
                          setIsProfileOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <Settings className="h-4 w-4 mr-3" />
                        Settings
                      </button>
                      <button
                        onClick={() => {
                          navigate("/help");
                          setIsProfileOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <HelpCircle className="h-4 w-4 mr-3" />
                        Help Center
                      </button>
                    </div>

                    <div className="border-t border-gray-200 dark:border-gray-700">
                      <button
                        onClick={handleLogout}
                        className="flex items-center w-full px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <LogOut className="h-4 w-4 mr-3" />
                        Sign out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
