import { create } from "zustand";
import { persist } from "zustand/middleware";
import { SettingsState } from "../types/settings";
import { auth, db } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const initialState = {
  notifications: {
    push: {
      transactionAlerts: true,
      budgetAlerts: true,
      investmentAlerts: true,
      goalAlerts: true,
      marketAlerts: true,
      mentorshipAlerts: true,
      courseAlerts: true,
      communityAlerts: true,
      securityAlerts: true,
    },
  },
  security: {
    twoFactorEnabled: false,
    lastPasswordChange: new Date().toISOString(),
    loginHistory: [
      {
        date: new Date().toISOString(),
        device: "Chrome on MacOS",
        location: "San Francisco, CA",
        status: "success" as const,
      },
    ],
  },
  preferences: {
    language: "en" as const,
    currency: "USD" as const,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    defaultDashboard: "overview" as const,
    plaidSync: {
      autoSync: true,
      syncFrequency: "daily" as const,
    },
  },
};

export const useSettingsStore = create(
  persist<SettingsState>(
    (set) => ({
      ...initialState,
      isLoading: false,
      error: null,

      fetchSettings: async () => {
        set({ isLoading: true });
        try {
          const user = auth.currentUser;
          if (!user) throw new Error("User not authenticated");

          const docRef = doc(db, "notificationSettings", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            set({
              notifications: data.notifications || initialState.notifications,
              security: data.security || initialState.security,
              preferences: data.preferences || initialState.preferences,
            });
          }
        } catch (error) {
          console.error("Error fetching settings:", error);
          set({ error: error.message });
        } finally {
          set({ isLoading: false });
        }
      },

      updateNotifications: async (settings) => {
        try {
          const user = auth.currentUser;
          if (!user) throw new Error("User not authenticated");

          const docRef = doc(db, "notificationSettings", user.uid);
          await setDoc(docRef, { notifications: settings }, { merge: true });

          set((state) => ({
            notifications: {
              push: {
                ...state.notifications.push,
                ...settings.push,
              },
            },
          }));
        } catch (error) {
          console.error("Error updating notifications:", error);
          throw error;
        }
      },

      updateSecurity: async (settings) => {
        try {
          const user = auth.currentUser;
          if (!user) throw new Error("User not authenticated");

          const docRef = doc(db, "notificationSettings", user.uid);
          await setDoc(docRef, { security: settings }, { merge: true });

          set((state) => ({
            security: {
              ...state.security,
              ...settings,
            },
          }));
        } catch (error) {
          console.error("Error updating security settings:", error);
          throw error;
        }
      },

      updatePreferences: async (settings) => {
        try {
          const user = auth.currentUser;
          if (!user) throw new Error("User not authenticated");

          const docRef = doc(db, "notificationSettings", user.uid);
          await setDoc(docRef, { preferences: settings }, { merge: true });

          set((state) => ({
            preferences: {
              ...state.preferences,
              ...settings,
              plaidSync: {
                ...state.preferences.plaidSync,
                ...(settings.plaidSync || {}),
              },
            },
          }));
        } catch (error) {
          console.error("Error updating preferences:", error);
          throw error;
        }
      },
    }),
    {
      name: "settings-storage",
      partialize: (state) => ({
        notifications: state.notifications,
        security: state.security,
        preferences: state.preferences,
      }),
    }
  )
);
