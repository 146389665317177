import React, { useState } from "react";
import {
  Calculator,
  DollarSign,
  Percent,
  FileText,
  Building2,
  Gift,
  Briefcase,
  GraduationCap,
  Heart,
} from "lucide-react";

function TaxPlanner() {
  const [income, setIncome] = useState<string>("");
  const [filingStatus, setFilingStatus] = useState<string>("single");
  const [deductions, setDeductions] = useState<{
    mortgage?: string;
    charitable?: string;
    medical?: string;
    retirement?: string;
    studentLoan?: string;
    other?: string;
  }>({});

  // 2024 Tax Brackets
  const taxBrackets = {
    single: [
      { rate: 0.1, limit: 11600 },
      { rate: 0.12, limit: 47150 },
      { rate: 0.22, limit: 100525 },
      { rate: 0.24, limit: 191950 },
      { rate: 0.32, limit: 243725 },
      { rate: 0.35, limit: 609350 },
      { rate: 0.37, limit: Infinity },
    ],
    married: [
      { rate: 0.1, limit: 23200 },
      { rate: 0.12, limit: 94300 },
      { rate: 0.22, limit: 201050 },
      { rate: 0.24, limit: 383900 },
      { rate: 0.32, limit: 487450 },
      { rate: 0.35, limit: 731200 },
      { rate: 0.37, limit: Infinity },
    ],
  };

  const standardDeduction = {
    single: 14600,
    married: 29200,
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleNumberInput = (
    value: string,
    setter: (value: string) => void,
    isPercentage: boolean = false
  ) => {
    const regex = isPercentage ? /^\d*\.?\d*$/ : /^[0-9]*\.?[0-9]*$/;
    if (value === "" || regex.test(value)) {
      setter(value);
    }
  };

  const calculateTax = () => {
    const brackets = taxBrackets[filingStatus as keyof typeof taxBrackets];
    let remainingIncome = parseFloat(income || "0");
    let totalTax = 0;
    let previousLimit = 0;

    const totalDeductions = Object.values(deductions).reduce(
      (sum, val) => sum + parseFloat(val || "0"),
      0
    );
    const standardDed =
      standardDeduction[filingStatus as keyof typeof standardDeduction];
    const useItemized = totalDeductions > standardDed;
    const taxableIncome = Math.max(
      0,
      parseFloat(income || "0") - (useItemized ? totalDeductions : standardDed)
    );
    remainingIncome = taxableIncome;

    // Calculate tax bracket by bracket
    const bracketBreakdown = [];
    for (const bracket of brackets) {
      const taxableAmount = Math.min(
        Math.max(0, remainingIncome),
        bracket.limit - previousLimit
      );
      const bracketTax = taxableAmount * bracket.rate;
      totalTax += bracketTax;

      if (taxableAmount > 0) {
        bracketBreakdown.push({
          rate: bracket.rate * 100,
          amount: taxableAmount,
          tax: bracketTax,
        });
      }

      remainingIncome -= taxableAmount;
      previousLimit = bracket.limit;
      if (remainingIncome <= 0) break;
    }

    const effectiveRate = (totalTax / parseFloat(income || "1")) * 100;
    const marginRate = brackets.find((b) => b.limit > taxableIncome)?.rate || 0;

    return {
      totalTax,
      effectiveRate,
      marginRate: marginRate * 100,
      taxableIncome,
      takeHome: parseFloat(income || "0") - totalTax,
      deductionSummary: {
        itemized: totalDeductions,
        standard: standardDed,
        actual: useItemized ? totalDeductions : standardDed,
        usingItemized: useItemized,
      },
      bracketBreakdown,
    };
  };

  const results = income ? calculateTax() : null;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Annual Income
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={income}
                onChange={(e) => handleNumberInput(e.target.value, setIncome)}
                onBlur={() => {
                  if (income) {
                    setIncome(parseFloat(income).toFixed(2));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter annual income"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Filing Status
            </label>
            <select
              value={filingStatus}
              onChange={(e) => setFilingStatus(e.target.value)}
              className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
            >
              <option value="single">Single</option>
              <option value="married">Married Filing Jointly</option>
            </select>
          </div>

          <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
            <h3 className="text-lg font-medium mb-4">Deductions</h3>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Mortgage Interest
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Building2 className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    inputMode="decimal"
                    value={deductions.mortgage || ""}
                    onChange={(e) => {
                      handleNumberInput(e.target.value, (value) =>
                        setDeductions((prev) => ({ ...prev, mortgage: value }))
                      );
                    }}
                    onBlur={() => {
                      if (deductions.mortgage) {
                        setDeductions((prev) => ({
                          ...prev,
                          mortgage: parseFloat(deductions.mortgage!).toFixed(2),
                        }));
                      }
                    }}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter mortgage interest"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Charitable Contributions
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Heart className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    inputMode="decimal"
                    value={deductions.charitable || ""}
                    onChange={(e) => {
                      handleNumberInput(e.target.value, (value) =>
                        setDeductions((prev) => ({
                          ...prev,
                          charitable: value,
                        }))
                      );
                    }}
                    onBlur={() => {
                      if (deductions.charitable) {
                        setDeductions((prev) => ({
                          ...prev,
                          charitable: parseFloat(
                            deductions.charitable!
                          ).toFixed(2),
                        }));
                      }
                    }}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter charitable contributions"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Student Loan Interest
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <GraduationCap className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    inputMode="decimal"
                    value={deductions.studentLoan || ""}
                    onChange={(e) => {
                      handleNumberInput(e.target.value, (value) =>
                        setDeductions((prev) => ({
                          ...prev,
                          studentLoan: value,
                        }))
                      );
                    }}
                    onBlur={() => {
                      if (deductions.studentLoan) {
                        setDeductions((prev) => ({
                          ...prev,
                          studentLoan: parseFloat(
                            deductions.studentLoan!
                          ).toFixed(2),
                        }));
                      }
                    }}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter student loan interest"
                  />
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  Maximum deduction: $2,500
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Retirement Contributions
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Briefcase className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    inputMode="decimal"
                    value={deductions.retirement || ""}
                    onChange={(e) => {
                      handleNumberInput(e.target.value, (value) =>
                        setDeductions((prev) => ({
                          ...prev,
                          retirement: value,
                        }))
                      );
                    }}
                    onBlur={() => {
                      if (deductions.retirement) {
                        setDeductions((prev) => ({
                          ...prev,
                          retirement: parseFloat(
                            deductions.retirement!
                          ).toFixed(2),
                        }));
                      }
                    }}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter retirement contributions"
                  />
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  2024 limits: $23,000 (401k), $7,000 (IRA)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
          <h3 className="text-lg font-medium mb-6">Tax Summary</h3>

          {results ? (
            <div className="space-y-6">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Taxable Income
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {formatCurrency(results.taxableIncome)}
                </p>
                <div className="mt-2 text-sm">
                  <div className="flex justify-between">
                    <span className="text-gray-500">Gross Income:</span>
                    <span className="font-medium">
                      {formatCurrency(parseFloat(income))}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-500">
                      {results.deductionSummary.usingItemized
                        ? "Itemized"
                        : "Standard"}{" "}
                      Deduction:
                    </span>
                    <span className="font-medium text-green-500">
                      -{formatCurrency(results.deductionSummary.actual)}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Total Tax
                </p>
                <p className="text-2xl font-bold text-red-500">
                  {formatCurrency(results.totalTax)}
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Take-Home Amount
                </p>
                <p className="text-2xl font-bold text-green-500">
                  {formatCurrency(results.takeHome)}
                </p>
                <p className="text-sm text-gray-500">
                  Monthly: {formatCurrency(results.takeHome / 12)}
                </p>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <div className="flex justify-between mb-2">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Effective Tax Rate
                  </p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {results.effectiveRate.toFixed(1)}%
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Marginal Tax Rate
                  </p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {results.marginRate.toFixed(1)}%
                  </p>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Tax Bracket Breakdown
                </h4>
                <div className="space-y-3">
                  {results.bracketBreakdown.map((bracket, index) => (
                    <div
                      key={index}
                      className="bg-white dark:bg-gray-800 rounded-lg p-3"
                    >
                      <div className="flex justify-between items-center mb-1">
                        <span className="text-sm font-medium">
                          {bracket.rate}% Bracket
                        </span>
                        <span className="text-sm text-gray-500">
                          Tax: {formatCurrency(bracket.tax)}
                        </span>
                      </div>
                      <div className="text-sm text-gray-500">
                        Income in bracket: {formatCurrency(bracket.amount)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Deductions Analysis
                </h4>
                <div className="space-y-3">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Standard Deduction:</span>
                    <span className="font-medium">
                      {formatCurrency(results.deductionSummary.standard)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Itemized Deductions:</span>
                    <span className="font-medium">
                      {formatCurrency(results.deductionSummary.itemized)}
                    </span>
                  </div>
                  <div className="p-3 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                    <p className="text-sm text-blue-800 dark:text-blue-200">
                      {results.deductionSummary.usingItemized
                        ? `Using itemized deductions saves you ${formatCurrency(
                            results.deductionSummary.itemized -
                              results.deductionSummary.standard
                          )} in taxable income`
                        : "Standard deduction is better for your situation"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Tax Savings Opportunities
                </h4>
                <div className="space-y-3">
                  {parseFloat(deductions.retirement || "0") < 23000 && (
                    <div className="flex items-start space-x-3 text-sm">
                      <Briefcase className="h-5 w-5 text-primary-400 flex-shrink-0" />
                      <p>
                        You can contribute up to{" "}
                        {formatCurrency(
                          23000 - parseFloat(deductions.retirement || "0")
                        )}{" "}
                        more to your 401(k) this year
                      </p>
                    </div>
                  )}
                  {results.marginRate > 22 && (
                    <div className="flex items-start space-x-3 text-sm">
                      <Gift className="h-5 w-5 text-primary-400 flex-shrink-0" />
                      <p>
                        Consider increasing charitable contributions - they'll
                        save you {results.marginRate.toFixed(1)}% in taxes
                      </p>
                    </div>
                  )}
                  {!deductions.mortgage && (
                    <div className="flex items-start space-x-3 text-sm">
                      <Building2 className="h-5 w-5 text-primary-400 flex-shrink-0" />
                      <p>
                        Mortgage interest can be deducted if you itemize
                        deductions
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <Calculator className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500 dark:text-gray-400">
                Enter income details to see your tax estimate
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TaxPlanner;
