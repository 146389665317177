import React, { useState } from "react";
import {
  Calculator,
  DollarSign,
  Percent,
  Target,
  Calendar,
} from "lucide-react";

function RetirementCalculator() {
  const [currentAge, setCurrentAge] = useState<string>("30");
  const [retirementAge, setRetirementAge] = useState<string>("65");
  const [currentSavings, setCurrentSavings] = useState<string>("");
  const [monthlyContribution, setMonthlyContribution] = useState<string>("");
  const [expectedReturn, setExpectedReturn] = useState<string>("7");
  const [inflationRate, setInflationRate] = useState<string>("2");
  const [desiredIncome, setDesiredIncome] = useState<string>("");
  const [socialSecurity, setSocialSecurity] = useState<string>("");
  const [lifeExpectancy, setLifeExpectancy] = useState<string>("90");

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleNumberInput = (
    value: string,
    setter: (value: string) => void,
    isPercentage: boolean = false
  ) => {
    const regex = isPercentage ? /^\d*\.?\d*$/ : /^[0-9]*\.?[0-9]*$/;
    if (value === "" || regex.test(value)) {
      setter(value);
    }
  };

  const calculateRetirement = () => {
    const yearsToRetirement = parseInt(retirementAge) - parseInt(currentAge);
    const yearsInRetirement =
      parseInt(lifeExpectancy) - parseInt(retirementAge);
    const monthlyRate = (1 + parseFloat(expectedReturn) / 100) ** (1 / 12) - 1;
    const months = yearsToRetirement * 12;

    // Calculate future value of current savings
    let futureValue = parseFloat(currentSavings || "0");
    for (let i = 0; i < months; i++) {
      futureValue =
        (futureValue + parseFloat(monthlyContribution || "0")) *
        (1 + monthlyRate);
    }

    // Calculate required retirement savings
    const monthlyDesiredIncome =
      parseFloat(desiredIncome || "0") - parseFloat(socialSecurity || "0");
    const inflationAdjustedIncome =
      monthlyDesiredIncome *
      Math.pow(1 + parseFloat(inflationRate) / 100, yearsToRetirement);
    const withdrawalRate = 0.04; // 4% rule
    const requiredSavings = (inflationAdjustedIncome * 12) / withdrawalRate;

    // Calculate monthly income in retirement
    const projectedMonthlyIncome =
      (futureValue * withdrawalRate) / 12 + parseFloat(socialSecurity || "0");
    const incomeGap = inflationAdjustedIncome - projectedMonthlyIncome;

    // Calculate additional savings needed
    const additionalSavingsNeeded = Math.max(0, requiredSavings - futureValue);
    const additionalMonthlySavingsNeeded =
      (additionalSavingsNeeded * monthlyRate) /
      ((Math.pow(1 + monthlyRate, months) - 1) * (1 + monthlyRate));

    // Calculate year-by-year projections
    const projections = Array.from(
      { length: yearsToRetirement + 1 },
      (_, year) => {
        const savings =
          futureValue * Math.pow(1 + parseFloat(expectedReturn) / 100, year);
        const contributions =
          parseFloat(monthlyContribution || "0") * 12 * year;
        const totalSavings = savings + contributions;
        const percentageComplete = (totalSavings / requiredSavings) * 100;

        return {
          age: parseInt(currentAge) + year,
          savings: totalSavings,
          contributions,
          percentageComplete: Math.min(100, percentageComplete),
        };
      }
    );

    return {
      futureValue,
      requiredSavings,
      projectedMonthlyIncome,
      incomeGap,
      additionalMonthlySavingsNeeded,
      onTrack: futureValue >= requiredSavings,
      savingsRatio: (futureValue / requiredSavings) * 100,
      projections,
    };
  };

  const results = currentAge && retirementAge ? calculateRetirement() : null;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Current Age
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="numeric"
                  value={currentAge}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setCurrentAge)
                  }
                  onBlur={() => {
                    if (currentAge) {
                      setCurrentAge(
                        Math.round(parseFloat(currentAge)).toString()
                      );
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter current age"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Retirement Age
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="numeric"
                  value={retirementAge}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setRetirementAge)
                  }
                  onBlur={() => {
                    if (retirementAge) {
                      setRetirementAge(
                        Math.round(parseFloat(retirementAge)).toString()
                      );
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter retirement age"
                />
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Current Retirement Savings
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={currentSavings}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setCurrentSavings)
                }
                onBlur={() => {
                  if (currentSavings) {
                    setCurrentSavings(parseFloat(currentSavings).toFixed(2));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter current savings"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Monthly Contribution
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={monthlyContribution}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setMonthlyContribution)
                }
                onBlur={() => {
                  if (monthlyContribution) {
                    setMonthlyContribution(
                      parseFloat(monthlyContribution).toFixed(2)
                    );
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter monthly contribution"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Desired Monthly Retirement Income
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={desiredIncome}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setDesiredIncome)
                }
                onBlur={() => {
                  if (desiredIncome) {
                    setDesiredIncome(parseFloat(desiredIncome).toFixed(2));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter desired monthly income"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Expected Monthly Social Security
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                inputMode="decimal"
                value={socialSecurity}
                onChange={(e) =>
                  handleNumberInput(e.target.value, setSocialSecurity)
                }
                onBlur={() => {
                  if (socialSecurity) {
                    setSocialSecurity(parseFloat(socialSecurity).toFixed(2));
                  }
                }}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter expected Social Security"
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              <a
                href="https://www.ssa.gov/myaccount/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-400 hover:text-primary-500"
              >
                Check your Social Security estimate →
              </a>
            </p>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Expected Return (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={expectedReturn}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setExpectedReturn, true)
                  }
                  onBlur={() => {
                    if (expectedReturn) {
                      setExpectedReturn(parseFloat(expectedReturn).toFixed(1));
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter expected return"
                />
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Historical S&P 500 average: ~10% (before inflation)
              </p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Inflation Rate (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={inflationRate}
                  onChange={(e) =>
                    handleNumberInput(e.target.value, setInflationRate, true)
                  }
                  onBlur={() => {
                    if (inflationRate) {
                      setInflationRate(parseFloat(inflationRate).toFixed(1));
                    }
                  }}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter inflation rate"
                />
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Historical average: 2-3% per year
              </p>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
          <h3 className="text-lg font-medium mb-6">Retirement Summary</h3>

          {results ? (
            <div className="space-y-6">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Projected Savings at Retirement
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {formatCurrency(results.futureValue)}
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Required Savings
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {formatCurrency(results.requiredSavings)}
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Projected Monthly Income
                </p>
                <p
                  className={`text-2xl font-bold ${
                    results.incomeGap <= 0 ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {formatCurrency(results.projectedMonthlyIncome)}
                </p>
                {results.incomeGap > 0 && (
                  <p className="text-sm text-red-500">
                    Income gap: {formatCurrency(results.incomeGap)}/month
                  </p>
                )}
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <div className="flex items-center justify-between mb-2">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Progress to Goal
                  </p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {results.savingsRatio.toFixed(1)}%
                  </p>
                </div>
                <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2.5">
                  <div
                    className={`h-2.5 rounded-full ${
                      results.onTrack ? "bg-green-500" : "bg-yellow-500"
                    }`}
                    style={{ width: `${Math.min(results.savingsRatio, 100)}%` }}
                  />
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Year-by-Year Projections
                </h4>
                <div className="space-y-3">
                  {results.projections
                    .filter(
                      (_, i) =>
                        i % 5 === 0 || i === results.projections.length - 1
                    )
                    .map((year) => (
                      <div
                        key={year.age}
                        className="bg-white dark:bg-gray-800 rounded-lg p-3"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <span className="text-sm font-medium">
                            Age {year.age}
                          </span>
                          <span className="text-sm text-gray-500">
                            {formatCurrency(year.savings)}
                          </span>
                        </div>
                        <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-1.5">
                          <div
                            className="bg-primary-400 h-1.5 rounded-full"
                            style={{ width: `${year.percentageComplete}%` }}
                          />
                        </div>
                        <div className="flex justify-between mt-1 text-xs text-gray-500">
                          <span>
                            Total Contributions:{" "}
                            {formatCurrency(year.contributions)}
                          </span>
                          <span>
                            {year.percentageComplete.toFixed(1)}% of goal
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {!results.onTrack && (
                <div className="p-4 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg">
                  <p className="text-sm text-yellow-800 dark:text-yellow-200">
                    To reach your goal, consider:
                    <ul className="list-disc ml-4 mt-2 space-y-1">
                      <li>
                        Increasing your monthly contribution by{" "}
                        {formatCurrency(results.additionalMonthlySavingsNeeded)}
                      </li>
                      <li>Adjusting your retirement age</li>
                      <li>Reducing your desired retirement income</li>
                      <li>Exploring higher-return investment options</li>
                    </ul>
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center py-8">
              <Calculator className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500 dark:text-gray-400">
                Enter retirement details to see your projection
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RetirementCalculator;
