import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  X,
  MapPin,
  Receipt,
  Tag,
  Edit2,
  Save,
  Plus,
  ExternalLink,
} from "lucide-react";
import { Transaction } from "../../types/transaction";
import { useTransactionStore } from "../../store/transactionStore";
import { createDocument, getDocument } from "../../utils/firebase-db";
import {
  arrayUnion,
  collection,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../config/firebase";
import { useAuthStore } from "../../store/authStore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

interface TransactionDetailsModalProps {
  transaction: Transaction;
  onClose: () => void;
}

function TransactionDetailsModal({
  transaction,
  onClose,
}: TransactionDetailsModalProps) {
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notes, setNotes] = useState(transaction.notes || "");
  const [newTag, setNewTag] = useState("");
  const [transactionNotes, setTransactionNotes] = useState([]);
  const [transactionReceipts, setTransactionReceipts] = useState<any>(null);
  const [tags, setTags] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const [imageOrientation, setImageOrientation] = useState<
    "landscape" | "portrait" | null
  >(null);
  const { user } = useAuthStore();

  useEffect(() => {
    if (!transaction?.transaction_id) return;

    const unsubscribe = onSnapshot(
      doc(db, "transactionTags", transaction.transaction_id),
      (docSnap) => {
        if (docSnap.exists()) {
          setTags(docSnap.data().tags || []);
        } else {
          setTags([]);
        }
      },
      (error) => console.error("Error fetching tags:", error.message)
    );

    return () => unsubscribe();
  }, [user, transaction?.transaction_id]);

  useEffect(() => {
    if (!transaction?.transaction_id) return;

    const unsubscribe = onSnapshot(
      doc(db, "transactionReceipts", transaction.transaction_id),
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTransactionReceipts(data);

          // Check image orientation when receipt data is loaded
          if (data?.url) {
            const img = new Image();
            img.onload = () => {
              setImageOrientation(
                img.width > img.height ? "landscape" : "portrait"
              );
              setImageLoading(false);
            };
            img.src = data.url;
          }
        } else {
          setTransactionReceipts(null);
          setImageOrientation(null);
        }
      },
      (error) => console.error("Error fetching receipts:", error.message)
    );

    return () => unsubscribe();
  }, [user, transaction?.transaction_id]);

  useEffect(() => {
    if (!transaction?.transaction_id) return;

    const unsubscribe = onSnapshot(
      doc(db, "transactionNotes", transaction.transaction_id),
      (docSnap) => {
        if (docSnap.exists()) {
          setTransactionNotes(docSnap.data() || {});
        } else {
          setTransactionNotes({});
        }
      },
      (error) => console.error("Error fetching notes:", error.message)
    );

    return () => unsubscribe();
  }, [user, transaction?.transaction_id]);

  const handleNotesSubmit = async () => {
    if (!notes) return;

    try {
      await createDocument("transactionNotes", transaction.transaction_id, {
        notes: notes,
      });
      setTransactionNotes({ notes: notes });
      setIsEditingNotes(false);
    } catch (error) {
      console.error("Error saving notes:", error);
    }
  };

  const handleReceiptUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = e.target.files?.[0];
      if (!file) throw new Error("No file selected.");
      if (!user) throw new Error("No authenticated user found.");

      setImageLoading(true);

      // Create a temporary URL for the uploaded image
      const tempUrl = URL.createObjectURL(file);

      // Check image orientation
      const img = new Image();
      img.onload = async () => {
        const orientation = img.width > img.height ? "landscape" : "portrait";
        setImageOrientation(orientation);
        URL.revokeObjectURL(tempUrl);
      };
      img.src = tempUrl;

      // Storage reference (organized by user and transaction)
      const receiptRef = ref(
        storage,
        `receipts/${user.id}/${transaction.transaction_id}/${file.name}`
      );

      // Upload file to Firebase Storage
      const snapshot = await uploadBytes(receiptRef, file);
      const receiptURL = await getDownloadURL(snapshot.ref);

      // Update Firestore with the receipt URL and metadata
      await createDocument("transactionReceipts", transaction.transaction_id, {
        url: receiptURL,
        transaction_id: transaction.transaction_id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
      });
    } catch (error: any) {
      console.error("Receipt upload failed:", error.message);
      setImageLoading(false);
      throw error;
    }
  };

  const handleAddTag = async () => {
    if (!newTag.trim()) return;

    try {
      const docRef = doc(db, "transactionTags", transaction.transaction_id);

      // Create the document if it doesn't exist
      await updateDoc(docRef, {
        tags: arrayUnion(newTag.trim()),
      }).catch(async (error) => {
        if (error.code === "not-found") {
          await createDocument("transactionTags", transaction.transaction_id, {
            tags: [newTag.trim()],
          });
        } else {
          throw error;
        }
      });

      setNewTag("");
    } catch (error) {
      console.error("Error updating tags:", error);
    }
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImageLoading(false);
    const img = e.target as HTMLImageElement;
    setImageOrientation(img.width > img.height ? "landscape" : "portrait");
  };

  const handleViewFullReceipt = () => {
    if (transactionReceipts?.url) {
      window.open(transactionReceipts.url, "_blank");
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div
          className="relative w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <div>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                Transaction Details
              </h2>
              <p className="text-sm text-gray-500">
                {transaction?.authorized_date || transaction?.date
                  ? format(
                      new Date(transaction.authorized_date || transaction.date),
                      "MMM d, yyyy"
                    )
                  : "N/A"}
              </p>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="p-6 space-y-6">
            {/* Amount and Status */}
            <div className="flex justify-between items-center">
              <div>
                <p
                  className={`text-3xl font-bold ${
                    transaction.amount < 0 ? "text-red-500" : "text-green-500"
                  }`}
                >
                  {transaction.amount < 0
                    ? "-$" + (transaction.amount * -1).toLocaleString()
                    : "$" + transaction.amount.toLocaleString()}
                </p>
                {transaction.currency !== "USD" && (
                  <p className="text-sm text-gray-500">
                    {transaction.iso_currency_code}{" "}
                    {transaction.usdAmount?.toLocaleString()}
                  </p>
                )}
              </div>
              <span
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  !transaction.pending
                    ? "bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400"
                    : transaction.pending
                    ? "bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-400"
                    : "bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-400"
                }`}
              >
                {!transaction.pending ? "Completed" : "Pending"}
              </span>
            </div>

            {/* Merchant Details */}
            <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
              <div className="flex items-center space-x-4">
                {transaction.logo_url ? (
                  <img
                    src={transaction.logo_url}
                    alt={transaction.merchant_name || transaction.name}
                    className="h-12 w-12 rounded-full"
                  />
                ) : (
                  <div className="h-12 w-12 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
                    <span className="text-lg font-medium text-gray-600 dark:text-gray-300">
                      {transaction?.merchant_name?.charAt(0) ||
                        transaction?.name?.charAt(0)}
                    </span>
                  </div>
                )}
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    {transaction.merchant_name || transaction.name}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {transaction?.category?.join(", ")}
                  </p>
                </div>
              </div>
              {transaction.location.city ? (
                <div className="mt-4 flex items-start">
                  <MapPin className="h-5 w-5 text-gray-400 mr-2 mt-0.5" />
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      {transaction.location.address}
                    </p>
                    <p className="text-sm text-gray-500">
                      {transaction.location.city}, {transaction.location.state}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="mt-4 flex items-start">
                  <MapPin className="h-5 w-5 text-gray-400 mr-2 mt-0.5" />
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      N/A
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Receipt */}
            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Receipt</h3>
                <label className="cursor-pointer">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleReceiptUpload}
                    className="hidden"
                  />
                  <div className="flex items-center text-primary-400 hover:text-primary-500">
                    <Receipt className="h-5 w-5 mr-2" />
                    <span>
                      {transactionReceipts?.url
                        ? "Update Receipt"
                        : "Upload Receipt"}
                    </span>
                  </div>
                </label>
              </div>

              {transactionReceipts?.url && (
                <div className="mt-2 space-y-2">
                  <div
                    className={`relative rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700 ${
                      imageOrientation === "landscape"
                        ? "max-h-48 w-full"
                        : "max-h-96 mx-auto"
                    }`}
                  >
                    {imageLoading && (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-100 dark:bg-gray-700">
                        <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary-400 border-t-transparent"></div>
                      </div>
                    )}
                    <img
                      src={transactionReceipts.url}
                      alt="Receipt"
                      className={`${
                        imageOrientation === "landscape"
                          ? "w-full h-auto object-cover"
                          : "h-full w-auto max-h-96 mx-auto object-contain"
                      } ${
                        imageLoading ? "opacity-0" : "opacity-100"
                      } transition-opacity duration-200`}
                      onLoad={handleImageLoad}
                    />
                    <button
                      onClick={handleViewFullReceipt}
                      className="absolute top-2 right-2 p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70 transition-opacity"
                      title="View full receipt"
                    >
                      <ExternalLink className="h-4 w-4" />
                    </button>
                  </div>
                  <div className="flex items-center justify-between text-xs text-gray-500">
                    <p>
                      Uploaded on{" "}
                      {format(
                        new Date(transactionReceipts.createdAt),
                        "MMM d, yyyy"
                      )}
                    </p>
                    {transactionReceipts.fileSize && (
                      <p>
                        {(transactionReceipts.fileSize / 1024 / 1024).toFixed(
                          2
                        )}{" "}
                        MB
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Notes */}
            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Notes</h3>
                <button
                  onClick={() => setIsEditingNotes(!isEditingNotes)}
                  className="text-primary-400 hover:text-primary-500"
                >
                  {isEditingNotes ? (
                    <Save className="h-5 w-5" />
                  ) : (
                    <Edit2 className="h-5 w-5" />
                  )}
                </button>
              </div>
              {isEditingNotes ? (
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  onBlur={handleNotesSubmit}
                  className="w-full rounded-lg border border-gray-300 dark:border-gray-600 p-2 focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700"
                  rows={3}
                  placeholder="Add notes about this transaction..."
                />
              ) : (
                <p className="text-gray-600 dark:text-gray-300">
                  {transactionNotes?.notes || "No notes added"}
                </p>
              )}
            </div>

            {/* Tags */}
            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Tags</h3>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && handleAddTag()}
                    className="border border-gray-300 dark:border-gray-600 rounded-lg px-3 py-1 text-sm focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700"
                    placeholder="Add tag"
                  />
                  <button
                    onClick={handleAddTag}
                    className="p-1 text-primary-400 hover:text-primary-500"
                  >
                    <Plus className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap gap-2">
                {transaction?.category?.map((cat) => (
                  <span
                    key={cat}
                    className="px-3 py-1 bg-gray-100 dark:bg-gray-700 rounded-full text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    {cat}
                  </span>
                ))}
                {tags.map((tag) => (
                  <span
                    key={tag}
                    className="px-3 py-1 bg-primary-50 dark:bg-primary-900/20 rounded-full text-sm font-medium text-primary-600 dark:text-primary-400"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetailsModal;
