import { useAuthStore } from "../../store/authStore";
import { BudgetItem } from "../../types/budget";
import { startOfMonth, endOfMonth, isWithinInterval } from "date-fns";

interface BudgetCategoryListProps {
  budgets?: BudgetItem[];
  categories?: string[];
  transactions?: any[];
}

function BudgetCategoryList({
  budgets,
  categories,
  transactions,
}: BudgetCategoryListProps) {
  const { user } = useAuthStore();

  // Get current month's date range
  const currentMonthStart = startOfMonth(new Date());
  const currentMonthEnd = endOfMonth(new Date());

  // Filter budgets by user and calculate current month's transactions
  const currentMonthBudgets = budgets
    ?.filter((budget) => budget.userId === user.id)
    .map((budget) => {
      // Check if it's a custom category
      const isCustomCategory = !categories
        ?.map((cat) => cat.name)
        .includes(budget.category);

      if (isCustomCategory) {
        // For custom categories, use the stored spent amount
        return {
          ...budget,
          spent: budget.spent || 0,
        };
      } else {
        // For Plaid categories, calculate from transactions
        const monthlyTransactions =
          transactions?.filter((transaction) => {
            const transactionDate = new Date(
              transaction.authorized_date || transaction.date
            );
            const transactionCategory =
              transaction.category?.[1]?.toLowerCase() ||
              transaction.category?.[0]?.toLowerCase();

            return (
              isWithinInterval(transactionDate, {
                start: currentMonthStart,
                end: currentMonthEnd,
              }) && transactionCategory === budget.category.toLowerCase()
            );
          }) || [];

        const monthlySpent = monthlyTransactions.reduce(
          (sum, transaction) => sum + Math.abs(transaction.amount || 0),
          0
        );

        return {
          ...budget,
          spent: monthlySpent,
          transactions: monthlyTransactions,
        };
      }
    });

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
          Budget Categories
        </h2>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {currentMonthStart.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </p>
      </div>

      <div className="space-y-4">
        {currentMonthBudgets?.map((item) => {
          const percentage = ((item.spent || 0) / item.limit) * 100;
          const isOverBudget = (item.spent || 0) > item.limit;
          const remaining = item.limit - (item.spent || 0);
          const isCustomCategory = !categories
            ?.map((cat) => cat.name)
            .includes(item.category);

          return (
            <div key={item.id} className="space-y-2">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white capitalize">
                    {item.name}
                    {isCustomCategory && (
                      <span className="ml-2 text-xs text-gray-500">
                        (Custom)
                      </span>
                    )}
                  </h3>
                  <p className="text-sm text-gray-500">
                    ${(item.spent || 0).toLocaleString()} of $
                    {item.limit.toLocaleString()} this month
                  </p>
                </div>
                <span
                  className={`text-sm font-medium ${
                    isOverBudget ? "text-red-500" : "text-green-500"
                  }`}
                >
                  {remaining >= 0
                    ? `$${remaining.toLocaleString()} left`
                    : `$${Math.abs(remaining).toLocaleString()} over`}
                </span>
              </div>

              <div className="relative">
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className={`h-2 rounded-full ${
                      percentage >= 100
                        ? "bg-red-500"
                        : percentage >= 80
                        ? "bg-yellow-500"
                        : "bg-green-500"
                    }`}
                    style={{ width: `${Math.min(percentage, 100)}%` }}
                  />
                </div>
              </div>
            </div>
          );
        })}

        {(!currentMonthBudgets || currentMonthBudgets.length === 0) && (
          <div className="text-center py-4">
            <p className="text-gray-500 dark:text-gray-400">
              No budget categories found for this month
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default BudgetCategoryList;
