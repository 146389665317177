export const properties = [
  {
    id: "5500-Grand-Lake-Dr,-San-Antonio,-TX-78244",
    formattedAddress: "5500 Grand Lake Dr, San Antonio, TX 78244",
    addressLine1: "5500 Grand Lake Dr",
    addressLine2: null,
    city: "San Antonio",
    state: "TX",
    zipCode: "78244",
    county: "Bexar",
    latitude: 29.475962,
    longitude: -98.351442,
    propertyType: "Single Family",
    bedrooms: 3,
    bathrooms: 2,
    squareFootage: 1878,
    lotSize: 8843,
    yearBuilt: 1973,
    assessorID: "05076-103-0500",
    legalDescription: "CB 5076A BLK 3 LOT 50",
    subdivision: "CONV A/S CODE",
    zoning: "RH",
    lastSaleDate: "2017-10-19T00:00:00.000Z",
    hoa: {
      fee: 175,
    },
    features: {
      architectureType: "Contemporary",
      cooling: true,
      coolingType: "Central",
      exteriorType: "Wood",
      fireplace: true,
      fireplaceType: "Masonry",
      floorCount: 1,
      foundationType: "Slab / Mat / Raft",
      garage: true,
      garageSpaces: 2,
      garageType: "Garage",
      heating: true,
      heatingType: "Forced Air",
      pool: true,
      poolType: "Concrete",
      roofType: "Asphalt",
      roomCount: 5,
      unitCount: 1,
      viewType: "City",
    },
    taxAssessments: {
      2017: {
        year: 2017,
        value: 134880,
        land: 18760,
        improvements: 116120,
      },
      2018: {
        year: 2018,
        value: 126510,
        land: 18760,
        improvements: 107750,
      },
      2019: {
        year: 2019,
        value: 135430,
        land: 23450,
        improvements: 111980,
      },
      2020: {
        year: 2020,
        value: 142610,
        land: 23450,
        improvements: 119160,
      },
      2021: {
        year: 2021,
        value: 163440,
        land: 45050,
        improvements: 118390,
      },
      2022: {
        year: 2022,
        value: 197600,
        land: 49560,
        improvements: 148040,
      },
      2023: {
        year: 2023,
        value: 225790,
        land: 59380,
        improvements: 166410,
      },
    },
    propertyTaxes: {
      2017: {
        year: 2017,
        total: 3064,
      },
      2018: {
        year: 2018,
        total: 2890,
      },
      2019: {
        year: 2019,
        total: 2984,
      },
      2020: {
        year: 2020,
        total: 3023,
      },
      2021: {
        year: 2021,
        total: 3455,
      },
      2022: {
        year: 2022,
        total: 4091,
      },
      2023: {
        year: 2023,
        total: 4201,
      },
    },
    history: {
      "2004-06-16": {
        event: "Sale",
        date: "2004-06-16T00:00:00.000Z",
        price: 95000,
      },
      "2017-10-19": {
        event: "Sale",
        date: "2017-10-19T00:00:00.000Z",
      },
    },
    owner: {
      names: ["Michael O Smith"],
      type: "Individual",
      mailingAddress: {
        id: "149-Weaver-Blvd,---264,-Weaverville,-NC-28787",
        formattedAddress: "149 Weaver Blvd, # 264, Weaverville, NC 28787",
        addressLine1: "149 Weaver Blvd",
        addressLine2: "# 264",
        city: "Weaverville",
        state: "NC",
        zipCode: "28787",
      },
    },
    ownerOccupied: false,
  },
  {
    id: "1235-E-76th-St,-Los-Angeles,-CA-90001",
    formattedAddress: "1235 E 76th St, Los Angeles, CA 90001",
    addressLine1: "1235 E 76th St",
    addressLine2: null,
    city: "Los Angeles",
    state: "CA",
    zipCode: "90001",
    county: "Los Angeles",
    latitude: 33.971687,
    longitude: -118.253137,
    propertyType: "Single Family",
    bedrooms: 3,
    bathrooms: 1,
    squareFootage: 960,
    lotSize: 4741,
    yearBuilt: 1923,
    assessorID: "6024-008-023",
    legalDescription: "TRACT NO 5450 LOT 1046",
    subdivision: "5450",
    zoning: "LCR3*",
    lastSaleDate: "1989-08-31T00:00:00.000Z",
    lastSalePrice: 95000,
    features: {
      floorCount: 1,
      heating: true,
      heatingType: "Floor / Wall",
      unitCount: 1,
    },
    taxAssessments: {
      2023: {
        year: 2023,
        value: 197594,
        land: 106080,
        improvements: 91514,
      },
    },
    propertyTaxes: {
      2023: {
        year: 2023,
        total: 3064,
      },
    },
    owner: {
      names: [""],
      mailingAddress: {
        id: "1235-E-76th-St,-Los-Angeles,-CA-90001",
        formattedAddress: "1235 E 76th St, Los Angeles, CA 90001",
        addressLine1: "1235 E 76th St",
        addressLine2: null,
        city: "Los Angeles",
        state: "CA",
        zipCode: "90001",
      },
    },
    ownerOccupied: true,
  },
];
