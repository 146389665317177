import React, { useCallback, useContext, useEffect } from "react";
import {
  TrendingUp,
  Search,
  Filter,
  ArrowUpDown,
  Bitcoin,
  DollarSign,
  Clock,
} from "lucide-react";
import LinkCryptoButton from "./LinkCryptoButton";
import { usePlaidLink } from "react-plaid-link";
import { api } from "../../utils/api";
import { useAuthStore } from "../../store/authStore";
import Context from "../../Context";
import { createDocument } from "../../utils/firebase-db";

function CryptoPage() {
  const cryptoHoldings = [
    {
      id: "1",
      symbol: "BTC",
      name: "Bitcoin",
      quantity: 2.5,
      avgCost: 35000,
      currentPrice: 68500,
      value: 171250,
      gain: 84750,
      gainPercent: 98.14,
      wallet: "Hardware Wallet",
      platform: "Coinbase",
      lastTransaction: "2024-02-15",
    },
    {
      id: "2",
      symbol: "ETH",
      name: "Ethereum",
      quantity: 15,
      avgCost: 2200,
      currentPrice: 3850,
      value: 57750,
      gain: 24750,
      gainPercent: 75.0,
      wallet: "MetaMask",
      platform: "Binance",
      lastTransaction: "2024-03-01",
    },
    {
      id: "3",
      symbol: "SOL",
      name: "Solana",
      quantity: 250,
      avgCost: 85,
      currentPrice: 145,
      value: 36250,
      gain: 15000,
      gainPercent: 70.59,
      wallet: "Phantom",
      platform: "FTX",
      lastTransaction: "2024-03-10",
    },
  ];

  const totalValue = cryptoHoldings.reduce(
    (sum, crypto) => sum + crypto.value,
    0
  );
  const totalGain = cryptoHoldings.reduce(
    (sum, crypto) => sum + crypto.gain,
    0
  );
  const totalCost = cryptoHoldings.reduce(
    (sum, crypto) => sum + crypto.avgCost * crypto.quantity,
    0
  );

  const { addAccessTokenAndItemId, user, plaidInvestmentsHoldings } =
    useAuthStore();
  const { linkToken, isPaymentInitiation, isCraProductsExclusively, dispatch } =
    useContext(Context);

  enum CraCheckReportProduct {
    BaseReport = "cra_base_report",
    IncomeInsights = "cra_income_insights",
    PartnerInsights = "cra_partner_insights",
    NetworkInsights = "cra_network_insights",
  }

  const getInfo = useCallback(async () => {
    if (!dispatch) {
      console.error("Dispatch is not defined");
      return;
    }

    const response = await api.post("/info", {
      access_token: user.access_token,
      item_id: user.item_id,
    });

    const paymentInitiation: boolean =
      response.products.includes("payment_initiation");
    const craEnumValues = Object.values(CraCheckReportProduct);
    const isUserTokenFlow: boolean = response.products.some(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );
    const isCraProductsExclusively: boolean = response.products.every(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );

    dispatch({
      type: "SET_STATE",
      state: {
        products: response.products,
        isPaymentInitiation: paymentInitiation,
        isCraProductsExclusively: isCraProductsExclusively,
        isUserTokenFlow: isUserTokenFlow,
      },
    });
    return { paymentInitiation, isUserTokenFlow };
  }, [dispatch]);

  const generateUserToken = useCallback(async () => {
    const response = await api.post("/create_user_token", {});

    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { userToken: null } });

      return;
    }

    const data = await response.json();
    if (data) {
      if (data.error != null) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
            linkTokenError: data.error,
          },
        });
        return;
      }
      dispatch({ type: "SET_STATE", state: { userToken: data.user_token } });

      return data.user_token;
    }
  }, [dispatch]);

  const generateToken = useCallback(
    async (isPaymentInitiation) => {
      // Link tokens for 'payment_initiation' use a different creation flow in your backend.

      const path =
        // isPaymentInitiation
        // ? "/api/create_link_token_for_payment"
        "/create_link_token";
      const response = await api.post(path, {
        user_id: user.id,
        token_type: "loan",
      });

      // if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { linkToken: null } });

      // return;
      // }
      // const data = await response.text();

      // if (data) {
      if (!response.link_token) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
          },
        });

        return;
      }

      dispatch({
        type: "SET_STATE",
        state: { linkToken: response.link_token },
      });
    },

    [dispatch]
  );

  useEffect(() => {
    const init = async () => {
      const { paymentInitiation, isUserTokenFlow } = await getInfo(); // used to determine which path to take when generating token
      // do not generate a new token for OAuth redirect; instead

      if (isUserTokenFlow) {
        await generateUserToken();
      }
      generateToken(paymentInitiation);
    };
    init();
  }, [dispatch, generateToken, generateUserToken, getInfo]);

  const onSuccess = React.useCallback(
    (public_token: string, metadata: any) => {
      const tokenType = "crypto";
      // If the access_token is needed, send public_token to server
      const exchangePublicTokenForAccessToken = async () => {
        try {
          const response = await api.post("/set_access_token", {
            public_token: public_token,
          });

          if (!response.access_token) {
            throw new Error("Failed to exchange token");
          }

          dispatch({
            type: "SET_STATE",
            state: {
              itemId: response.item_id,
              accessToken: response.access_token,
              isItemAccess: true,
            },
          });

          const cryptoResults = await plaidInvestmentsHoldings(
            response.access_token
          );

          await addAccessTokenAndItemId(response, user, tokenType);

          await createDocument("crypto", user.id, cryptoResults);
        } catch (error) {
          console.error("Token exchange failed:", error);
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: `no item_id retrieved`,
              accessToken: `no access_token retrieved`,
              isItemAccess: false,
            },
          });
        }
      };
      // 'payment_initiation' products do not require the public_token to be exchanged for an access_token.
      if (isPaymentInitiation) {
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else if (isCraProductsExclusively) {
        // When only CRA products are enabled, only user_token is needed. access_token/public_token exchange is not needed.
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else {
        exchangePublicTokenForAccessToken();
      }

      dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
      window.history.pushState("", "", "/");
    },
    [dispatch, isPaymentInitiation, isCraProductsExclusively]
  );

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  const linkCrypto = async () => {
    if (!ready) {
      console.warn("Plaid Link not ready");
      return;
    }

    if (!linkToken) {
      console.error("No link token available");
      return;
    }

    open();
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search cryptocurrencies..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <Filter className="h-5 w-5 mr-2" />
            Filter
          </button>
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <ArrowUpDown className="h-5 w-5 mr-2" />
            Sort
          </button>
          <LinkCryptoButton linkCrypto={linkCrypto} />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Portfolio Value</h3>
            <Bitcoin className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalValue.toLocaleString()}</p>
          <div className="flex items-center mt-2 text-green-500">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+${totalGain.toLocaleString()} Total Return</span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">24h Change</h3>
            <Clock className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold text-green-500">+$12,450</p>
          <p className="text-sm text-gray-500 mt-2">+4.8% Today</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Cost Basis</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalCost.toLocaleString()}</p>
          <p className="text-sm text-gray-500 mt-2">
            ROI: +{(((totalValue - totalCost) / totalCost) * 100).toFixed(2)}%
          </p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium">Crypto Holdings</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Asset
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Holdings
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Avg Cost
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Price
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Market Value
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Wallet/Platform
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Total Return
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {cryptoHoldings.map((crypto) => (
                <tr
                  key={crypto.id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="font-medium text-gray-900 dark:text-white">
                        {crypto.symbol}
                      </div>
                      <div className="text-sm text-gray-500">{crypto.name}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {crypto.quantity.toLocaleString()} {crypto.symbol}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${crypto.avgCost.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${crypto.currentPrice.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${crypto.value.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm text-gray-900 dark:text-white">
                        {crypto.wallet}
                      </div>
                      <div className="text-sm text-gray-500">
                        {crypto.platform}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex flex-col items-end">
                      <div className="flex items-center text-green-500">
                        <TrendingUp className="h-4 w-4 mr-1" />
                        <span>${crypto.gain.toLocaleString()}</span>
                      </div>
                      <span className="text-sm text-green-500">
                        +{crypto.gainPercent.toFixed(2)}%
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CryptoPage;
