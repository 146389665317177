import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { X, DollarSign, Percent, Calendar, Scale } from "lucide-react";

const commoditySchema = z.object({
  name: z.string().min(2, "Name is required"),
  type: z.enum([
    "Precious Metals",
    "Energy",
    "Agriculture",
    "Industrial Metals",
  ]),
  quantity: z.number().min(0.0001, "Quantity must be greater than 0"),
  unit: z.string().min(1, "Unit is required"),
  purchasePrice: z.number().min(0.01, "Purchase price must be greater than 0"),
  location: z.string().min(2, "Storage location is required"),
  custodian: z.string().min(2, "Custodian is required"),
  form: z.string().min(2, "Physical form is required"),
  purity: z.string().optional(),
  purchaseDate: z.string(),
  insuranceStatus: z.enum(["Insured", "Uninsured"]),
  id: z.string().optional(),
});

type CommodityFormData = z.infer<typeof commoditySchema>;

interface AddCommodityModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (data: CommodityFormData) => void;
  editingCommodity?: any;
  onEdit?: (id: string, data: CommodityFormData) => void;
}

function AddCommodityModal({
  isOpen,
  onClose,
  onAdd,
  editingCommodity,
  onEdit,
}: AddCommodityModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<CommodityFormData>({
    resolver: zodResolver(commoditySchema),
  });

  // Set form values when editingCommodity changes
  useEffect(() => {
    if (editingCommodity) {
      // Set each field individually to ensure proper type conversion
      setValue("name", editingCommodity.name);
      setValue("type", editingCommodity.type);
      setValue("quantity", Number(editingCommodity.quantity));
      setValue("unit", editingCommodity.unit);
      setValue("purchasePrice", Number(editingCommodity.purchasePrice));
      setValue("location", editingCommodity.location);
      setValue("custodian", editingCommodity.custodian);
      setValue("form", editingCommodity.form);
      setValue("purity", editingCommodity.purity || "");
      setValue(
        "purchaseDate",
        editingCommodity.purchaseDate
          ? new Date(editingCommodity.purchaseDate).toISOString().split("T")[0]
          : new Date().toISOString().split("T")[0]
      );
      setValue(
        "insuranceStatus",
        editingCommodity.insuranceStatus || "Uninsured"
      );
      setValue("id", editingCommodity.id);
    } else {
      reset(); // Clear form when not editing
    }
  }, [editingCommodity, setValue, reset]);

  const onSubmit = async (data: CommodityFormData) => {
    try {
      
      if (editingCommodity && editingCommodity.id) {
        await onEdit?.(editingCommodity, data);
      } else {
        await onAdd(data);
      }
      reset();
      onClose();
    } catch (error) {
      console.error("Failed to handle commodity:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div
          className="relative w-full max-w-md bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                <Scale className="h-6 w-6 text-primary-400" />
              </div>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {editingCommodity ? "Edit Commodity" : "Add Commodity"}
              </h2>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Commodity Name
                </label>
                <input
                  type="text"
                  {...register("name")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="e.g., Gold"
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.name.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Type
                </label>
                <select
                  {...register("type")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="Precious Metals">Precious Metals</option>
                  <option value="Energy">Energy</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Industrial Metals">Industrial Metals</option>
                </select>
                {errors.type && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.type.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Quantity
                </label>
                <input
                  type="number"
                  step="0.0001"
                  {...register("quantity", { valueAsNumber: true })}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="0.00"
                />
                {errors.quantity && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.quantity.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Unit
                </label>
                <input
                  type="text"
                  {...register("unit")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="e.g., oz, kg, barrel"
                />
                {errors.unit && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.unit.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Purchase Price (per unit)
              </label>
              <div className="relative mt-1">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <DollarSign className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  step="0.01"
                  {...register("purchasePrice", { valueAsNumber: true })}
                  className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-10 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="0.00"
                />
              </div>
              {errors.purchasePrice && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.purchasePrice.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Storage Location
              </label>
              <input
                type="text"
                {...register("location")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="e.g., Brinks Vault - NYC"
              />
              {errors.location && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.location.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Custodian
              </label>
              <input
                type="text"
                {...register("custodian")}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                placeholder="e.g., Brinks Security"
              />
              {errors.custodian && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.custodian.message}
                </p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Physical Form
                </label>
                <input
                  type="text"
                  {...register("form")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="e.g., Bullion, Bars"
                />
                {errors.form && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.form.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Purity (optional)
                </label>
                <input
                  type="text"
                  {...register("purity")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="e.g., 99.99%"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Purchase Date
                </label>
                <input
                  type="date"
                  {...register("purchaseDate")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
                {errors.purchaseDate && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.purchaseDate.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Insurance Status
                </label>
                <select
                  {...register("insuranceStatus")}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="Insured">Insured</option>
                  <option value="Uninsured">Uninsured</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md disabled:opacity-50"
              >
                {isSubmitting
                  ? editingCommodity
                    ? "Updating..."
                    : "Adding..."
                  : editingCommodity
                  ? "Update Commodity"
                  : "Add Commodity"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCommodityModal;
