import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  Suspense,
} from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "./store/authStore";
import { useThemeStore } from "./store/themeStore";
import { QuickstartProvider } from "./Context";

// Layout Components
import AppLayout from "./components/layout/AppLayout";
import AdminLayout from "./components/layout/AdminLayout";
import HomeTab from "./pages/admin/HomeTab";
import ClientsTab from "./pages/admin/ClientsTab";
import ClientProfile from "./components/admin/clients/ClientProfile";
import ActivitiesTab from "./pages/admin/ActivitiesTab";
import AdminSettings from "./pages/admin/AdminSettings";

// Auth Components
import LoginForm from "./components/auth/LoginForm";
import RegisterForm from "./components/auth/RegisterForm";
import TwoFactorVerification from "./components/auth/TwoFactorVerification";

// Pages
import Dashboard from "./pages/Dashboard";
import ReportsPage from "./pages/ReportsPage";
import BudgetPage from "./pages/BudgetPage";
import ToolsPage from "./pages/ToolsPage";
import CoursesPage from "./pages/CoursesPage";
import CourseDetailsPage from "./pages/CourseDetailsPage";
import MentorshipPage from "./pages/MentorshipPage";
import CommunityPage from "./pages/CommunityPage";
import ProfilePage from "./pages/ProfilePage";
import SettingsPage from "./pages/SettingsPage";
import TransactionPage from "./pages/TransactionPage";

// Account Pages
import AccountsOverview from "./pages/accounts/AccountsOverview";
import BankAccountsPage from "./pages/accounts/BankAccountsPage";
import CreditCardsPage from "./pages/accounts/CreditCardsPage";
import LoansPage from "./pages/accounts/LoansPage";
import RetirementAccountsPage from "./pages/accounts/RetirementAccountsPage";
import OtherAccountsPage from "./pages/accounts/OtherAccountsPage";

// Investment Pages
import InvestmentsLayout from "./pages/investments/InvestmentsLayout";
import InvestmentsOverview from "./pages/investments/InvestmentsOverview";
import StocksPage from "./pages/investments/StocksPage";
import BondsPage from "./pages/investments/BondsPage";
import CommoditiesPage from "./pages/investments/CommoditiesPage";
import RealEstatePage from "./pages/investments/RealEstatePage";
import CryptoPage from "./pages/investments/CryptoPage";
import OtherInvestmentsPage from "./pages/investments/OtherInvestmentsPage";

// Admin Pages
import { getDocument, updateDocument } from "./utils/firebase-db";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./config/firebase";
import { SavingsGoal } from "./types/budget";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, twoFactorSetup, isNewLogin, needs2FA } =
    useAuthStore();

  useEffect(() => {
    const checkAuth = () => {
      const storedNeeds2FA = JSON.parse(
        localStorage.getItem("needs2FA") || "false"
      );
      const storedTwoFactorSetup = JSON.parse(
        localStorage.getItem("twoFactorSetup") || "null"
      );

      if (
        isAuthenticated &&
        (needs2FA || storedNeeds2FA) &&
        storedTwoFactorSetup?.enabled &&
        storedTwoFactorSetup?.verified &&
        location.pathname !== "/2fa"
      ) {
        navigate("/2fa", { replace: true });
      }
    };

    checkAuth();
  }, [isAuthenticated, needs2FA, navigate, location.pathname]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (
    (needs2FA ||
      (isAuthenticated &&
        isNewLogin &&
        twoFactorSetup?.enabled &&
        twoFactorSetup?.verified)) &&
    location.pathname !== "/2fa"
  ) {
    return <Navigate to="/2fa" replace />;
  }

  return <>{children}</>;
};

function App() {
  const { t, i18n } = useTranslation();
  const { isDarkMode } = useThemeStore();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isAuthenticated,
    user,
    clearLoginState,
    logout,
    plaidTransactions,
    plaidAccounts,
    plaidLiabilities,
  } = useAuthStore();

  const [investmentTransactions, setInvestmentTransactions] = useState([]);
  const [userAddedInvestments, setUserAddedInvestments] = useState([]);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [liabilities, setLiabilities] = useState<any[]>([]);
  const [investments, setInvestments] = useState<any[]>([]);
  const [userAccounts, setUserAccounts] = useState(null);
  const [goals, setGoals] = useState<SavingsGoal[]>([]);
  const [commodities, setCommodities] = useState([]);
  const [addedStocks, setAddedStocks] = useState([]);
  const [addedBonds, setAddedBonds] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [userAddedLoans, setUserAddedLoans] = useState([]);

  const isAdmin = user?.role === "admin";

  // Handle back button on 2FA screen
  useEffect(() => {
    const handlePopState = async (event: PopStateEvent) => {
      if (location.pathname === "/2fa") {
        event.preventDefault();
        await logout();
        navigate("/login");
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [location.pathname, navigate, logout]);

  // Clear login state on unmount
  useEffect(() => {
    return () => {
      clearLoginState();
    };
  }, [clearLoginState]);

  // Data fetching effects
  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        if (!user) return;
        const fetchedData: any = await getDocument("bankAccounts", user.id);

        if (!fetchedData) {
          return;
        }

        setUserAccounts(fetchedData);

        if (user.access_token) {
          const bankAccountResult = await plaidAccounts(user?.access_token);
          await updateDocument("bankAccounts", user.id, bankAccountResult);
        }
      } catch (err) {
        console.error("Error fetching account data:", err);
      }
    };

    fetchAccountData();
  }, [user]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        if (!user) return;
        const fetchedData = await getDocument("transactions", user.id);
        if (!fetchedData) {
          return;
        }
        const transactionsArray = Object.entries(fetchedData)
          .filter(([key]) => !isNaN(Number(key)))
          .map(([, value]) => value);

        setTransactions(transactionsArray);

        if (user.access_token) {
          const transactionResult = await plaidTransactions(user?.access_token);
          await updateDocument("transactions", user.id, transactionResult);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setTransactions([]);
      }
    };

    fetchTransactions();
  }, [user]);

  useEffect(() => {
    const fetchLiabilityData = async () => {
      try {
        if (!user) return;
        const fetchedData: any = await getDocument("liabilities", user.id);

        if (!fetchedData) {
          return;
        }

        setLiabilities(fetchedData);

        if (user.access_token_loan) {
          const bankAccountResult = await plaidLiabilities(
            user?.access_token_loan
          );
          await updateDocument("liabilities", user.id, bankAccountResult);
        }
      } catch (err) {
        console.error("Error fetching account data:", err);
      }
    };

    fetchLiabilityData();
  }, [user]);

  useEffect(() => {
    const fetchInvestmentData = async () => {
      try {
        if (!user) return;
        const fetchedData: any = await getDocument("investments", user.id);

        if (!fetchedData) {
          return;
        }

        setInvestments(fetchedData);

        if (user?.access_token_inv) {
          const investmentResult = await plaidAccounts(user?.access_token_inv);
          await updateDocument("investments", user.id, investmentResult);
        }
      } catch (err) {
        console.error("Error fetching investment data:", err);
      }
    };

    fetchInvestmentData();
  }, [user]);

  useEffect(() => {
    const fetchInvestmentTransactions = async () => {
      try {
        if (!user) return;
        const fetchedData: any = await getDocument(
          "investmentTransactions",
          user.id
        );

        if (!fetchedData) {
          return;
        }

        setInvestmentTransactions(fetchedData);

        if (user?.access_token_inv) {
          const transactionResult = await plaidTransactions(
            user?.access_token_inv
          );
          await updateDocument(
            "investmentTransactions",
            user.id,
            transactionResult
          );
        }
      } catch (err) {
        console.error("Error fetching investment transactions:", err);
      }
    };

    fetchInvestmentTransactions();
  }, [user]);

  // Collection subscriptions
  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(db, "budgetCategories", user.id, "categories"),
      (snapshot) => {
        setBudgets(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      },
      (error) => {
        console.error("Error fetching documents:", error.message);
      }
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(db, "savingGoals", user.id, "goals"),
      (snapshot) => {
        setGoals(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      },
      (error) => {
        console.error("Error fetching documents:", error.message);
      }
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(db, "addedStocks", user.id, "stocks"),
      (snapshot) => {
        setAddedStocks(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      },
      (error) => {
        console.error("Error fetching documents:", error.message);
      }
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(db, "addedBonds", user.id, "bonds"),
      (snapshot) => {
        setAddedBonds(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      },
      (error) => {
        console.error("Error fetching documents:", error.message);
      }
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(db, "commodities", user.id, "userCommodity"),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCommodities(data);
      },
      (error) => {
        console.error("Error fetching documents:", error.message);
      }
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(db, "userAddedInvestments", user.id, "otherInvestments"),
      (snapshot) => {
        setUserAddedInvestments(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      },
      (error) => {
        console.error("Error fetching documents:", error.message);
      }
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(db, "userAddedLoans", user.id, "loans"),
      (snapshot) => {
        setUserAddedLoans(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      },
      (error) => {
        console.error("Error fetching documents:", error.message);
      }
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  if (!isAuthenticated) {
    return (
      <div className={isDarkMode ? "dark" : ""}>
        <Suspense fallback={<div>{t("general.loading")}</div>}>
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/2fa" element={<TwoFactorVerification />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Suspense>
      </div>
    );
  }

  // Show 2FA screen if needed
  if (location.pathname === "/2fa") {
    return (
      <div className={isDarkMode ? "dark" : ""}>
        <TwoFactorVerification />
      </div>
    );
  }

  return (
    <QuickstartProvider>
      <div className={isDarkMode ? "dark" : ""} lang={i18n.language}>
        <Suspense fallback={<div>{t("general.loading")}</div>}>
          <Routes>
            {isAdmin ? (
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <AdminLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<HomeTab />} />
                <Route path="clients" element={<ClientsTab />} />
                <Route path="clients/:clientId" element={<ClientProfile />} />
                <Route path="activities" element={<ActivitiesTab />} />
                <Route path="settings" element={<AdminSettings />} />
              </Route>
            ) : (
              <Route
                path="/*"
                element={
                  <ProtectedRoute>
                    <AppLayout
                      transactions={transactions}
                      investments={investments}
                      userAccounts={userAccounts}
                      liabilities={liabilities}
                      budgets={budgets}
                      addedStocks={addedStocks}
                      addedBonds={addedBonds}
                      commodities={commodities}
                      userAddedInvestments={userAddedInvestments}
                      goals={goals}
                    />
                  </ProtectedRoute>
                }
              >
                <Route
                  index
                  element={
                    <Dashboard
                      transactions={transactions}
                      userAccounts={userAccounts}
                      liabilities={liabilities}
                      budgets={budgets}
                      addedStocks={addedStocks}
                      addedBonds={addedBonds}
                      commodities={commodities}
                      userAddedInvestments={userAddedInvestments}
                      goals={goals}
                    />
                  }
                />
                <Route
                  path="reports"
                  element={
                    <ReportsPage
                      transactions={transactions}
                      userAccounts={userAccounts}
                      liabilities={liabilities}
                      addedStocks={addedStocks}
                      addedBonds={addedBonds}
                      commodities={commodities}
                      userAddedInvestments={userAddedInvestments}
                    />
                  }
                />
                <Route path="accounts">
                  <Route
                    index
                    element={
                      <AccountsOverview
                        userAccounts={userAccounts}
                        liabilities={liabilities}
                        addedStocks={addedStocks}
                        addedBonds={addedBonds}
                        commodities={commodities}
                        userAddedInvestments={userAddedInvestments}
                      />
                    }
                  />
                  <Route
                    path="bank"
                    element={<BankAccountsPage userAccounts={userAccounts} />}
                  />
                  <Route
                    path="credit"
                    element={<CreditCardsPage liabilities={liabilities} />}
                  />
                  <Route
                    path="loans"
                    element={
                      <LoansPage
                        liabilities={liabilities}
                        userAddedLoans={userAddedLoans}
                      />
                    }
                  />
                  <Route
                    path="retirement"
                    element={
                      <RetirementAccountsPage
                        investments={investments}
                        investmentTransactions={investmentTransactions}
                      />
                    }
                  />
                  <Route path="other" element={<OtherAccountsPage />} />
                </Route>
                <Route
                  path="budget"
                  element={
                    <BudgetPage
                      transactions={transactions}
                      budgets={budgets}
                      goals={goals}
                    />
                  }
                />
                <Route path="investments" element={<InvestmentsLayout />}>
                  <Route
                    index
                    element={
                      <InvestmentsOverview
                        investments={investments}
                        investmentTransactions={investmentTransactions}
                        transactions={transactions}
                      />
                    }
                  />
                  <Route
                    path="stocks"
                    element={
                      <StocksPage
                        investments={investments}
                        addedStocks={addedStocks}
                      />
                    }
                  />
                  <Route
                    path="bonds"
                    element={
                      <BondsPage
                        investments={investments}
                        addedBonds={addedBonds}
                      />
                    }
                  />
                  <Route
                    path="commodities"
                    element={<CommoditiesPage commodities={commodities} />}
                  />
                  <Route
                    path="real-estate"
                    element={
                      <RealEstatePage
                        investments={investments}
                        liabilities={liabilities}
                        transactions={transactions}
                      />
                    }
                  />
                  <Route path="crypto" element={<CryptoPage />} />
                  <Route
                    path="other"
                    element={
                      <OtherInvestmentsPage
                        investments={userAddedInvestments}
                      />
                    }
                  />
                </Route>
                <Route path="tools" element={<ToolsPage />} />
                <Route path="courses" element={<CoursesPage />} />
                <Route
                  path="courses/:courseId"
                  element={<CourseDetailsPage />}
                />
                <Route path="mentorship" element={<MentorshipPage />} />
                <Route path="community" element={<CommunityPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="settings" element={<SettingsPage />} />
                <Route
                  path="transactions"
                  element={<TransactionPage transactions={transactions} />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            )}
            <Route path="/2fa" element={<TwoFactorVerification />} />
          </Routes>
        </Suspense>
      </div>
    </QuickstartProvider>
  );
}

export default App;
