const STORAGE_KEYS = {
  TWO_FACTOR: "twoFactorSetup",
  NEW_LOGIN: "isNewLogin",
  NEEDS_2FA: "needs2FA",
};

import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updateEmail,
  User,
} from "firebase/auth";
import { auth, db } from "../config/firebase";
import { doc, getDoc, setDoc, updateDoc, onSnapshot } from "firebase/firestore";
import DeviceDetector from "device-detector-js";
import { api } from "../utils/api";

interface AuthState {
  user: any;
  isAuthenticated: boolean;
  error: string | null;
  twoFactorSetup: any;
  isNewLogin: boolean;
  unsubscribeUser: (() => void) | null;
  needs2FA: boolean;
  getUserLocation: () => Promise<any>;
  subscribeToUserUpdates: (userId: string) => void;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<void>;
  register: (email: string, password: string, name: string) => Promise<void>;
  clearError: () => void;
  clearLoginState: () => void;
  setupTwoFactor: (id: string) => Promise<void>;
  verifyTwoFactor: (code: number, sixDigitCode: number) => Promise<void>;
  updateUserProfile: (data: any, password: string) => Promise<boolean>;
  addAccessTokenAndItemId: (
    response: any,
    user: any,
    tokenType: string
  ) => Promise<void>;
  genShareableUrl: (user: User) => Promise<string>;
  plaidAuth: (access_token: User) => Promise<any>;
  plaidTransactions: (access_token: User) => Promise<any>;
  plaidInvestments: (access_token: User) => Promise<any>;
  plaidInvestmentTransactions: (access_token: User) => Promise<any>;
  plaidInvestmentsHoldings: (access_token: User) => Promise<any>;
  plaidIdentity: (access_token: User) => Promise<any>;
  plaidBalance: (access_token: User) => Promise<any>;
  plaidHoldings: (access_token: User) => Promise<any>;
  plaidLiabilities: (access_token: User) => Promise<any>;
  plaidItem: (access_token: User) => Promise<any>;
  plaidAccounts: (access_token: User) => Promise<any>;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      isAuthenticated: false,
      error: null,
      twoFactorSetup: null,
      isNewLogin: false,
      unsubscribeUser: null,
      needs2FA: false,

      getUserLocation: async () => {
        try {
          const response = await fetch(
            "http://ip-api.com/json/?fields=status,country,regionName,city,query"
          );

          if (!response.ok) throw new Error("Failed to fetch location");

          const data = await response.json();

          return {
            city: data.city || "Unknown",
            region: data.regionName || "Unknown",
            country: data.country || "Unknown",
            ip: data.query || "Unknown",
          };
        } catch (error) {
          console.error("Error fetching location:", error);
          return {
            city: "Unknown",
            region: "Unknown",
            country: "Unknown",
            ip: "Unknown",
          };
        }
      },

      subscribeToUserUpdates: (userId: string) => {
        if (get().unsubscribeUser) {
          if (get().unsubscribeUser) {
            get().unsubscribeUser();
          }
        }

        const unsubscribe = onSnapshot(
          doc(db, "users", userId),
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              set((state) => ({
                user: {
                  ...state.user,
                  ...userData,
                },
                twoFactorSetup: userData.twoFactorSetup || null,
                needs2FA:
                  userData.twoFactorSetup?.enabled &&
                  userData.twoFactorSetup?.verified &&
                  state.isNewLogin,
              }));
            }
          },
          (error) => {
            console.error("Error subscribing to user updates:", error);
          }
        );

        set({ unsubscribeUser: unsubscribe });
      },

      login: async (email: string, password: string) => {
        try {
          const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
          );
          const user = userCredential.user;
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          const userInfo = userDoc.data();

          const deviceDetector = new DeviceDetector();
          const device = deviceDetector.parse(navigator.userAgent);
          const deviceLocation = device.client.name + " on " + device.os.name;
          const loginLocation = await get().getUserLocation();

          const userData = {
            id: user.uid,
            name: user.displayName || "No name",
            email: user.email || "",
            role: userInfo?.role,
            avatar: user.photoURL || "default-avatar-url",
            createdAt: user.metadata.creationTime || new Date().toISOString(),
            phoneNumber: userInfo?.phoneNumber || "",
            location: userInfo?.location || "",
            shareInfo: userInfo?.shareInfo || "",
            bio: userInfo?.bio || "",
            access_token: userInfo?.access_token || "",
            access_token_loan: userInfo?.access_token_loan || "",
            access_token_inv: userInfo?.access_token_loan || "",
            item_id: userInfo?.item_id || "",
            item_id_loan: userInfo?.item_id_loan || "",
            item_id_inv: userInfo?.item_id_loan || "",
            twoFactorSetup: userInfo?.twoFactorSetup || {
              enabled: false,
              verified: false,
            },
            deviceLocation: deviceLocation,
            loginTime: new Date(Date.now()).toISOString(),
            loginLocation: loginLocation,
          };

          const shouldShowTwoFactor =
            userInfo?.twoFactorSetup?.enabled === true &&
            userInfo?.twoFactorSetup?.verified === true;

          await updateDoc(userDocRef, {
            twoFactorSetup: userInfo?.twoFactorSetup || {
              enabled: false,
              verified: false,
            },
            lastLogin: new Date().toISOString(),
          });

          get().subscribeToUserUpdates(user.uid);

          localStorage.setItem(
            STORAGE_KEYS.TWO_FACTOR,
            JSON.stringify(userInfo?.twoFactorSetup)
          );
          localStorage.setItem(
            STORAGE_KEYS.NEW_LOGIN,
            JSON.stringify(shouldShowTwoFactor)
          );
          localStorage.setItem(
            STORAGE_KEYS.NEEDS_2FA,
            JSON.stringify(shouldShowTwoFactor)
          );

          set({
            user: userData,
            isAuthenticated: true,
            error: null,
            twoFactorSetup: userInfo?.twoFactorSetup,
            isNewLogin: shouldShowTwoFactor,
            needs2FA: shouldShowTwoFactor,
          });

          return userData.role;
        } catch (error: any) {
          console.error("Login error:", error);
          set({
            error: error.message || "Login failed",
          });
          throw error;
        }
      },

      logout: async () => {
        try {
          if (get().unsubscribeUser) {
            get().unsubscribeUser();
          }

          await signOut(auth);

          localStorage.removeItem(STORAGE_KEYS.TWO_FACTOR);
          localStorage.removeItem(STORAGE_KEYS.NEW_LOGIN);
          localStorage.removeItem(STORAGE_KEYS.NEEDS_2FA);

          set({
            user: null,
            isAuthenticated: false,
            error: null,
            twoFactorSetup: null,
            isNewLogin: false,
            unsubscribeUser: null,
            needs2FA: false,
          });
        } catch (error: any) {
          set({
            error: error.message || "Logout failed",
          });
          throw error;
        }
      },

      register: async (
        email: string,
        password: string,
        name: string,
        phone: any
      ) => {
        try {
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );

          const user = userCredential.user;
          await updateProfile(user, { displayName: name });

          const deviceDetector = new DeviceDetector();
          const device = deviceDetector.parse(navigator.userAgent);
          const deviceLocation = device.client.name + " on " + device.os.name;
          const loginLocation = await get().getUserLocation();

          const userData = {
            id: user.uid,
            name: name,
            email: user.email || "",
            role: "user",
            avatar: user.photoURL || "default-avatar-url",
            createdAt: user.metadata.creationTime || new Date().toISOString(),
            phoneNumber: phone || "",
            location: "",
            bio: "",
            twoFactorSetup: {
              enabled: false,
              verified: false,
            },
            shareInfo: false,
            access_token: "",
            access_token_loan: "",
            access_token_inv: "",
            item_id: "",
            item_id_loan: "",
            item_id_inv: "",
            isNewLogin: false,
            deviceLocation: deviceLocation,
            loginTime: new Date(Date.now()).toISOString(),
            loginLocation: loginLocation,
          };

          await setDoc(doc(db, "users", userData.id), userData);

          get().subscribeToUserUpdates(user.uid);

          set({
            user: userData,
            isAuthenticated: true,
            error: null,
            twoFactorSetup: {
              enabled: false,
              verified: false,
            },
          });
        } catch (error: any) {
          set({
            error: error.message || "Registration failed",
          });
          throw error;
        }
      },

      clearError: () => set({ error: null }),

      clearLoginState: () => {
        localStorage.removeItem(STORAGE_KEYS.NEW_LOGIN);
        localStorage.removeItem(STORAGE_KEYS.NEEDS_2FA);
        set({ isNewLogin: false, needs2FA: false });
      },

      setupTwoFactor: async (id: string) => {
        try {
          await updateDoc(doc(db, "users", id), {
            twoFactorSetup: {
              enabled: true,
              verified: false,
            },
          });

          set((state) => ({
            user: {
              ...state.user,
              twoFactorSetup: {
                enabled: true,
                verified: false,
              },
            },
            twoFactorSetup: {
              enabled: true,
              verified: false,
            },
          }));
        } catch (error) {
          console.error("Failed to setup 2FA:", error);
          throw error;
        }
      },

      cancelTwoFactor: async (id: string) => {
        try {
          await updateDoc(doc(db, "users", id), {
            twoFactorSetup: {
              enabled: false,
              verified: false,
            },
          });

          set((state) => ({
            user: {
              ...state.user,
              twoFactorSetup: {
                enabled: false,
                verified: false,
              },
            },
            twoFactorSetup: {
              enabled: false,
              verified: false,
            },
          }));
        } catch (error) {
          console.error("Failed to disable 2FA:", error);
          throw error;
        }
      },

      verifyTwoFactor: async (code: number, sixDigitCode: number) => {
        try {
          if (!auth.currentUser?.uid) {
            throw new Error("No authenticated user found.");
          }

          if (+code === +sixDigitCode) {
            await updateDoc(doc(db, "users", auth.currentUser.uid), {
              twoFactorSetup: {
                enabled: true,
                verified: true,
              },
            });

            localStorage.removeItem(STORAGE_KEYS.NEW_LOGIN);
            localStorage.removeItem(STORAGE_KEYS.NEEDS_2FA);

            set((state) => ({
              user: {
                ...state.user,
                twoFactorSetup: {
                  enabled: true,
                  verified: true,
                },
              },
              twoFactorSetup: {
                enabled: true,
                verified: true,
              },
              isNewLogin: false,
              needs2FA: false,
            }));
          } else {
            throw new Error("Invalid verification code");
          }
        } catch (error) {
          console.error("Failed to verify 2FA:", error);
          throw error;
        }
      },

      updateUserProfile: async (
        data: {
          email?: string;
          location?: string;
          photoURL?: string;
          name?: string;
          phone?: string;
          bio?: string;
        },
        password: string
      ) => {
        try {
          const user = auth.currentUser;

          if (!user) {
            throw new Error("No authenticated user found.");
          }

          if (data.email && data.email !== user.email) {
            const emailUpdateSuccess = await updateEmail(
              user,
              data.email
            ).catch(async (error) => {
              if (error.message.includes("recent-login")) {
                if (password.length >= 6) {
                  await signInWithEmailAndPassword(auth, user.email!, password);
                  await updateEmail(user, data.email);
                  return true;
                } else {
                  return false;
                }
              }
              throw error;
            });

            if (emailUpdateSuccess === false) {
              return false;
            }
          }

          if (data.name || data.photoURL) {
            await updateProfile(user, {
              displayName: data.name,
              photoURL: data.photoURL,
            });
          }

          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const updatedData = {
              phoneNumber: data.phone,
              location: data.location,
              bio: data.bio,
              email: data.email,
              name: data.name,
              ...(data.photoURL && { photoURL: data.photoURL }),
            };

            await updateDoc(userDocRef, updatedData);
          } else {
            throw new Error("User document does not exist in Firestore.");
          }

          set((state: any) => ({
            user: {
              ...state.user,
              name: data.name !== undefined ? data.name : state.user?.name,
              avatar:
                data.photoURL !== undefined
                  ? data.photoURL
                  : state.user?.avatar,
              email: data.email !== undefined ? data.email : state.user?.email,
              phoneNumber:
                data.phone !== undefined ? data.phone : state.user?.phoneNumber,
              location:
                data.location !== undefined
                  ? data.location
                  : state.user?.location,
              bio: data.bio !== undefined ? data.bio : state.user?.bio,
            },
          }));

          return true;
        } catch (error: any) {
          set({
            error: error.message || "Failed to update profile",
          });
          console.error("Failed to update profile:", error);
          throw error;
        }
      },

      addAccessTokenAndItemId: async (
        response: any,
        user: any,
        tokenType: string
      ) => {
        try {
          const userDocRef = doc(db, "users", user.id);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            if (tokenType?.toLowerCase() === "loan") {
              await updateDoc(userDocRef, {
                access_token_loan: response.access_token,
                item_id_loan: response.item_id,
              });

              set((state: any) => ({
                user: {
                  ...state.user,
                  access_token_loan: response.access_token,
                  item_id_loan: response.item_id,
                },
              }));
            } else if (tokenType?.toLowerCase() === "link") {
              await updateDoc(userDocRef, {
                access_token: response.access_token,
                item_id: response.item_id,
              });

              set((state: any) => ({
                user: {
                  ...state.user,
                  access_token: response.access_token,
                  item_id: response.item_id,
                },
              }));
            } else if (tokenType?.toLowerCase() === "investment") {
              await updateDoc(userDocRef, {
                access_token_inv: response.access_token,
                item_id_inv: response.item_id,
              });

              set((state: any) => ({
                user: {
                  ...state.user,
                  access_token_inv: response.access_token,
                  item_id_inv: response.item_id,
                },
              }));
            }
          } else {
            throw new Error("User document does not exist in Firestore.");
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      },

      genShareableUrl: async function (user: User) {
        const response = await api.post("/generate_shareable_url", {
          email_address: user.email,
          user_id: user.uid,
        });
        return response.shareable_url;
      },

      plaidAuth: async function (access_token: User) {
        const response = await api.get("/auth", access_token, {});
        return response.shareable_url;
      },

      plaidTransactions: async function (access_token: User) {
        const response = await api.get("/transactions/sync", access_token, {});
        return response;
      },

      plaidInvestments: async function (access_token: User) {
        const response = await api.get(
          "/investments/auth/get",
          access_token,
          {}
        );
        return response;
      },

      plaidInvestmentTransactions: async function (access_token: User) {
        const response = await api.get(
          "/investments/transactions/get",
          access_token,
          {}
        );
        return response;
      },

      plaidInvestmentsHoldings: async function (access_token: User) {
        const response = await api.get(
          "/investments/holdings/get",
          access_token,
          {}
        );
        return response;
      },

      plaidIdentity: async function (access_token: User) {
        const response = await api.get("/identity", access_token, {});
        return response;
      },

      plaidBalance: async function (access_token: User) {
        const response = await api.get("/balance", access_token, {});
        return response;
      },

      plaidHoldings: async function (access_token: User) {
        const response = await api.get("/holdings/get", access_token, {});
        return response;
      },

      plaidLiabilities: async function (access_token: User) {
        const response = await api.get("/liabilities", access_token, {});
        return response;
      },

      plaidItem: async function (access_token: User) {
        const response = await api.get("/item", access_token, {});
        return response;
      },

      plaidAccounts: async function (access_token: User) {
        const response = await api.get("/accounts", access_token, {});
        return response;
      },
    }),
    {
      name: "auth-storage",
      storage: {
        getItem: (name) => {
          const str = localStorage.getItem(name);
          if (!str) return null;
          const { state } = JSON.parse(str);
          return {
            state: {
              ...state,
              twoFactorSetup: JSON.parse(
                localStorage.getItem(STORAGE_KEYS.TWO_FACTOR) || "null"
              ),
              isNewLogin: JSON.parse(
                localStorage.getItem(STORAGE_KEYS.NEW_LOGIN) || "false"
              ),
              needs2FA: JSON.parse(
                localStorage.getItem(STORAGE_KEYS.NEEDS_2FA) || "false"
              ),
            },
          };
        },
        setItem: (name, value) =>
          localStorage.setItem(name, JSON.stringify(value)),
        removeItem: (name) => localStorage.removeItem(name),
      },
      partialize: (state) => ({
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        twoFactorSetup: state.twoFactorSetup,
        isNewLogin: state.isNewLogin,
        needs2FA: state.needs2FA,
      }),
    }
  )
);
