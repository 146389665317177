import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Pie,
  Cell,
} from "recharts";
import {
  TrendingUp,
  TrendingDown,
  DollarSign,
  PiggyBank,
  CreditCard,
  Target,
  PieChart,
} from "lucide-react";
import DownloadButton from "./DownloadButton";
import QualitativeInsights from "./QualitativeInsights";
import { useTransactionStore } from "../../store/transactionStore";
import { isWithinInterval, startOfYear, sub } from "date-fns";
import { getDocument } from "../../utils/firebase-db.js";
import { useAuthStore } from "../../store/authStore.js";
import NetWorthCard from "../cards/NetWorthCard.js";

interface ReportsOverviewProps {
  timeFrame: string;
}

const assetAllocation = [
  { name: "Real Estate", value: 350000 },
  { name: "Investments", value: 100000 },
  { name: "Cash", value: 50000 },
  { name: "Other", value: 10000 },
];

const COLORS = [
  "#B100FF",
  "#10B981",
  "#F59E0B",
  "#6366F1",
  "#EC4899",
  "#8B5CF6",
];

function ReportsOverview({
  timeFrame,
  transactions,
  addedStocks,
  userAccounts,
  addedBonds,
  commodities,
  liabilities,
  userAddedInvestments,
}: ReportsOverviewProps) {
  const getDateRange = (timeFrame: string) => {
    const endDate = new Date();
    let startDate: Date;

    switch (timeFrame) {
      case "1M":
        startDate = sub(endDate, { months: 1 });
        break;
      case "3M":
        startDate = sub(endDate, { months: 3 });
        break;
      case "6M":
        startDate = sub(endDate, { months: 6 });
        break;
      case "YTD":
        startDate = startOfYear(endDate);
        break;
      case "1Y":
        startDate = sub(endDate, { years: 1 });
        break;
      case "5Y":
        startDate = sub(endDate, { years: 5 });
        break;
      case "ALL":
      default:
        startDate = new Date(0); // Include all transactions
        break;
    }

    return { startDate, endDate };
  };

  const { startDate, endDate } = getDateRange(timeFrame);

  const filteredTransactions = transactions.filter((transaction) =>
    isWithinInterval(new Date(transaction.date), {
      start: startDate,
      end: endDate,
    })
  );

  const calculateNetWorth = () => {
    const creditCardAcc = liabilities?.accounts?.filter((account) =>
      account.type.includes("credit")
    );

    const loans = liabilities?.accounts?.filter((account) =>
      account.type.includes("loan")
    );

    const totalBankBalance =
      (userAccounts?.accounts?.reduce(
        (sum, acc) => sum + acc.balances.current,
        0
      ) || 0) +
      (liabilities?.accounts?.reduce(
        (sum, acc) => sum + acc.balances.current,
        0
      ) || 0) +
      (addedStocks?.reduce((sum, stock) => sum + stock.institution_value, 0) ||
        0) +
      (addedBonds?.reduce((sum, bond) => sum + bond.institution_value, 0) ||
        0) +
      (commodities?.reduce((sum, comm) => sum + comm.totalValue, 0) || 0) +
      (userAddedInvestments?.reduce((sum, inv) => sum + inv.currentValue, 0) ||
        0);

    const totalAssets = totalBankBalance;

    const totalCreditDebt = creditCardAcc?.reduce(
      (sum, card) => sum + card?.balances?.current,
      0
    );

    const totalLoanDebt = loans?.reduce(
      (sum, loan) => sum + loan.balances.current,
      0
    );
    const totalLiabilities = totalCreditDebt + totalLoanDebt;

    // Calculate net worth
    const netWorth = totalAssets - totalLiabilities || 0;

    return netWorth;
  };

  const calculateMonthlyIncome = () =>
    filteredTransactions
      .filter((tx) => tx.amount > 0)
      .reduce((sum, tx) => sum + tx.amount, 0);

  const calculateMonthlyExpenses = () =>
    filteredTransactions
      .filter((tx) => tx.amount < 0)
      .reduce((sum, tx) => sum + Math.abs(tx.amount), 0);

  const netWorth = calculateNetWorth();
  const monthlyIncome = calculateMonthlyIncome();
  const monthlyExpenses = calculateMonthlyExpenses();
  const monthlySavings = monthlyIncome - monthlyExpenses;

  const netWorthData = filteredTransactions.map((tx) => ({
    date: tx.date,
    netWorth: netWorth,
    income: tx.amount > 0 ? tx.amount : 0,
    expenses: tx.amount < 0 ? Math.abs(tx.amount) : 0,
  }));

  const calculateSpendingChange = () => {
    // Group spending by month
    const spendingByMonth = filteredTransactions.reduce(
      (acc: { [key: string]: number }, tx) => {
        const month = new Date(tx.authorized_date).toISOString().slice(0, 7); // Format: YYYY-MM
        if (!acc[month]) acc[month] = 0;
        if (tx.amount < 0) acc[month] += Math.abs(tx.amount); // Only consider expenses
        return acc;
      },
      {}
    );

    // Sort months in chronological order
    const months = Object.keys(spendingByMonth).sort();

    if (months.length < 2) return 0; // Not enough data to compare

    const lastMonth = spendingByMonth[months[months.length - 2]];
    const currentMonth = spendingByMonth[months[months.length - 1]];

    if (lastMonth === 0) return currentMonth > 0 ? 100 : 0; // Avoid division by zero

    // Calculate percentage change
    const changePercentage = ((currentMonth - lastMonth) / lastMonth) * 100;

    return changePercentage;
  };

  // Example usage:
  const spendingChange = calculateSpendingChange();

  const insights = [
    {
      type: monthlySavings > 0 ? "positive" : "negative",
      title: "Monthly Savings",
      description: `Your savings for this period ${
        monthlySavings > 0 ? "increased" : "decreased"
      } by $${Math.abs(monthlySavings).toLocaleString()}.`,
    },
    {
      type: "warning",
      title: "Spending Pattern",
      description: `Spending has changed by ${spendingChange.toFixed(
        2
      )}% this month.`,
    },
    // {
    //   type: "action",
    //   title: "Spending Pattern",
    //   description: "Review your expenses to optimize savings.",
    // },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Financial Overview</h3>
        <DownloadButton
          reportId="reports-overview"
          fileName="financial-overview"
        />
      </div>

      <div id="reports-overview" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4 ">
              Net Worth (Excluding Real Estate)
            </h4>
            <p className="text-2xl font-bold text-green-500">
              <NetWorthCard
                userAccounts={userAccounts}
                addedStocks={addedStocks}
                liabilities={liabilities}
                addedBonds={addedBonds}
                commodities={commodities}
                userAddedInvestments={userAddedInvestments}
              />
            </p>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Monthly Savings
            </h4>
            {monthlySavings >= 0 ? (
              <p className="text-2xl font-bold text-green-500">
                +${monthlySavings.toLocaleString()}
              </p>
            ) : (
              <p className="text-2xl font-bold text-red-500">
                -${Math.abs(monthlySavings).toLocaleString()}
              </p>
            )}
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Monthly Expenses
            </h4>
            <p className="text-2xl font-bold text-red-500">
              -${monthlyExpenses.toLocaleString()}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-1 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Net Worth Trend
            </h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={netWorthData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="income"
                    stroke="#10B981"
                    name="Income"
                  />
                  <Line
                    type="monotone"
                    dataKey="expenses"
                    stroke="#EF4444"
                    name="Expenses"
                  />
                  <Line
                    type="monotone"
                    dataKey="netWorth"
                    stroke="#B100FF"
                    name="Net Worth"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
          {/* <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Key Metrics
            </h4>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <DollarSign className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    Debt-to-Income Ratio
                  </span>
                </div>
                <span className="text-sm font-medium">28%</span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <PiggyBank className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    Emergency Fund Coverage
                  </span>
                </div>
                <span className="text-sm font-medium">4.5 months</span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <CreditCard className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    Credit Utilization
                  </span>
                </div>
                <span className="text-sm font-medium">15%</span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <Target className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    Investment Goals Progress
                  </span>
                </div>
                <span className="text-sm font-medium">65%</span>
              </div>
            </div>
          </div> */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Insights & Recommendations
            </h4>
            <QualitativeInsights insights={insights} />
          </div>
        </div>

        {/* <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
            Asset Allocation
          </h4>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={assetAllocation}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="amount"
                >
                  {assetAllocation.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-4 space-y-2">
            {assetAllocation.map((asset, index) => (
              <div
                key={asset.name}
                className="flex items-center justify-between"
              >
                <div className="flex items-center">
                  <div
                    className="w-3 h-3 rounded-full mr-2"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  />
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    {asset.name}
                  </span>
                </div>
                <span className="text-sm font-medium">
                  ${asset.value.toLocaleString()}
                </span>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ReportsOverview;
